import React, { Component } from 'react';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div className="ccc">


                <div className="top_line"></div>

                {/*<header className={window.location.pathname === '/' ? 'container headear_backimg' : 'container header_child'}>*/}
                {/*    <div className={window.location.pathname === '/' ? 'headear_img' : 'header_two_img'}></div>*/}
                <header className="container headear_backimg">
                    <div className="headear_img"></div>
                    <h1>Cоциальный навигатор</h1>
                    <h3>Челябинской области</h3>
                </header>

                <main>
                    {this.props.children}
                </main>

                <footer className="footer_img">
                    <div className="container">
                        <h5>©ООО СоцИнформТех 2004-2022.</h5>
                    </div>
                </footer>
            </div>
        );
    }
}
