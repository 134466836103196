import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                <li className="breadcrumb-item active" aria-current="page"><Link to="/certificateFamily" style={{ cursor: 'pointer' }}>Получение удостоверения многодетной семьи Челябинской области</Link></li>
            </ol>
        </nav>
        <div className="name">Получение удостоверения многодетной семьи Челябинской области(Вы не являетесь многодетной семьей)</div>

        <div className='white-card'>

            <div className='card-body'>
                <p>Право на получение удостоверения многодетной семьи имеют многодетные семьи Челябинской области, постоянно проживающие на территории Челябинской области и имеющие на своем содержании трех и более детей (в том числе усыновленных, взятых под опеку (попечительство), пасынков и падчериц) в возрасте до восемнадцати лет (Закон Челябинской области от 31 марта 2010 г. N 548-ЗО "О статусе и дополнительных мерах социальной поддержки многодетной семьи в Челябинской области").</p>
            </div>

        </div>

    </Container >)
}

export default Act1;