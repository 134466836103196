import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import {
    Link
} from 'react-router-dom';

export class Health extends Component {

    render() {
        return (
            <Container>
                <Row>
                    <Col md='12' lg='12'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb pl-0">
                                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"><Link to="/health" style={{ cursor: 'pointer' }}>Отдых и оздоровление детей</Link></li>
                            </ol>
                        </nav>
                    </Col>
                </Row>
                <Row>
                    <Col md='12' lg='12' className='mb-3'>
                        <div className='head-list-child mt-30'>
                            <div className='title-head-list title-head-list-child'>Отдых и оздоровление детей</div>
                        </div>
                        <div className='white-box-child last pt-0'>
                            <div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Выберите категорию:</label>
                                    </div>

                                    <div className="element_string attribute ">
                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/91">
                                                <input type="radio" id="attribute_value0" name="attribute_0" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_0" htmlFor="attribute_value0">предоставление путевки детям, находящимся в трудной жизненной ситуации </label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/92">
                                                <input type="radio" id="attribute_value1" name="attribute_0" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_1" htmlFor="attribute_value1">предоставление путевки детям, нуждающимся в санаторно-курортном лечении</label>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }

}