import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import Home from './components/Home';
import Action from './components/Actions/Action';
import { CertificateStart } from './components/Certificat/CertificateStart';
import { CertificateBase } from './components/Certificat/CertificateBase';
import { CertificateRadiation } from './components/Certificat/CertificateRadiation';
import { CertificateFamily } from './components/Certificat/CertificateFamily';
import { CertificateWow } from './components/Certificat/CertificateWow';
import { CertificateIwow } from './components/Certificat/CertificateIwow';
import { CertificateChsp } from './components/Certificat/CertificateChsp';
import { Jku } from './components/Jku/Jku';
import { Measure } from './components/Jku/Measure';
import { Social } from './components/Jku/Social';
import { SocialServices } from './components/SocialServices/SocialServices'
import { Siroty } from './components/Siroty/Siroty'
import { Children } from './components/Children/Children'
import { Health } from './components/Health/Health'
import './custom.css'
import './BaseStyle.css'
import './Child.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
      return (
      <Layout>
              <Route exact path='/' component={Home} />
              <Route exact path='/action/:id' component={Action} />
              <Route exact path='/certificat' component={CertificateStart} />
              <Route exact path='/certificateBase' component={CertificateBase} />
              <Route exact path='/certificateRadiation' component={CertificateRadiation} />
              <Route exact path='/certificateFamily' component={CertificateFamily} />
              <Route exact path='/certificateWow' component={CertificateWow} />
              <Route exact path='/certificateIwow' component={CertificateIwow} />
              <Route exact path='/certificateChsp' component={CertificateChsp} />
              <Route exact path='/jku' component={Jku} />
              <Route exact path='/socialservices' component={SocialServices} />
              <Route exact path='/siroty' component={Siroty} />
              <Route exact path='/children' component={Children} />
              <Route exact path='/health' component={Health} />
              <Route exact path='/measure' component={Measure} />
              <Route exact path='/social' component={Social} />
      </Layout>
    );
  }
}
