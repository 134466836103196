import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item active" aria-current="page"><Link to="/health" style={{ cursor: 'pointer' }}>Отдых и оздоровление детей</Link></li>
            </ol>
        </nav>

        <div className='white-card'>

            <div className='card-body'>
                <p>Бесплатные путевки в санаторно-оздоровительные детские лагеря круглогодичного действия предоставляются детям школьного возраста до достижения ими 18 лет, за исключением детей-инвалидов по медицинским показаниям. Выдача путевок осуществляется органами социальной защиты населения муниципальных районов и городских округов Челябинской области по месту жительства заявителя. Путевки на отдых и оздоровление предоставляются гражданам в порядке очередности. </p>
                <a>Постановление Правительства Челябинской области от 1 апреля 2010 г. № 85-П «О Положении о порядке предоставления путевок в санаторно-оздоровительные детские лагеря круглогодичного действия и о порядке расходования средств областного бюджета на указанные цели»</a>
            </div>

        </div>

    </Container >)
}

export default Act1;