import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'

const Act2 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><a href="/">Главная</a></li>
                <li className="breadcrumb-item"><a href="/certificat">Получение удостоверений </a></li>
                <li className="breadcrumb-item active" aria-current="page">Присвоение звания "Ветеран труда Челябинской области" и выдача удостоверения "Ветеран труда Челябинской области"</li>
            </ol>
        </nav>
        <div className="name">Присвоение звания "Ветеран труда Челябинской области" и выдача удостоверения "Ветеран труда Челябинской области"</div>

        <div className='white-card'>

            <div className='card-body'>


                <div className="small-title">Звание «Ветеран труда Челябинской области» присваивается гражданам Российской Федерации, постоянно проживающим на территории Челябинской области, которым установлена (назначена) страховая пенсия по старости в соответствии с Федеральным законом «О страховых пенсиях» или которые достигли возраста 55 и 60 лет (соответственно женщины и мужчины):</div>
                <ul className="text">
                    <li>имеющим страховой стаж не менее 40 лет для мужчин и 35 лет для женщин, награжденным наградами Челябинской области, наградами Законодательного Собрания Челябинской области, почетными грамотами Законодательного Собрания Челябинской области и Губернатора Челябинской области;</li>
                    <li>родившим пять и более детей и воспитавшим их до достижения ими возраста восьми лет.</li>
                </ul>

                <a target="_blank" href="http://minsoc74.ru/sites/default/files/2_zayavlenie_vtcho.doc">Шаблон заявления</a>

                <div className="small-title">срок предоставления государственной услуги</div>
                <ul className="text">
                    <li>Срок предоставления государственной услуги со дня регистрации заявления о предоставлении государственной услуги со всеми необходимыми документами, установленными пунктом 10 Административного регламента, до вынесения Министерством социальных отношений решения о предоставлении либо об отказе в предоставлении государственной услуги не может превышать 30 календарных дней.</li>
                </ul>
                <div className="small-title">исчерпывающий перечень документов</div>
                <ul className="text">
                    <li>заявление</li>
                    <li>документ, удостоверяющий личность (при обращении представителя заявителя - документы, подтверждающие его полномочия);</li>
                    <li>фотография размером 3 х 4 сантиметра;</li>
                    <li>

                        <ul className="point">документы, подтверждающие право на присвоение звания «Ветеран труда Челябинской области» , которым установлена (назначена) страховая пенсия по старости в соответствии с Федеральным законом «О страховых пенсиях»:
                                <li style={{ marginLeft: '2em' }}>
                                <p>
                                    <ul className="point">
                                        для граждан, имеющих страховой стаж не менее 40 лет для мужчин и 35 лет для женщин, награжденных наградами Челябинской области, наградами Законодательного Собрания Челябинской области, почетными грамотами Законодательного Собрания Челябинской области и Губернатора Челябинской области:
                                             <li style={{ marginLeft: '2em' }}>справка, выданная органами, осуществляющими пенсионное обеспечение, либо сведения, полученные от указанных органов в порядке межведомственного информационного взаимодействия, об установлении (назначении) пенсии или назначении пожизненного содержания за работу (службу) и о наличии страхового стажа, исчисленного в соответствии с Федеральным законом "О страховых пенсиях", не менее 40 лет для мужчин и 35 лет для женщин; а в случаях, когда право на присвоение звания "Ветеран труда Челябинской области" возникает с учетом периодов трудовой или иной деятельности, наступивших после даты установления (назначения) пенсии в соответствии с Федеральным законом "О трудовых пенсиях в Российской Федерации", также выписка из индивидуального лицевого счета застрахованного лица;
</li>
                                        <li style={{ marginLeft: '2em' }}>удостоверения (дубликаты удостоверений) к наградам Челябинской области, наградам Законодательного Собрания Челябинской области;
                                        почетные грамоты Законодательного Собрания Челябинской области и Губернатора Челябинской области.
                                        В случае утраты наградных документов факт награждения может быть подтвержден справками, выданными органами государственной власти Челябинской области, от имени которых производилось награждение;
</li>
                                    </ul>
                                </p>
                            </li>

                            <li style={{ marginLeft: '2em' }}>
                                <p>
                                    <ul className="point">
                                        для граждан, имеющих страховой стаж не менее 40 лет для мужчин и 35 лет для женщин, награжденных наградами Челябинской области, наградами Законодательного Собрания Челябинской области, почетными грамотами Законодательного Собрания Челябинской области и Губернатора Челябинской области, достигших возраста 55 и 60 лет (соответственно женщины и мужчины):
                                             <li style={{ marginLeft: '2em' }}>справка, выданная работодателем, о наличии страхового стажа, исчисленного в соответствии с Федеральным законом "О страховых пенсиях", не менее 40 лет для мужчин и 35 лет для женщин;
</li>
                                        <li style={{ marginLeft: '2em' }}>удостоверения (дубликаты удостоверений) к наградам Челябинской области, наградам Законодательного Собрания Челябинской области; почетные грамоты Законодательного Собрания Челябинской области и Губернатора Челябинской области. В случае утраты наградных документов факт награждения может быть подтвержден справками, выданными органами государственной власти Челябинской области, от имени которых производилось награждение;
</li>
                                    </ul>
                                </p>
                            </li>


                            <li style={{ marginLeft: '2em' }}>
                                <p>
                                    <ul className="point">
                                        для родивших пять и более детей и воспитавших их до достижения ими возраста восьми лет:
                                             <li style={{ marginLeft: '2em' }}>справка, выданная органами, осуществляющими пенсионное обеспечение, об установлении (назначении) пенсии или назначении пожизненного содержания за работу (службу) и о рождении пяти и более детей и воспитании их до достижения ими возраста восьми лет.
</li>
                                    </ul>
                                </p>
                            </li>
                        </ul>

                    </li>
                </ul>
                <div className="small-title">результат предоставления государственной услуги</div>
                <ul className="text">
                    <li>присвоение звания "Ветеран труда Челябинской области" и выдача удостоверения "Ветеран труда Челябинской области"</li>
                </ul>
                <div className="small-title">Основания для отказа в приеме документов </div>
                <ul className="text">
                    <li>представление неполного пакета документов </li>
                    <li>документы имеют подчистки, приписки, зачеркнутые слова и иные не оговоренные в них исправления.</li>
                </ul>
                <div className="small-title">основания для отказа в предоставлении государственной услуги</div>
                <ul className="text">
                    <li>отсутствие гражданства Российской Федерации;</li>
                    <li>отсутствие необходимой продолжительности страхового стажа;</li>
                    <li>отсутствие факта рождения пяти и более детей и воспитания их до достижения ими возраста восьми лет;</li>
                    <li>не установлена (не назначена) страховая пенсия по старости в соответствии с Федеральным законом "О страховых пенсиях" (не применяется в отношении граждан, проходивших военную службу или иную государственную службу);</li>
                    <li>недостижение возраста, дающего право на страховую пенсию по старости в соответствии с Федеральным законом "О страховых пенсиях" (применяется в отношении граждан, получающих пенсии по основаниям, отличным от оснований, установленных статьей 8 Федерального закона "О страховых пенсиях", либо получающих пожизненное содержание за работу (службу);</li>
                    <li>наличие звания "Ветеран труда".</li>
                    <li>отсутствие наград Челябинской области, наград Законодательного Собрания Челябинской области, почетных грамот Законодательного Собрания Челябинской области и Губернатора Челябинской области.</li>
                </ul>
                <div className="small-title">право на досудебное (внесудебное) обжалование решений и действий (бездействия) должностных лиц </div>
                <ul className="text">
                    <li>В досудебном (внесудебном) порядке заявители могут обжаловать действия (бездействие) органов социальной защиты, Министерства социальных отношений Челябинской области, многофункционального центра, а также их должностных лиц, государственных гражданских служащих, муниципальных служащих, работников и принимаемые ими решения при предоставлении государственной услуги.</li>
                </ul>
            </div>

            <div className="small-title"><a target="_blank" href="http://www.gosuslugi74.ru">Портал государственных и муниципальных услуг Челябинской области</a></div>

        </div>

    </Container >)
}

export default Act2;