import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import {
    Link
} from 'react-router-dom';


export class CertificateRadiation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            First: true,
            Second: false,
            Third: false
        };
    }
    render() {
        const { First, Second, Third } = this.state;
        return (
            <Container>
                <Row>
                    <Col md='12' lg='12'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb pl-0">
                                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"><a onClick={() => this.setState({ First: true, Second: false, Third: false })} style={{ cursor: 'pointer' }}>Получение удостоверения гражданами, пострадавшими от воздействия радиации</a></li>
                            </ol>
                        </nav>
                    </Col>
                </Row>
                <Row>
                    <Col md='12' lg='12' className='mb-3'>
                        <div className='head-list-child mt-30'>
                            <div className='title-head-list title-head-list-child'>Получение удостоверения гражданами, пострадавшими от воздействия радиации</div>
                        </div>
                        <div className='white-box-child last pt-0'>


                            {First ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Вы являетесь гражданином РФ?</label>
                                    </div>

                                    <div className="element_string attribute_0 ">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input value='state:RadSecond' type="radio" id="attribute_value3" name="attribute_1" className="custom-control-input" onChange={() => this.setState({ First: false, Second: true, Third: false })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_3" htmlFor="attribute_value3">Да</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value030" name="attribute_1" className="custom-control-input" onChange={() => this.setState({ First: false, Second: false, Third: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_030" htmlFor="attribute_value030">Нет</label>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null
                            }

                            {Second ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Выберите событие</label>
                                    </div>

                                    <div className="element_string attribute_0 ">
                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/60">
                                                <input type="radio" id="attribute_value4" name="attribute_2" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_4" htmlFor="attribute_value4">Пострадали от ВР вследствие аварии на ПО «Маяк»</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/61">
                                                <input type="radio" id="attribute_value5" name="attribute_2" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_5" htmlFor="attribute_value5">Пострадали от ВР вследствие катастрофы на ЧАЭС</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/62">
                                                <input type="radio" id="attribute_value6" name="attribute_2" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_6" htmlFor="attribute_value6">Пострадали от ВР вследствие ядерных испытаний на Семипалатинском полигоне</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/63">
                                                <input type="radio" id="attribute_value7" name="attribute_2" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_7" htmlFor="attribute_value7">Получили заболевание,  связанное с радиационными авариями и катастрофами</label>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null
                            }

                            {Third ? (<div className="element_group">
                                <p className="mt-5">Правовых оснований на оформление документов нет</p>
                            </div>)
                                : null
                            }

                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }

}