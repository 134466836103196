import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                <li className="breadcrumb-item active" aria-current="page"><Link to="/certificateBase" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами</Link></li>
            </ol>
        </nav>

        <div className='white-card'>

            <div className='card-body'>
                <p>Лицам, не проживающим на территории Челябинской области, удостоверение инвалида Великой Отечественной войны или удостоверение инвалида о праве на льготы (далее – удостоверение инвалида войны) не выдается.</p>
                <a target="_blank" htef="http://minsoc74.ru/vydacha-udostovereniya-invalida-velikoy-otechestvennoy-voyny-i-udostovereniya-invalida-o-prave-na-0">Пункт 4 раздела I Административного регламента предоставления государственной услуги «Выдача удостоверения инвалида Великой Отечественной войны и удостоверения инвалида о праве на льготы проживающим на территории Челябинской области инвалидам Великой Отечественной войны и приравненным к ним лицам», утвержденного постановлением Правительства Челябинской области от 23.05.2012 г. 
                № 252-П (далее – Административный регламент)
</a>
                <p className="mt-3"><Link to="/action/4" style={{ cursor: 'pointer' }}>Общая информация о выдаче удостоверения инвалида войны</Link></p>
            </div>

        </div>

    </Container >)
}

export default Act1;