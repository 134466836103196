import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'

const Act5 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><a href="/">Главная</a></li>
                <li className="breadcrumb-item"><a href="/certificat">Получение удостоверений </a></li>
                <li className="breadcrumb-item active" aria-current="page">«Выдача удостоверений о праве на льготы членам семей погибших (умерших)
                инвалидов войны, участников Великой Отечественной войны, ветеранов боевых
                действий, а также военнослужащих, проходивших военную службу по призыву
        и погибших при исполнении обязанностей военной службы»</li>
            </ol>
        </nav>
        <div className="name">«Выдача удостоверений о праве на льготы членам семей погибших (умерших)
        инвалидов войны, участников Великой Отечественной войны, ветеранов боевых
        действий, а также военнослужащих, проходивших военную службу по призыву
        и погибших при исполнении обязанностей военной службы»</div>

        <div className='white-card'>

            <div className='card-body'>
                <div className="small-title">круг заявителей</div>
                <ul className="text">
                    <li>нетрудоспособным членам семей погибших (умерших) инвалидов войны, участников Великой Отечественной войны и ветеранов боевых действий (далее именуются - погибшие (умершие), а также нетрудоспособным членам семей военнослужащих, проходивших военную службу по призыву и погибших при исполнении обязанностей военной службы (далее именуются - члены семьи погибшего (умершего), состоявшим на иждивении погибшего (умершего) и получающим пенсию по случаю потери кормильца (имеющим право на ее получение) в соответствии с пенсионным законодательством Российской Федерации</li>
                    <li>независимо от состояния трудоспособности, нахождения на иждивении, получения пенсии или заработной платы:
                    родителям погибшего (умершего) инвалида войны, участника Великой Отечественной войны и ветерана боевых действий;
                    супруге (супругу) погибшего (умершего) инвалида войны, не вступившей (не вступившему) в повторный брак;
                    супруге (супругу) погибшего (умершего) участника Великой Отечественной войны, не вступившей (не вступившему) в повторный брак;
                    супруге (супругу) погибшего (умершего) ветерана боевых действий, не вступившей (не вступившему) в повторный брак и проживающей (проживающему) одиноко, или с несовершеннолетним ребенком (детьми), или с ребенком (детьми) старше возраста 18 лет, ставшим (ставшими) инвалидом (инвалидами) до достижения им (ими) возраста 18 лет, или с ребенком (детьми), не достигшим (не достигшими) возраста 23 лет и обучающимся (обучающимися) в образовательных учреждениях по очной форме обучения.
</li>
                </ul>
                <div className="small-title">срок предоставления государственной услуги</div>
                <ul className="text">
                    <li>Срок предоставления государственной услуги со дня регистрации в органе социальной защиты населения либо многофункциональном центре заявления о предоставлении государственной услуги со всеми необходимыми документами, установленными Административным регламентом, обязанность по предоставлению которых возложена на заявителя, до вынесения Министерством социальных отношений Челябинской области решения о предоставлении либо об отказе в предоставлении государственной услуги не может превышать 30 календарных дней.</li>
                </ul>
                <div className="small-title">исчерпывающий перечень документов</div>
                <ul className="text">
                    <li>заявление </li>
                    <li>документ, удостоверяющий личность (при обращении представителя заявителя - документы, подтверждающие его полномочия);</li>
                    <li>документ о прохождении военной службы или участии в боевых действиях погибшим (умершим) ветераном.</li>
                    <li>свидетельство о смерти (извещение о гибели) погибшего (умершего) ветерана;</li>
                    <li>документы, подтверждающие родственное отношение к погибшему (умершему) ветерану;</li>
                    <li>пенсионное удостоверение либо справка о получении пенсии по случаю потери кормильца (о наличии права на её получение), выданная органом, осуществляющим назначение и выплату пенсии (для заявителей, указанных в «круге заявителей»);</li>
                    <li>документы, подтверждающие одинокое проживание супруги (супруга) погибшего (умершего) ветерана боевых действий или проживание с несовершеннолетним ребенком (детьми), или с ребенком (детьми) старше возраста 18 лет, ставшим (ставшими) инвалидом (инвалидами) до достижения им (ими) возраста 18 лет, или с ребенком (детьми), не достигшим (не достигшими) возраста 23 лет и обучающимся (обучающимися) в образовательных организациях по очной форме обучения (для супругов погибших (умерших) ветеранов боевых действий):
                    справка о регистрации заявителя и членов его семьи по месту жительства в пределах Российской Федерации, выданная территориальным органом федерального органа исполнительной власти, уполномоченного на осуществление функций по контролю и надзору в сфере миграции;
                    документы (сведения) о рождении ребенка (детей) (представляются при проживании с ребенком (детьми);
                    документы (сведения) об установлении ребенку (детям) инвалидности до достижения им (ими) возраста 18 лет (представляются при проживании с ребенком (детьми) старше возраста 18 лет, ставшим (ставшими) инвалидом (инвалидами) до достижения им (ими) возраста 18 лет);
                    справка образовательной организации об обучении ребенка (детей) по очной форме обучения до достижения им (ими) возраста 23 лет (представляется при проживании с ребенком (детьми), не достигшим (не достигшими) возраста 23 лет и обучающимся (обучающимися) в образовательных организациях по очной форме обучения)
</li>
                    <li>фотография размером 3 х 4 сантиметра.
                    Документы, указанные в пунктах 1 - 4, 7 (за исключением справки о составе семьи), 8, представляются заявителями.
</li>
                </ul>
                <div className="small-title">результат предоставления государственной услуги</div>
                <ul className="text">
                    <li>Результат предоставления государственной услуги - выдача удостоверения о праве на льготы, дубликата удостоверения о праве на льготы</li>
                </ul>
                <div className="small-title">Основания для отказа в приеме документов </div>
                <ul className="text">
                    <li>неполное представление или отсутствие документов, обязанность по представлению которых возложена на заявителя;</li>
                    <li>не предоставление в срок, установленный подпунктом 3 пункта 24-2 Административного регламента, оригиналов документов, указанных в пункте 10 Административного регламента, обязанность по предоставлению которых возложена на заявителя (при подаче документов посредством заполнения электронной формы запроса с использованием федерального портала)</li>
                </ul>
                <div className="small-title">основания для отказа в предоставлении государственной услуги</div>
                <ul className="text">
                    <li>пенсионное обеспечение заявителя осуществляется в соответствии с Законом Российской Федерации "О пенсионном обеспечении лиц, проходивших военную службу, службу в органах внутренних дел, Государственной противопожарной службе, органах по контролю за оборотом наркотических средств и психотропных веществ, учреждениях и органах уголовно-исполнительной системы, и их семей;</li>
                    <li>отсутствие пенсионного обеспечения (для заявителей, указанных в пункте 1 «круга заявителей»);</li>
                    <li>вступление супруги (супруга) погибшего (умершего) ветерана в повторный брак;</li>
                    <li>отсутствие права на получение пенсии по случаю потери кормильца для лиц, указанных в пунктах 1, 3 статьи 21 Федерального закона от 12 января 1995 года N 5-ФЗ "О ветеранах";</li>
                    <li>проживание супруги (супруга) погибшего (умершего) ветерана боевых действий с лицами, не указанными в подпункте 4 пункта 2 статьи 21 Федерального закона от 12 января 1995 года N 5-ФЗ "О ветеранах";</li>
                    <li>документы имеют подчистки, приписки, зачеркнутые слова и иные, не оговоренные в них исправления.</li>
                </ul>
                <div className="small-title">право на досудебное (внесудебное) обжалование решений и действий (бездействия) должностных лиц </div>
                <ul className="text">
                    <li>В досудебном (внесудебном) порядке заявители могут обжаловать действия (бездействие) Министерства социальных отношений Челябинской области, органов социальной защиты населения, а также их должностных лиц, государственных служащих, муниципальных служащих и принимаемые ими решения при предоставлении государственной услуги.</li>
                </ul>
                <p><a target="_blank" href="http://minsoc74.ru/sites/default/files/5_zayavlenie_chsp.doc ">Шаблон заявления</a></p>
            </div>

        </div>

    </Container >)
}

export default Act5;