import React, { useState } from 'react';
import { Container, Form, FormGroup, Label, Input, Col, Row, Table, Button } from 'reactstrap';
import './Action.css'

const Act23 = () => {
    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><a href="/">Главная</a></li>
                <li className="breadcrumb-item"><a href="/socialservices">Меры социальной поддержки детей-сирот и детей, оставшихся без попечения родителей</a></li>
            </ol>
        </nav>
        <div className="name">Право на медицинское обеспечение</div>

        <div className='white-card'>

            <div className='card-body'>
                Право на медицинское обеспечение (бесплатная медицинская помощь в медицинских организациях государственной системы здравоохранения и муниципальной системы здравоохранения, в том числе высокотехнологичная медицинская помощь, проведение диспансеризации, оздоровления, регулярных медицинских осмотров, и осуществляется их направление на лечение за пределы территории Российской Федерации (предоставление путевок в организации отдыха детей и их оздоровления (в санаторно-курортные организации - при наличии медицинских показаний), а также оплачивается проезд к месту лечения (отдыха) и обратно). .
            </div>

        </div>

    </Container>)
}

export default Act23;