import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'

const Act7 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><a href="/">Главная</a></li>
                <li className="breadcrumb-item"><a href="/certificat">Получение удостоверений </a></li>
                <li className="breadcrumb-item active" aria-current="page">«Возмещение реабилитированным лицам расходов на проезд на междугородном транспорте»</li>
            </ol>
        </nav>
        <div className="name">«Возмещение реабилитированным лицам расходов на проезд на междугородном транспорте»</div>

        <div className='white-card'>

            <div className='card-body'>
                <div className="small-title">круг заявителей</div>
                <ul className="text">
                    <li>реабилитированные лица</li>
                </ul>
                <div className="small-title">срок предоставления государственной услуги</div>
                <ul className="text">
                    <li>со дня подачи заявления со всеми необходимыми документами, установленными Административным регламентом, не может превышать 45 календарных дней.</li>
                </ul>
                <div className="small-title">исчерпывающий перечень документов</div>
                <ul className="text">
                    <li>заявление о возмещении расходов на проезд на междугородном транспорте с указанием способа получения сумм возмещения (через кредитную организацию путем зачисления сумм возмещения на счет реабилитированного лица, открытый им в кредитном учреждении, или через отделение федеральной почтовой связи);</li>
                    <li>документ, удостоверяющий личность;</li>
                    <li>документ, удостоверяющий право на меры социальной поддержки;</li>
                    <li>документ, подтверждающий полномочия представителя заявителя (в случае если от имени заявителя выступает его представитель);</li>
                    <li>проездные документы.
                    Если документы на проезд были оформлены в виде электронного проездного документа (билета), для подтверждения расходов необходимо представить:
                    контрольный купон электронного проездного документа (билета) (выписка из автоматизированной системы управления пассажирскими перевозками на железнодорожном транспорте) с отметкой в строке «Статус электронного билета» - «Пройдена электронная регистрация» (при условии подтверждения факта совершения поездки).
                    Справка о подтверждении факта совершения поездки запрашивается органами социальной защиты населения в Южно-Уральском филиале акционерного общества «Федеральная пассажирская компания»;
                    маршрут/квитанцию электронного документа (авиабилета), сформированную автоматизированной информационной системой оформления воздушных перевозок, на бумажном носителе, в которой указана стоимость перелета, и посадочный талон, подтверждающий перелет реабилитированного лица по указанному в электронном авиабилете маршруту;
</li>
                    <li>справка, содержащая сведения об отсутствии прямого железнодорожного сообщения (или об отсутствии билетов в прямом железнодорожном сообщении на указанную в проездном документе дату отправления) и о возможных станциях пересадки (в случае отсутствия прямого железнодорожного сообщения от станции отправления до станции назначения);</li>
                    <li>справка, содержащая сведения об отсутствии железнодорожного сообщения (в случае отсутствия железнодорожного сообщения от станции отправления до станции назначения);</li>
                    <li>справка о тарифной стоимости проезда от железнодорожной станции отправления на территории Российской Федерации до пограничной с другим государством железнодорожной станции, расположенной по указанному в проездном документе маршруту (в случае предъявления проездных документов, оформленных для проезда на железнодорожном транспорте за пределы Российской Федерации);</li>
                    <li>документы лечебных учреждений, подтверждающие срок продления действия проездных документов на время болезни (в случае болезни реабилитированного лица в пути следования);</li>
                    <li>справка органа социальной защиты населения по месту жительства (пребывания) реабилитированного лица о не предоставлении в текущем году возмещения расходов, связанных с проездом, по месту пребывания (жительства).</li>
                </ul>
                <div className="small-title">результат предоставления государственной услуги</div>
                <ul className="text">
                    <li>отсутствуют</li>
                </ul>
                <div className="small-title">Основания для отказа в приеме документов </div>
                <ul className="text">
                    <li>представление заявителем неполного пакета документов, предусмотренных Административным регламентом, обязанность по представлению которых возложена на заявителя;</li>
                </ul>
                <div className="small-title">основания для отказа в предоставлении государственной услуги</div>
                <ul className="text">
                    <li>представление заявителем неполного пакета документов, предусмотренных Административным регламентом, обязанность по представлению которых возложена на заявителя;</li>
                    <li>наличие противоречий в документах, представленных заявителем;</li>
                    <li>представление органом социальной защиты населения по месту жительства (пребывания), прежнему месту жительства (пребывания) реабилитированного лица справки, подтверждающей, что в текущем году указанному лицу производилось возмещение расходов на проезд на междугородном транспорте по месту жительства (пребывания), прежнему месту жительства (пребывания);</li>
                    <li>повторное обращение реабилитированного лица за возмещением расходов, связанных с проездом на междугородном транспорте, если в течение текущего года было принято решение о возмещении ему таких расходов.</li>
                </ul>
                <div className="small-title">право на досудебное (внесудебное) обжалование решений и действий (бездействия) должностных лиц </div>
                <ul className="text">
                    <li>
                        В досудебном (внесудебном) порядке заявители могут обжаловать действия (бездействие) органов социальной защиты населения, Министерства социальных отношений Челябинской области, многофункционального центра, а также их должностных лиц, государственных гражданских служащих, муниципальных служащих, работников и принимаемые ими решения при предоставлении государственной услуги.</li>
                </ul>
                <div className="small-title">Форма заявления</div>
                <ul className="text">
                    <li>Заявление 7</li>
                </ul>
            </div>

        </div>

    </Container >)
}

export default Act7;