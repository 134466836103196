import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import { Link } from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/children"> Социальная поддержка семей с детьми</Link></li>
            </ol>
        </nav>
        <div className="name">Пособие по уходу за ребенком (до 1.5 лет)(ЕЖЕМЕСЯЧНО)</div>

        <div className='white-card'>

            <div className='card-body'>
                <text>Пособие назначается и выплачивается следующим категориям лиц: гражданам Российской Федерации, проживающим на территории Российской Федерации; постоянно проживающим на территории Российской Федерации иностранным гражданам и лицам без гражданства, а также беженцам; временно проживающим на территории Российской Федерации и подлежащим обязательному социальному страхованию на случай временной нетрудоспособности и в связи с материнством иностранным гражданам и лицам без гражданства. </text>
                <div className="small-title">Право возникает:</div>
                <ul className="text">
                    <li>если матери, либо отцы, либо другие родственники, опекуны, фактически осуществляющие уход за ребёнком по месту работы (службы).</li>
                    <li>если матери или отцы, либо опекуны, фактически осуществляющие уход за ребёнком, не работают или обучаются в образовательных учреждениях на очном обучении в органе социальной защиты населения по месту жительства (месту пребывания, месту фактического проживания),. </li>
                </ul>
                <div className="small-title">Нормативно правовые акты</div>
                <ul className="text">
                    <li><a target="_blank" href="http://publication.pravo.gov.ru/Document/View/0001202007040001">Конституция Российской Федерации от 12.12.1993 г. </a></li>
                    <li><a target="_blank" href="https://docs.cntd.ru/document/9035383">Федеральный закон от 19.05.1995 № 81-ФЗ «О государственных пособиях гражданам, имеющим детей».</a></li>
                    <li><a target="_blank" href="http://publication.pravo.gov.ru/Document/View/0001201804160048">Приказ Министерства труда и социальной защиты РФ от 23 марта 2018 г. N 186н "Об утверждении Административного регламента по предоставлению органами государственной власти субъектов Российской Федерации государственной услуги в сфере переданных полномочий Российской Федерации по назначению государственных пособий гражданам, имеющим детей"</a></li>
                </ul>
                <div className="small-title">Основание для отказа</div>
                <ul className="text">
                    <li>Получение пособия по безработице.   </li>
                    <li>Трудоустройство заявителя   </li>
                    <li>Помещение ребенка на полное государственное обеспечение.   </li>
                    <li>Обращение за пособием по истечении шести месяцев со дня достижения ребенком возраста полутора лет   </li>
                    <li>Выезд на постоянное место жительства за пределы Российской Федерации.   </li>
                    <li>Передача ребенка под опеку другого лица.   </li>

                </ul>
                <p>Подробные сведения размещены<a target="_blank" href="https://www.gosuslugi.ru/12850/4/info">здесь</a></p>
                <p>Для неработающих родителей подать заявление можно <a target="_blank" href="https://www.gosuslugi.ru/332575/2/form/order/814610862 ">здесь</a></p>
            </div>

        </div>

    </Container >)
}

export default Act1;