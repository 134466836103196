import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                <li className="breadcrumb-item" aria-current="page"><Link to="/certificateBase" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами</Link></li>
            </ol>
        </nav>

        <div className='white-card'>

            <div className='card-body'>
                <a target="_blank" href="http://minsoc74.ru/prisvoenie-zvaniya-veteran-truda-chelyabinskoy-oblasti-i-vydacha-udostovereniya-veteran-truda#overlay-context=socialnye-uslugi">Шаблон заявления</a>

                <div className="small-title">Разъяснения по заполнению заявления</div>
                <p>В шапке заявления указываются:
                фамилия, имя, отчество заявителя, дата рождения; адрес регистрации по месту жительства (указываются индекс, населенный пункт, улица, номер дома, номер квартиры (при наличии)); серия и номер, дата выдачи и наименование органа, выдавшего паспорт гражданина Российской Федерации; реквизиты пенсионного удостоверения (при наличии), номер телефона для контактов.
</p>
                <p>В тексте заявления:
                гражданами, претендующими на присвоение звания «Ветеран труда Челябинской области» на основании стажа и наград, указываются наименование наградного документа (например, удостоверение к знаку отличия «За заслуги перед Челябинской областью», почетная грамота Губернатора Челябинской области и так далее), реквизиты наградного документа и наименование органа, принявшего решение о награждении. Также указываются сведения о продолжительности страхового стажа;
</p>
                <p>женщинами, родившими пять и более детей и воспитавшими их до достижения ими возраста восьми лет, указывается факт рождения детей и воспитания их до достижения возраста восьми лет, наименование и реквизиты документов, подтверждающих данный факт (при наличии);</p>
                <p>далее указываются наименования документов, прилагаемых к заявлению, то есть документов, указанных в заявлении;</p>
                <p>*Справки о страховом стаже в отношении граждан, являющихся получателями пенсии, могут быть запрошены органами социальной защиты населения в рамках межведомственного информационного взаимодействия в уполномоченных органах, осуществляющих пенсионное обеспечение граждан.</p>
                <p>Граждане вправе самостоятельно по собственной инициативе представить справки о страховом стаже.</p>

                <a target="_blank" href="http://minsoc74.ru/prisvoenie-zvaniya-veteran-truda-chelyabinskoy-oblasti-i-vydacha-udostovereniya-veteran-truda#overlay-context=socialnye-uslugi">Образец заполнения заявления о присвоении звания «Ветеран труда Челябинской области»</a>
                <br />
                <div className="small-title">Способ подачи заявления о присвоении звания «Ветеран труда Челябинской области»</div>
                <ul className="text">
                    <li>при обращении в органы социальной защиты населения по месту жительства   
                     <a target="_blank" href="https://pravmin74.ru/normativnye-pravovye-akty/postanovleniya-pravitelstva/postanovlenie-pravitelstva-chelyabinskoi-o-933">Приложение 1 к Административному регламенту предоставления государственной услуги «Присвоение звания «Ветеран труда Челябинской области» и выдача удостоверения «Ветеран труда Челябинской области», утвержденному постановлением Правительства Челябинской области от 18.07.2012 г. № 389-П (далее – Административный регламент)</a></li>
                    <li>При обращении в многофункциональные центры предоставления государственных и муниципальных услуг
                        <a target="_blank" href="https://pravmin74.ru/normativnye-pravovye-akty/postanovleniya-pravitelstva/postanovlenie-pravitelstva-chelyabinskoi-o-933">Приложение 3 к Административному регламенту</a></li>
                    <li>посредством почтового отправления</li>
                </ul>

                <div className="small-title">Способ получения результата рассмотрения заявления о присвоении звания «Ветеран труда Челябинской области»</div>
                <ul className="text">
                    <li>при вынесении решения о присвоении звания «Ветеран труда Челябинской области» удостоверение ветерана труда Челябинской области может быть получено в органах социальной защиты населения по месту жительства 
                        <a target="_blank" href="https://pravmin74.ru/normativnye-pravovye-akty/postanovleniya-pravitelstva/postanovlenie-pravitelstva-chelyabinskoi-o-933">Приложение 1 к Административному регламенту </a></li>
                    <li>решение об отказе в присвоении звания «Ветеран труда Челябинской области» направляется заявителю на домашний адрес почтовым отправлением
                        </li>

                </ul>

            </div>

        </div>

    </Container >)
}

export default Act1;