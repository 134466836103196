import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                <li className="breadcrumb-item" aria-current="page"><Link to="/certificateBase" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами</Link></li>
            </ol>

        </nav>
        <div className="name">«Ежемесячная денежная выплата отдельным категориям ветеранов, жертвам политических репрессий и ветеранам труда Челябинской области»</div>
        <small className="text-muted">(для ветеранов труда, ветеранов военной службы, ветеранов труда Челябинской области, ветеранов Великой Отечественной войны из числа лиц, проработавших в тылу …; реабилитированных лиц и лиц, признанных пострадавшими от политических репрессий)</small>
        <div className='white-card'>

            <div className='card-body'>
                <div className="small-title">Звание «Ветеран труда» присваивается гражданам Российской Федерации, постоянно проживающим на территории Челябинской области</div>
                <ul className="text">
                    <li>награжденным орденами или медалями СССР или Российской Федерации, либо удостоенным почетных званий СССР или Российской Федерации, либо награжденным почетными грамотами Президента Российской Федерации или удостоенным благодарности Президента Российской Федерации, либо награжденным ведомственными знаками отличия за заслуги в труде (службе) и продолжительную работу (службу) не менее 15 лет в соответствующей сфере деятельности (отрасли экономики) и имеющим трудовой (страховой) стаж, учитываемый для назначения пенсии, не менее 25 лет для мужчин и 20 лет для женщин или выслугу лет, необходимую для назначения пенсии за выслугу лет в календарном исчислении;</li>
                    <li>начавшим трудовую деятельность в несовершеннолетнем возрасте в период Великой Отечественной войны и имеющим трудовой (страховой) стаж не менее 40 лет для мужчин и 35 лет для женщин. <a target="_blank" href="http://minsoc74.ru/sites/default/files/css/postanovlenie_pravitelstva_chelyabinskoy_oblasti_ot_18_iyulya_2012_g_n_388_p_ob_adm.rtf"> Ссылка</a></li>
                </ul>
                <div className="small-title">круг заявителей</div>
                <ul className="text">
                    <li>граждане Российской Федерации, постоянно проживающие на территории Челябинской области награжденные орденами или медалями СССР или Российской Федерации, либо удостоенные почетных званий СССР или Российской Федерации, либо награжденные почетными грамотами Президента Российской Федерации или удостоенные благодарности Президента Российской Федерации, либо награжденные ведомственными знаками отличия за заслуги в труде (службе) и продолжительную работу (службу) не менее 15 лет в соответствующей сфере деятельности (отрасли экономики) и имеющие трудовой (страховой) стаж, учитываемый для назначения пенсии, не менее 25 лет для мужчин и 20 лет для женщин или выслугу лет, необходимую для назначения пенсии за выслугу лет в календарном исчислении;</li>
                    <li>граждане Российской Федерации, постоянно проживающие на территории Челябинской области начавшие трудовую деятельность в несовершеннолетнем возрасте в период Великой Отечественной войны и имеющие трудовой (страховой) стаж не менее 40 лет для мужчин и 35 лет для женщин.</li>
                </ul>


                <div className="small-title">срок предоставления государственной услуги</div>
                <ul className="text">
                    <li>срок предоставления государственной услуги - 42 рабочих дня</li>
                </ul>

                <div className="small-title">исчерпывающий перечень документов</div>
                <ul className="text">
                    <li>заявление</li>
                    <li>документ, удостоверяющий личность (при обращении представителя заявителя - документы, подтверждающие его полномочия);</li>
                    <li>фотография размером 3 х 4 сантиметра;</li>
                    <li>
                        <div>
                            <ul className="point">документы, подтверждающие право на присвоение звания «Ветеран труда»:
                                <li style={{ marginLeft: '2em' }}>
                                    <p>
                                        <ul className="point">
                                            для граждан, награжденных орденами или медалями СССР или Российской Федерации, либо удостоенные почетных званий СССР или Российской Федерации, либо награжденных почетными грамотами Президента Российской Федерации или удостоенных благодарности Президента Российской Федерации, либо награжденных ведомственными знаками отличия за заслуги в труде (службе) и продолжительную работу (службу) не менее 15 лет в соответствующей сфере деятельности (отрасли экономики) и имеющих трудовой (страховой) стаж, учитываемый для назначения пенсии, не менее 25 лет для мужчин и 20 лет для женщин или выслугу лет, необходимую для назначения пенсии за выслугу лет в календарном исчислении:
                                             <li style={{ marginLeft: '2em' }}>документы к орденам, медалям, почетным званиям СССР или Российской Федерации, почетная грамота или благодарность Президента Российской Федерации, документы к ведомственным знакам отличия (для граждан, награжденных до 01.07.2016 г., – документы к ведомственным знакам отличия в труде).
                                            В случае утраты документов к наградам в подтверждение факта награждения представляются дубликаты документов к орденам, медалям, почетным званиям СССР или Российской Федерации либо справки о награждении, выданные уполномоченными органами;
</li>
                                            <li style={{ marginLeft: '2em' }}>справка, выданная уполномоченными органами в соответствии с пенсионным законодательством Российской Федерации, о наличии трудового (страхового) стажа, учитываемого для назначения пенсии, не менее 25 лет для мужчин и 20 лет для женщин или выслуги лет, необходимой для назначения пенсии за выслугу лет в календарном исчислении.
                                            Справка выдается:
                                            гражданам, осуществляющим трудовую деятельность, - кадровыми службами организаций по месту трудовой деятельности;
                                            </li>
                                            <a>Ссылка на подпункты 1, 3 пункта 11 раздела II Административного регламента</a>
                                        </ul>
                                    </p>
                                </li>
                                <li style={{ marginLeft: '2em' }}>
                                    <p>
                                        <ul className="point">
                                            для начавших трудовую деятельность в несовершеннолетнем возрасте в период Великой Отечественной войны и имеющих трудовой (страховой) стаж не менее 40 лет для мужчин и 35 лет для женщин:
                                             <li style={{ marginLeft: '2em' }}>документы к орденам, медалям, почетным званиям СССР или Российской Федерации, почетная грамота или благодарность Президента Российской Федерации, документы к ведомственным знакам отличия (для граждан, награжденных до 01.07.2016 г., – документы к ведомственным знакам отличия в труде).
                                            трудовые книжки, справки архивных учреждений или справки уполномоченных организаций, выданные на основании архивных документов, подтверждающие начало трудовой деятельности в несовершеннолетнем возрасте в период с 22 июня 1941 года по 9 мая 1945 года, исключая время работы на временно оккупированной территории;
</li>
                                            <li style={{ marginLeft: '2em' }}>б) справка, выданная уполномоченными органами в соответствии с пенсионным законодательством Российской Федерации, о наличии трудового (страхового) стажа не менее 40 лет для мужчин и 35 лет для женщин;
                                            Сведения о продолжительности страхового (трудового) стажа могут быть запрошены органами социальной защиты населения в рамках межведомственного информационного взаимодействия в уполномоченных органах, осуществляющих пенсионное обеспечение.

                                            </li>
                                            <a>Ссылка на подпункт 2 пункта 11 раздела II Административного регламента</a>
                                        </ul>
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
                <div className="small-title">результат предоставления государственной услуги</div>
                <ul className="text">
                    <li>Результат предоставления государственной услуги - присвоение звания "Ветеран труда" и выдача удостоверения "Ветеран труда", выдача дубликата удостоверения "Ветеран труда".</li>
                </ul>
                <div className="small-title">Основания для отказа в приеме документов </div>
                <ul className="text">
                    <li>неполного представления или отсутствия документов, указанных в Административном регламенте;</li>
                    <li>документы имеют подчистки, приписки, зачеркнутые слова и иные не оговоренные в них исправления;</li>
                    <li>не предоставление в срок, установленный Административным регламентом, оригиналов документов, обязанность по предоставлению которых возложена на заявителя (при подаче документов посредством заполнения электронной формы запроса с использованием федерального портала).</li>
                </ul>


                <div className="small-title">основания для отказа в предоставлении государственной услуги</div>
                <ul className="text">
                    <li>отсутствие гражданства Российской Федерации;</li>
                    <li>наличие трудового (страхового) стажа, учитываемого для назначения пенсии, менее 25 лет для мужчин и 20 лет для женщин либо отсутствие выслуги лет, необходимой для назначения пенсии за выслугу лет в календарном исчислении;</li>
                    <li>знак отличия в труде не учитывается при присвоении звания "Ветеран труда";</li>
                    <li>отсутствие документов, подтверждающих факт начала работы в период Великой Отечественной войны в несовершеннолетнем возрасте.</li>
                </ul>


            </div>

        </div>

    </Container >)
}

export default Act1;