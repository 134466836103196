import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                <li className="breadcrumb-item active" aria-current="page"><Link to="/certificateBase" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами</Link></li>
            </ol>
        </nav>

        <div className='white-card'>

            <div className='card-body'>
                <p><a target="_blank" href="http://minsoc74.ru/sites/default/files/4_zayavlenie_invalida_voyny.doc">Шаблон заявления</a></p>

                <p>Разъяснения по заполнению заявления</p>
                <p>В шапке заявления указываются:
                фамилия, имя, отчество заявителя, дата рождения; адрес регистрации по месту жительства (указываются индекс, населенный пункт, улица, номер дома, номер квартиры (при наличии)); серия и номер, дата выдачи и наименование органа, выдавшего паспорт; реквизиты пенсионного удостоверения (либо информация об органе, осуществляющем пенсионное обеспечение).</p>
                <p>Текст заявления стандартный для всех (заполнен).</p>
                <p>Далее указываются наименования документов, прилагаемых к заявлению:</p>

                <p>* Сведения, подтверждающие факт установления инвалидности и отражающие ее причину (при наличии сведений об инвалидности в федеральной государственной информационной системе «Федеральный реестр инвалидов»), а также сведения о пенсионном обеспечении запрашиваются органами социальной защиты населения в рамках межведомственного информационного взаимодействия.</p>
                <p>При наличии сведений об инвалидности в федеральной государственной информационной системе «Федеральный реестр инвалидов» заявитель вправе самостоятельно по собственной инициативе представить документы об инвалидности.</p>
                <p>При отсутствии сведений об инвалидности в федеральной государственной информационной системе «Федеральный реестр инвалидов» документы об инвалидности (справки МСЭ (ВТЭК) представляются заявителями.</p>

                <br />
                <div className="small-title">
                    Способ подачи заявления о выдаче удостоверения инвалида войны</div>
                <ul className="text">
                    <li>при обращении в органы социальной защиты населения по месту жительства 
                      <a target="_blank" href="http://minsoc74.ru/municipalnye-upravleniya-i-uchrezhdeniya">Ссылка</a>
</li>
                    <li>при обращении в многофункциональные центры предоставления государственных и муниципальных услуг
                    <a target="_blank" href="https://mfc-74.ru">Ссылка</a>
</li>
                </ul>


                <div className="small-title">Способ получения результата рассмотрения заявления о выдаче удостоверения инвалида войны</div>
                <ul className="text">
                    <li>при вынесении решения о выдаче удостоверения инвалида войны удостоверение может быть получено в органах социальной защиты населения по месту жительства 
                    <a target="_blank" href="http://minsoc74.ru/municipalnye-upravleniya-i-uchrezhdeniya">Ссылка</a>

</li>
                    <li>  решение об отказе в выдаче удостоверения инвалида войны направляется заявителю на домашний адрес почтовым отправлением</li>
                </ul>

                <p><Link to="/action/4" style={{ cursor: 'pointer' }}>Общая информация о выдаче удостоверения инвалида войны</Link></p>
            </div>

        </div>

    </Container >)
}

export default Act1;