import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css';
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                <li className="breadcrumb-item active" aria-current="page"><Link to="/certificateRadiation" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами, пострадавшими от воздействия радиации</Link></li>
            </ol>
        </nav>
        <div className="name">Получили заболевание,  связанное с радиационными авариями и катастрофами</div>

        <div className='white-card'>

            <div className='card-body'>
                <div className="small-title">Перечень документов:</div>
                <ul className="text">
                    <li>Паспорт РФ;</li>
                    <li>удостоверение установленного образца;</li>
                    <li>заключение межведомственного экспертного совета или вонно-врачебной комиссии о причинной связи развившихся заболеваний и инвалидности с радиационным воздействием; </li>
                    <li>справка МСЭ;</li>
                    <li>Фото.</li>

                </ul>
            </div>

        </div>

    </Container >)
}

export default Act1;