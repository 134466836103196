import React, { useState } from 'react';
import { Container, Form, FormGroup, Label, Input, Col, Row, Table, Button } from 'reactstrap';
import './Action.css'

const Act23 = () => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><a href="/">Главная</a></li>
                <li className="breadcrumb-item"><a href="/jku">Субсидии на ЖКУ</a></li>
                <li className="breadcrumb-item active" aria-current="page">Социальный калькулятор для расчета субсидии.</li>
            </ol>
        </nav>
        <div className="name">Социальный калькулятор</div>

        <div className='white-card'>

            <div className='card-body'>
                <ul className="text">
                    <li>Вы можете самостоятельно сделать предварительный расчет размера субсидии на оплату жилого помещения и коммунальных услуг. При этом подчеркиваем, что полученный на основании расчета результат является ориентировочным и может служить лишь основанием для Вашего обращения в орган социальной защиты населения по месту постоянной регистрации
                        <a target="_blank" href="https://minsoc.gov74.ru/minsoc/documents/oblastnoe-zakonodatelstvo.htm">Приложение № 1 постановления Правительства Челябинской области от 23 мая 2012 г. № 247-П  «Об Административном регламенте предоставления государственной услуги  «Предоставление гражданам субсидии на оплату жилого помещения и коммунальных услуг»</a>
                    </li>
                </ul>

                <Form>
                    <FormGroup>
                        <Label className="small-title">Планируемая дата обращения за субсидией</Label>
                        <Input type="date" name="dateEx" />
                    </FormGroup>
                    <FormGroup>
                        <Label className="small-title">Наименование населенного пункта</Label>
                        <Input type="select" name="select" id="exampleSelect">
                            <option>Ничего не выбрано</option>
                            <option>Населенный пункт 1</option>
                            <option>Населенный пункт 2</option>
                            <option>Населенный пункт 3</option>
                            <option>Населенный пункт 4</option>
                            <option>Населенный пункт 5</option>
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <Label className="small-title">Наименование сельского поселения(города, района)</Label>
                        <Input type="select" name="select">
                            <option>Ничего не выбрано</option>
                            <option>Наименование сельского поселения 2</option>
                            <option>Наименование сельского поселения 3</option>
                            <option>Наименование сельского поселения 4</option>
                            <option>Наименование сельского поселения 5</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label className="small-title">Вид регионального стандарта в зависимости от основания пользования жилым помещением</Label>
                        <Input type="select" name="select">
                            <option>Ничего не выбрано</option>
                            <option>для собственников жилых помещений в многоквартирных домах, которые в соответствии с требованиями Жилищного кодекса Российской Федерации обязаны вносить взносы на капитальный ремонт;</option>
                            <option>для собственников жилых помещений в многоквартирных домах, которые в соответствии с требованиями Жилищного кодекса Российской Федерации не обязаны вносить взносы на капитальный ремонт</option>
                            <option>для собственников жилых домов;</option>
                            <option>для пользователей жилого помещения в государственном или муниципальном жилищном фонде, нанимателей жилого помещения по договору найма в частном жилищном фонде и членов жилищного кооператива, жилищно-строительного кооператива, иного специализированного потребительского кооператива, которым жилое помещение предоставлено в соответствии с требованиями законодательства Российской Федерации до приобретения ими права собственности на такое жилое помещение</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label className="small-title">Категория семей</Label>
                        <Input type="select" name="select">
                            <option>Ничего не выбрано</option>
                            <option>одиноко проживающий пенсионер или семья, состоящая из неработающих пенсионеров, достигшие возраста, дающего право на получение пенсии по старости</option>
                            <option>многодетные семьи;</option>
                            <option>семьи, в состав которых входят  одинокие матери с одним или более несовершеннолетними детьми;</option>
                            <option>иные категории семей;</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <div className="small-title">Сведения о составе, доходах семьи и расходах на оплату ЖКУ</div>
                        <div className="m-4">
                            <Row>
                                <Col sm={7}>
                                    <Label>Общее количество лиц, зарегистрированных по месту жительства</Label>
                                </Col>
                                <Col sm={15}>
                                    <Input type="number" min="0" name="dateEx" />
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Родственные отношения</th>
                                            <th>Категория</th>
                                            <th>Доходы за расчетный период, руб.</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>заявитель</td>
                                            <td> <Input type="select" name="select">
                                                <option>Ничего не выбрано</option>
                                                <option>трудоспособный</option>
                                                <option>пенсионер</option>
                                                <option>дети до 16 лет</option>
                                            </Input></td>
                                            <td>
                                                <Row>
                                                    <Col sm={6}>
                                                        <Label className="pt-2">с 01.07.2020 по 31.07.2020,</Label>
                                                    </Col>
                                                    <Col sm={5}>
                                                        <Input type="number" min="0" />
                                                    </Col>
                                                    <Col sm={1}>
                                                        <Label>рублей.</Label>
                                                    </Col>
                                                </Row>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <Input type="select" name="select">
                                                <option>Ничего не выбрано</option>
                                                <option>супруг/супруга</option>
                                                <option>дочь/сын</option>
                                                <option>родители</option>
                                                <option>родители супруга/супруги</option>
                                                <option>другой член семьи</option>
                                            </Input></td>
                                            <td> <Input type="select" name="select">
                                                <option>Ничего не выбрано</option>
                                                <option>трудоспособный</option>
                                                <option>пенсионер</option>
                                                <option>дети до 16 лет</option>
                                            </Input></td>
                                            <td>
                                                <Row>
                                                    <Col sm={6}>
                                                        <Label className="pt-2">с 01.07.2020 по 31.07.2020,</Label>
                                                    </Col>
                                                    <Col sm={5}>
                                                        <Input type="number" min="0" />
                                                    </Col>
                                                    <Col sm={1}>
                                                        <Label>рублей.</Label>
                                                    </Col>
                                                </Row>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <Input type="select" name="select">
                                                <option>Ничего не выбрано</option>
                                                <option>супруг/супруга</option>
                                                <option>дочь/сын</option>
                                                <option>родители</option>
                                                <option>родители супруга/супруги</option>
                                                <option>другой член семьи</option>
                                            </Input></td>
                                            <td> <Input type="select" name="select">
                                                <option>Ничего не выбрано</option>
                                                <option>трудоспособный</option>
                                                <option>пенсионер</option>
                                                <option>дети до 16 лет</option>
                                            </Input></td>
                                            <td>
                                                <Row>
                                                    <Col sm={6}>
                                                        <Label className="pt-2">с 01.07.2020 по 31.07.2020,</Label>
                                                    </Col>
                                                    <Col sm={5}>
                                                        <Input type="number" min="0" />
                                                    </Col>
                                                    <Col sm={1}>
                                                        <Label>рублей.</Label>
                                                    </Col>
                                                </Row>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <Input type="select" name="select">
                                                <option>Ничего не выбрано</option>
                                                <option>супруг/супруга</option>
                                                <option>дочь/сын</option>
                                                <option>родители</option>
                                                <option>родители супруга/супруги</option>
                                                <option>другой член семьи</option>
                                            </Input></td>
                                            <td> <Input type="select" name="select">
                                                <option>Ничего не выбрано</option>
                                                <option>трудоспособный</option>
                                                <option>пенсионер</option>
                                                <option>дети до 16 лет</option>
                                            </Input></td>
                                            <td>
                                                <Row>
                                                    <Col sm={6}>
                                                        <Label className="pt-2">с 01.07.2020 по 31.07.2020,</Label>
                                                    </Col>
                                                    <Col sm={5}>
                                                        <Input type="number" min="0" />
                                                    </Col>
                                                    <Col sm={1}>
                                                        <Label>рублей.</Label>
                                                    </Col>
                                                </Row>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <Input type="select" name="select">
                                                <option>Ничего не выбрано</option>
                                                <option>супруг/супруга</option>
                                                <option>дочь/сын</option>
                                                <option>родители</option>
                                                <option>родители супруга/супруги</option>
                                                <option>другой член семьи</option>
                                            </Input></td>
                                            <td> <Input type="select" name="select">
                                                <option>Ничего не выбрано</option>
                                                <option>трудоспособный</option>
                                                <option>пенсионер</option>
                                                <option>дети до 16 лет</option>
                                            </Input></td>
                                            <td>
                                                <Row>
                                                    <Col sm={6}>
                                                        <Label className="pt-2">с 01.07.2020 по 31.07.2020,</Label>
                                                    </Col>
                                                    <Col sm={5}>
                                                        <Input type="number" min="0" />
                                                    </Col>
                                                    <Col sm={1}>
                                                        <Label>рублей.</Label>
                                                    </Col>
                                                </Row>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <Input type="select" name="select">
                                                <option>Ничего не выбрано</option>
                                                <option>супруг/супруга</option>
                                                <option>дочь/сын</option>
                                                <option>родители</option>
                                                <option>родители супруга/супруги</option>
                                                <option>другой член семьи</option>
                                            </Input></td>
                                            <td> <Input type="select" name="select">
                                                <option>Ничего не выбрано</option>
                                                <option>трудоспособный</option>
                                                <option>пенсионер</option>
                                                <option>дети до 16 лет</option>
                                            </Input></td>
                                            <td>
                                                <Row>
                                                    <Col sm={6}>
                                                        <Label className="pt-2">с 01.07.2020 по 31.07.2020,</Label>
                                                    </Col>
                                                    <Col sm={5}>
                                                        <Input type="number" min="0" />
                                                    </Col>
                                                    <Col sm={1}>
                                                        <Label>рублей.</Label>
                                                    </Col>
                                                </Row>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <Input type="select" name="select">
                                                <option>Ничего не выбрано</option>
                                                <option>супруг/супруга</option>
                                                <option>дочь/сын</option>
                                                <option>родители</option>
                                                <option>родители супруга/супруги</option>
                                                <option>другой член семьи</option>
                                            </Input></td>
                                            <td> <Input type="select" name="select">
                                                <option>Ничего не выбрано</option>
                                                <option>трудоспособный</option>
                                                <option>пенсионер</option>
                                                <option>дети до 16 лет</option>
                                            </Input></td>
                                            <td>
                                                <Row>
                                                    <Col sm={6}>
                                                        <Label className="pt-2">с 01.07.2020 по 31.07.2020,</Label>
                                                    </Col>
                                                    <Col sm={5}>
                                                        <Input type="number" min="0" />
                                                    </Col>
                                                    <Col sm={1}>
                                                        <Label>рублей.</Label>
                                                    </Col>
                                                </Row>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <Input type="select" name="select">
                                                <option>Ничего не выбрано</option>
                                                <option>супруг/супруга</option>
                                                <option>дочь/сын</option>
                                                <option>родители</option>
                                                <option>родители супруга/супруги</option>
                                                <option>другой член семьи</option>
                                            </Input></td>
                                            <td> <Input type="select" name="select">
                                                <option>Ничего не выбрано</option>
                                                <option>трудоспособный</option>
                                                <option>пенсионер</option>
                                                <option>дети до 16 лет</option>
                                            </Input></td>
                                            <td>
                                                <Row>
                                                    <Col sm={6}>
                                                        <Label className="pt-2">с 01.07.2020 по 31.07.2020,</Label>
                                                    </Col>
                                                    <Col sm={5}>
                                                        <Input type="number" min="0" />
                                                    </Col>
                                                    <Col sm={1}>
                                                        <Label>рублей.</Label>
                                                    </Col>
                                                </Row>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Row>
                        </div>
                    </FormGroup>

                    <FormGroup>
                        <Label className="small-title">Сумма фактических расходов на оплату жилищно-коммунальных услуг, руб.    </Label>
                        <Input type="text" name="dateEx" />
                    </FormGroup>
                    <FormGroup className="mt-4">
                        <div className="small-title">Расчет</div>
                        <Table className="mt-2">
                            <thead>
                                <tr>
                                    <th>Наименование</th>
                                    <th>Значение</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Размер установленного регионального стандарта стоимости ЖКУ</td>
                                    <td>Исходя из выбранного значения «Сведения об условиях проживания»</td>
                                </tr>
                                <tr>
                                    <td>Региональный стандарт максимально допустимой доли расходов</td>
                                    <td>Исходя из выбранного значения «Категории семьи»</td>
                                </tr>
                                <tr>
                                    <td>Совокупный доход в месяц, руб.</td>
                                    <td>Исходя из значения  заполненного дохода</td>
                                </tr>
                                <tr>
                                    <td>Среднедушевой доход семьи</td>
                                    <td>Совокупный доход/количество членов семьи), СД</td>
                                </tr>
                                <tr>
                                    <td>Прожиточный минимум семьи</td>
                                    <td>Исходя из выбранного значения «Категория», ПМ</td>
                                </tr>
                                <tr>
                                    <td>Поправочный коэффициент</td>
                                    <td>К=СД/ПМ</td>
                                </tr>
                            </tbody>
                        </Table>
                    </FormGroup>
                    <div className="d-flex justify-content-center">
                        <Button className="m-1" outline color="primary">Рассчитать</Button>{' '}
                        <Button className="m-1" color="secondary">Отмена</Button>{' '}
                    </div>
                </Form>


            </div>

        </div>

    </Container >)
}

export default Act23;