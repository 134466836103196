import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import { Link } from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/children"> Социальная поддержка семей с детьми</Link></li>
            </ol>
        </nav>
        <div className="name">Назначение и осуществление ежемесячной денежной выплаты на ребенка в возрасте от трех до семи лет включительно </div>

        <div className='white-card'>

            <div className='card-body'>
                <text>Право на выплату имеет один из родителей или законных представителей ребёнка. И заявитель и ребёнок должны быть гражданами Российской Федерации. Заявитель также должен проживать на территории России.</text>
                <div className="small-title">Право на ежемесячную выплату возникает при совпадении таких условий:</div>
                <ul className="text">
                    <li>У вас и ребёнка есть гражданство РФ.</li>
                    <li>Вы постоянно живете в России.</li>
                    <li>Являетесь родителем или опекуном ребёнка в возрасте от 3 до 7 лет включительно.</li>
                    <li>Среднедушевой доход всех членов семьи не превышает одного регионального прожиточного минимума на душу населения.</li>
                </ul>

                <div className="small-title">Выплата назначается в том случае, если совокупность принадлежащего семье имущества укладывается в рамки этого перечня:</div>
                <ul className="text">
                    <li>одна квартира любой площади. Или несколько квартир, если площадь на каждого члена семьи меньше 24 квадратных метров. Непригодные для проживания помещения не учитываются при оценке нуждаемости. Также не учитываются квартиры, полученные многодетными семьями в качестве меры социальной поддержки; доли не более 1/3 от общей площади квартиры; квартира (ее часть), занимаемая членом семьи, страдающим тяжелой формой хронического заболевания, при которых невозможно совместное проживание граждан в одной квартире</li>
                    <li>один дом любой площади. Или несколько домов, если площадь на каждого члена семьи меньше 40 квадратных метров. Непригодные для проживания помещения не учитываются при оценке нуждаемости. Также не учитываются дома, полученные многодетными семьями в качестве меры социальной поддержки; доли не более 1/3 от общей площади дома; дом (его часть), занимаемая членом семьи, страдающим тяжелой формой хронического заболевания, при которых невозможно совместное проживание граждан в одной квартире</li>
                    <li>один гараж, машиноместо. Или два, если семья многодетная или семье автотранспортное или мототранспортное средство было выдано в рамках мер социальной поддержки, а также если у члена семьи есть инвалидность</li>
                    <li>земельный участок общей площадью не более 0,25 га, а для территории сельских поселений или межселенных территорий - 1 гектар. Не учитываются земельные участки, полученные многодетными семьями в качестве меры социальной поддержки, и дальневосточный гектар, а также земельные участки, находящиеся в общей долевой собственности земельных участков и земель сельскохозяйственного назначения</li>
                    <li>одно нежилое помещение. Не учитываются хозяйственные постройки, расположенные на земельных участках, предназначенных для ИЖС, ведения ЛПХ, садовых земельных участках, а также имущество, являющиеся общим имуществом в многоквартирном доме, имущество, являющиеся имуществом общего пользования садоводческого или огороднического некоммерческого товарищества</li>
                    <li>один мотоцикл. Или два, если семья многодетная, член семьи имеет инвалидность или мотоцикл получен в качестве меры соцподдержки</li>
                    <li>деньги на банковских вкладах, если годовой доход от процентов не больше прожиточного минимума в целом по России (в среднем — около 250 тыс. рублей в виде сбережений)</li>
                    <p>Семья может владеть квартирой, жилым домом, дачей, земельными участками, гаражом, автомобилем, мотоциклом, трактором и лодкой одновременно.</p>
                    <p>Если у семьи есть автомобиль младше 5 лет с двигателем мощностью более 250 лошадиных сил, права на выплату не возникает независимо от величины среднедушевого дохода. Исключение составляют случаи, когда такой автомобиль принадлежит семье с четырьмя и более детьми — и это микроавтобус, в котором более 5 посадочных мест.</p>
                    <p>Доход от вкладов на банковских счетах и вкладах, если среднедушевой доход от процентов не больше прожиточного минимума на душу населения в целом по РФ</p>
                </ul>

                <div className="small-title">От чего зависит размер выплаты:</div>
                <ul className="text">
                    <li>50% регионального прожиточного минимума для детей, базовый размер выплаты</li>
                    <li>75% регионального прожиточного минимума для детей, если при выплате в размере 50% среднедушевой доход семьи не достиг регионального прожиточного минимума на душу населения</li>
                    <li>100% регионального прожиточного минимума для детей, если при выплате в размере 75% среднедушевой доход семьи не достиг регионального прожиточного минимума на душу населения</li>
                </ul>

                <div className="small-title">Нормативно правовые акты </div>
                <ul className="text">

                    <li><Link target={"_blank"} to={{ pathname: "http://publication.pravo.gov.ru/Document/View/0001202003200014" }}>Указ Президента Российской Федерации от 20.03.2020 № 199 "О дополнительных мерах государственной поддержки семей, имеющих детей" </Link></li>
                    <li><Link target={"_blank"} to={{ pathname: "http://publication.pravo.gov.ru/Document/View/0001202004030035" }}>Постановление Правительства Российской Федерации от 31.03.2020 № 384 "Об утверждении основных требований к порядку назначения и осуществления ежемесячной денежной выплаты на ребенка в возрасте от 3 до 7 лет включительно, примерного перечня документов (сведений), необходимых для назначения указанной ежемесячной выплаты, и типовой формы заявления о ее назначении" </Link></li>
                    <li><Link target={"_blank"} to={{ pathname: "https://docs.cntd.ru/document/570737470 " }}>Закон Челябинской области от 09.04.2020 № 126-ЗО "О ежемесячной денежной выплате на ребенка в возрасте от трех до семи лет включительно" ознакомиться можно </Link></li>
                    <li><Link target={"_blank"} to={{ pathname: "https://docs.cntd.ru/document/570787543 " }}>Административный регламент «Назначение и выплата ежемесячной денежной выплаты на ребенка в возрасте от трёх до семи лет включительно», утвержденный постановлением Правительства Челябинской области от 30.04.2020 № 177-п. </Link></li>
                </ul>

                <p>Подробные сведения о пособии размещены <Link target={"_blank"} to={{ pathname: "https://minsoc.gov74.ru/minsoc/mery-socialnoy-podderzhki-grazhdan/socialnaya-podderzhka-semey-s-detmi/naznachenie-gosudarstvennyh-posobiy-grazhdanam-imeyushchim-detey-1/ezhemesyachnaya-denezhnaya-vyplata-na-rebenka-v-vozraste-ot-tryoh-do-semi-let-vklyuchitelno.htm" }}>по ссылке</Link></p>
                <p>Подать заявление можно
                    <Link target={"_blank"} to={{ pathname: "https://www.gosuslugi.ru/434403/2 " }}> по ссылке</Link>
                </p>
                <p>Если нужно подать заявление на внесение изменений на платёжные реквизиты, воспользуйтесь услугой
                    <Link target={"_blank"} to={{ pathname: "https://www.gosuslugi.ru/434403/3 " }}> по ссылке</Link>
                </p>
            </div>

        </div>

    </Container >)
}

export default Act1;