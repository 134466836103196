import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';



export class Children extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first: true,
            second: false,
            third: false,
            fourth: false,
            fivth: false,
            list: ['44', '45', '46', '47']
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleStartClick = this.handleStartClick.bind(this);
        this.renderList = this.renderList.bind(this);
    }

    componentDidMount() {
        this.renderList();
    }

    componentDidUpdate() {
        this.renderList();
    }

    handleChange(e) {
        const VALUE = e.currentTarget.value;
        debugger;
        if (VALUE.indexOf('state') > -1) {
            const listVAl = VALUE.split(':'),
                id = listVAl[1];

            switch (id) {
                case '1':
                    this.setState({ list: ['44', '45', '46', '47'] });
                    break;
                case 'second':
                    this.setState({ first: false, second: true, third: false, list: ['48', '49', '50', '51', '52', '53', '54'] });
                    break;
                case '2':
                    this.setState({ list: ['48', '49', '50', '51', '52', '53', '54'] });
                    break;
                case 'third':
                    this.setState({ first: false, second: false, third: true, list: ['55', '56'] });
                    break;
                case '3':
                    this.setState({ list: ['55', '56'] })
                    break;
                case 'fourth':
                    this.setState({ first: false, second: false, third: false, fourth: true, list: ['57', '58'] });
                    break;
                case '4':
                    this.setState({ list: ['57'] })
                    break;
                case 'none':
                    this.setState({ list: [] });
            }
        }
        else {
            const listVAl = VALUE.split(';');
            this.setState({ list: listVAl });
        }
    }


    renderList() {
        const { list } = this.state;

        const Baselist = ['44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '93'];

        Baselist.forEach(item => {
            let u = document.getElementById(`action_id_${item}`);
            u.style.display = 'none';
        });

        list.forEach(item => {
            let u = document.getElementById(`action_id_${item}`);
            u.style.display = 'block';
        });

    }

    handleStartClick(e) {
        this.setState({
            first: true,
            second: false,
            third: false,
            fourth: false,
            fivth: false,
            list: ['44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '93']
        })
    }

    render() {
        const { first, second, third, fourth, fivth } = this.state;
        return (
            <Container>
                <Row>
                    <Col md='12' lg='12'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb pl-0">
                                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                                <li className="breadcrumb-item active"><a onClick={this.handleStartClick} style={{ cursor: 'pointer' }}>Социальная поддержка семей с детьми</a></li>
                            </ol>
                        </nav>
                    </Col>
                </Row>
                <Row>
                    <div className='head-list-child mt-30'>
                        <div className='title-head-list title-head-list-child'>Социальная поддержка семей с детьми</div>
                    </div>
                    <div className='white-box-child last pt-0'>
                        <Col md='12' lg='7' className='mb-3'>
                            {first ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>У Вас родился ребенок?</label>
                                    </div>

                                    <div className="element_string attribute_0">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input value='state:1' type="radio" id="attribute_value0" name="attribute_0" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" htmlFor="attribute_value0">Да</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input value="state:second" type="radio" id="attribute_value1" name="attribute_0" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" htmlFor="attribute_value1">Нет</label>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null
                            }

                            {second ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>У Вас малообеспеченная семья?</label>
                                    </div>

                                    <div className="element_string attribute_1">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input value='state:2' type="radio" id="attribute_value2" name="attribute_1" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" htmlFor="attribute_value2">Да</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input value="state:third" type="radio" id="attribute_value3" name="attribute_1" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" htmlFor="attribute_value3">Нет</label>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null
                            }

                            {third ? (<div><div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>У Вас многодетная семья?</label>
                                    </div>

                                    <div className="element_string attribute ">
                                        <div className="custom-control custom-radio">
                                            <input value='state:3' type="radio" id="attribute_value4" name="attribute_2" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" htmlFor="attribute_value4">Да</label>
                                        </div>

                                        <div className="custom-control custom-radio">
                                            <input value="state:fourth" type="radio" id="attribute_value5" name="attribute_2" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" htmlFor="attribute_value5">Нет</label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                                <div className="label-group dop-text-info">
                                    <p className="mt-5"><strong>Многодетной семьей в Челябинской области признается семья, постоянно проживающая на территории Челябинской области </strong>и имеющая на своем содержании трех и более детей (в том числе усыновленных, взятых под опеку (попечительство), пасынков и падчериц) в возрасте до восемнадцати лет, а также детей старше восемнадцати лет, обучающихся в образовательных организациях по очной форме обучения, но не более чем до достижения ими возраста 23 лет (статья 1 Закона Челябинской области от 31 марта 2010 г. N 548-ЗО "О статусе и дополнительных мерах социальной поддержки многодетной семьи в Челябинской области")</p>
                                    <h4>Важно знать!!!</h4>
                                    <p><strong>С 1 января 2022 года </strong>будет расширен статус многодетной семьи</p>
                                </div>
                            </div>
                            )
                                : null
                            }
                            {fourth ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>В Вашей семье на воспитании ребенок под опекой?</label>
                                    </div>

                                    <div className="element_string attribute_3">
                                        <div className="custom-control custom-radio">
                                            <input value='state:4' type="radio" id="attribute_value6" name="attribute_3" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" htmlFor="attribute_value6">Да</label>
                                        </div>

                                        <div className="custom-control custom-radio">
                                            <input value="state:fivth" type="radio" id="attribute_value7" name="attribute_3" className="custom-control-input" onChange={() => { this.setState({ fourth: false, fivth: true, list: ['93'] }); }} />
                                            <label className="custom-control-label label-group" htmlFor="attribute_value7">Нет</label>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null
                            }

                            {fivth ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>В Вашей семье произошло скорбной событие (смерть) или произошло рождение мертвого ребенка по истечении 154 дней беременности?</label>
                                    </div>

                                    <div className="element_string attribute_3">
                                        <div className="custom-control custom-radio">
                                            <input value='state:5' type="radio" id="attribute_value8" name="attribute_4" className="custom-control-input" onChange={() => { this.setState({ list: ['93'] }); }} />
                                            <label className="custom-control-label label-group" htmlFor="attribute_value8">Да</label>
                                        </div>

                                        <div className="custom-control custom-radio">
                                            <input value="state:none" type="radio" id="attribute_value9" name="attribute_4" className="custom-control-input" onChange={() => { this.setState({ list: [] }); }} />
                                            <label className="custom-control-label label-group" htmlFor="attribute_value9">Нет</label>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null
                            }
                        </Col>
                        <Col md='12' lg='5' className='mb-3'>
                            <div className="element_group element-group-usl">
                                <div className="title-group title-group-usl">
                                    <label>Список услуг</label>
                                </div>

                                <div className="element_string attribute_0">
                                    <ul className="list-group list-group-flush" id="actions_list">

                                        <li id="action_id_46" className="">
                                            <Link to="/action/46">Областное единовременное пособие при рождении ребенка</Link>
                                        </li>
                                        <li id="action_id_47" className="">
                                            <Link to="/action/47">Назначение и осуществление ежемесячной выплаты в связи с рождением (усыновлением) первого ребенка</Link>
                                        </li>
                                        <li id="action_id_44" className="">
                                            <Link to="/action/44">{true ? '' : 'Единовременное пособие при рождении ребенка'}</Link>
                                        </li>
                                        <li id="action_id_45" className="">
                                            <Link to="/action/45">{true ? '' : 'Пособие по уходу за ребенком (до 1.5 лет)(ЕЖЕМЕСЯЧНО)'}</Link>
                                        </li>

                                        <li id="action_id_48" className="">
                                            <Link to="/action/48">Пособие на ребенка (ЕЖЕМЕСЯЧНО)</Link>
                                        </li>
                                        <li id="action_id_49" className="">
                                            <Link to="/action/49">Пособие на детей разыскиваемых родителей (ЕЖЕМЕСЯЧНО)</Link>
                                        </li>
                                        <li id="action_id_50" className="">
                                            <Link to="/action/50">Пособие на детей военнослужащих, проходящих военную службу по призыву (ЕЖЕМЕСЯЧНО)</Link>
                                        </li>
                                        <li id="action_id_51" className="">
                                            <Link to="/action/51">Пособие на ребенка одинокой матери (ЕЖЕМЕСЯЧНО)</Link>
                                        </li>
                                        <li id="action_id_52" className="">
                                            <Link to="/action/52">Пособие на ребенка инвалида (ЕЖЕМЕСЯЧНО)</Link>
                                        </li>
                                        <li id="action_id_53" className="">
                                            <Link to="/action/53">Назначение и осуществление ежемесячной денежной выплаты на ребенка в возрасте от трех до семи лет включительно </Link>
                                        </li>
                                        <li id="action_id_54" className="">
                                            <Link to="/action/54">Распоряжение средствами (частью средств)областного материнского (семейного) капитала</Link>
                                        </li>


                                        <li id="action_id_55" className="">
                                            <Link to="/action/55">Назначение и выплата ежемесячной денежной выплаты, назначаемой в случае рождения третьего ребенка и (или) последующих детей до достижения ребенком возраста трех лет </Link>
                                        </li>
                                        <li id="action_id_56" className="">
                                            <Link to="/action/56">Денежная выплата по оплате жилого помещения и коммунальных услуг многодетным семьям(ЕЖЕМЕСЯЧНО)</Link>
                                        </li>


                                        <li id="action_id_57" className="">
                                            <Link to="/action/57">Выплата на содержание детей-сирот и детей, оставшихся без попечения родителей в семье опекуна (попечителя), приемной семье</Link>
                                        </li>
                                        <li id="action_id_58" className="">
                                            <Link to="/action/58">Предоставление единовременной денежной выплаты при передаче детей-сирот и детей, оставшихся без попечения родителей, на воспитание в семью</Link>
                                        </li>

                                        <li id="action_id_93" className="">
                                            <Link to="/action/93">Назначение и выплата социального пособия на погребение, возмещение стоимости услкг по погребению</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </Col>
                    </div>
                </Row>
            </Container>
        );
    }
}