import React, { useState } from 'react';
import Act1 from './Act1';
import Act2 from './Act2';
import Act3 from './Act3';
import Act4 from './Act4';
import Act5 from './Act5';
import Act6 from './Act6';
import Act7 from './Act7';
import Act8 from './Act8';
import Act9 from './Act9';
import Act10 from './Act10';
import Act11 from './Act11';
import Act12 from './Act12';
import Act13 from './Act13';
import Act14 from './Act14';
import Act15 from './Act15';
import Act16 from './Act16';
import Act17 from './Act17';
import Act18 from './Act18';
import Act19 from './Act19';
import Act20 from './Act20';
import Act21 from './Act21';
import Act22 from './Act22';
import Act23 from './Act23';
import Act24 from './Act24';
import Act25 from './Act25';
import Act26 from './Act26';
import Act27 from './Act27';
import Act28 from './Act28';
import Act29 from './Act29';
import Act30 from './Act30';
import Act32 from './Act32';
import Act33 from './Act33';
import Act35 from './Act35';
import Act36 from './Act36';
import Act37 from './Act37';
import Act38 from './Act38';
import Act39 from './Act39';
import Act40 from './Act40';
import Act41 from './Act41';
import Act42 from './Act42';
import Act43 from './Act43';
import Act44 from './Act44';
import Act45 from './Act45';
import Act46 from './Act46';
import Act47 from './Act47';
import Act48 from './Act48';
import Act49 from './Act49';
import Act50 from './Act50';
import Act51 from './Act51';
import Act52 from './Act52';
import Act53 from './Act53';
import Act54 from './Act54';
import Act55 from './Act55';
import Act56 from './Act56';
import Act57 from './Act57';
import Act58 from './Act58';
import Act59 from './Act59';
import Act60 from './Act60';
import Act61 from './Act61';
import Act62 from './Act62';
import Act63 from './Act63';
import Act64 from './Act64';
import Act65 from './Act65';
import Act66 from './Act66';
import Act67 from './Act67';
import Act68 from './Act68';
import Act69 from './Act69';
import Act70 from './Act70';
import Act71 from './Act71';
import Act72 from './Act72';
import Act73 from './Act73';
import Act74 from './Act74';
import Act75 from './Act75';
import Act76 from './Act76';
import Act77 from './Act77';
import Act78 from './Act78';
import Act79 from './Act79';
import Act80 from './Act80';
import Act81 from './Act81';
import Act82 from './Act82';
import Act83 from './Act83';
import Act84 from './Act84';
import Act85 from './Act85';
import Act86 from './Act86';
import Act87 from './Act87';
import Act88 from './Act88';
import Act89 from './Act89';
import Act90 from './Act90';
import Act91 from './Act91';
import Act92 from './Act92';
import Act93 from './Act93';
import Act94 from './Act94';
import Act95 from './Act95';
import Act96 from './Act96';
import Act97 from './Act97';
import Act98 from './Act98';
import Act99 from './Act99';
import Act100 from './Act100';
import Act101 from './Act101';
import Act102 from './Act102';
import Act103 from './Act103';
import Act104 from './Act104';
import Act105 from './Act105';
import Act107 from './Act107';
import Act108 from './Act108';

const Action = (props) => {

    const { id } = props.match.params;

    return (<div>
        {id === '1' && <Act1 />}
        {id === '2' && <Act2 />}
        {id === '3' && <Act3 />}
        {id === '4' && <Act4 />}
        {id === '5' && <Act5 />}
        {id === '6' && <Act6 />}
        {id === '7' && <Act7 />}
        {id === '8' && <Act8 />}
        {id === '9' && <Act9 />}
        {id === '10' && <Act10 />}
        {id === '11' && <Act11 />}
        {id === '12' && <Act12 />}
        {id === '13' && <Act13 />}
        {id === '14' && <Act14 />}
        {id === '15' && <Act15 />}
        {id === '16' && <Act16 />}
        {id === '17' && <Act17 />}
        {id === '18' && <Act18 />}
        {id === '19' && <Act19 />}
        {id === '20' && <Act20 />}
        {id === '21' && <Act21 />}
        {id === '22' && <Act22 />}
        {id === '23' && <Act23 />}
        {id === '24' && <Act24 />}
        {id === '25' && <Act25 />}
        {id === '26' && <Act26 />}
        {id === '27' && <Act27 />}
        {id === '28' && <Act28 />}
        {id === '29' && <Act29 />}
        {id === '30' && <Act30 />}
        {id === '31' && <Act57 />}
        {id === '32' && <Act32 />}
        {id === '33' && <Act33 />}
        {id === '35' && <Act35 />}
        {id === '36' && <Act36 />}
        {id === '37' && <Act37 />}
        {id === '38' && <Act38 />}
        {id === '39' && <Act39 />}
        {id === '40' && <Act40 />}
        {id === '41' && <Act41 />}
        {id === '42' && <Act42 />}
        {id === '43' && <Act43 />}
        {id === '44' && <Act44 />}
        {id === '45' && <Act45 />}
        {id === '46' && <Act46 />}
        {id === '47' && <Act47 />}
        {id === '48' && <Act48 />}
        {id === '49' && <Act49 />}
        {id === '50' && <Act50 />}
        {id === '51' && <Act51 />}
        {id === '52' && <Act52 />}
        {id === '53' && <Act53 />}
        {id === '54' && <Act54 />}
        {id === '55' && <Act55 />}
        {id === '56' && <Act56 />}
        {id === '57' && <Act57 />}
        {id === '58' && <Act58 />}
        {id === '59' && <Act59 />}
        {id === '60' && <Act60 />}
        {id === '61' && <Act61 />}
        {id === '62' && <Act62 />}
        {id === '63' && <Act63 />}
        {id === '64' && <Act64 />}
        {id === '65' && <Act65 />}
        {id === '66' && <Act66 />}
        {id === '67' && <Act67 />}
        {id === '68' && <Act68 />}
        {id === '69' && <Act69 />}
        {id === '70' && <Act70 />}
        {id === '71' && <Act71 />}
        {id === '72' && <Act72 />}
        {id === '73' && <Act73 />}
        {id === '74' && <Act74 />}
        {id === '75' && <Act75 />}
        {id === '76' && <Act76 />}
        {id === '77' && <Act77 />}
        {id === '78' && <Act78 />}
        {id === '79' && <Act79 />}
        {id === '80' && <Act80 />}
        {id === '81' && <Act81 />}
        {id === '82' && <Act82 />}
        {id === '83' && <Act83 />}
        {id === '84' && <Act84 />}
        {id === '85' && <Act85 />}
        {id === '86' && <Act86 />}
        {id === '87' && <Act87 />}
        {id === '88' && <Act88 />}
        {id === '89' && <Act89 />}
        {id === '90' && <Act90 />}
        {id === '91' && <Act91 />}
        {id === '92' && <Act92 />}
        {id === '93' && <Act93 />}
        {id === '94' && <Act94 />}
        {id === '95' && <Act95 />}
        {id === '96' && <Act96 />}
        {id === '97' && <Act97 />}
        {id === '98' && <Act98 />}
        {id === '99' && <Act99 />}
        {id === '100' && <Act100 />}
        {id === '101' && <Act101 />}
        {id === '102' && <Act102 />}
        {id === '103' && <Act103 />}
        {id === '104' && <Act104 />}
        {id === '105' && <Act105 />}
        {id === '107' && <Act107 />}
        {id === '108' && <Act108 />}
    </div>)
}

export default Action;