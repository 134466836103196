import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                <li className="breadcrumb-item active" aria-current="page"><Link to="/certificateBase" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами</Link></li>
            </ol>
        </nav>

        <div className='white-card'>

            <div className='card-body'>
                <p>Органами социальной защиты населения удостоверение инвалида войны выдается лицам, пенсионное обеспечение которых осуществляется территориальными органами Пенсионного фонда Российской Федерации.</p>
                <a target="_blank" href="http://minsoc74.ru/vydacha-udostovereniya-invalida-velikoy-otechestvennoy-voyny-i-udostovereniya-invalida-o-prave-na-0">Подробнее</a>
                <p>Гражданам, получающим пенсии в иных органах, удостоверение инвалида войны выдается органами, осуществляющими их пенсионное обеспечение</p>
                <a>Ссылка на подпункт 2.15 пункта 2 Инструкции о порядке и условиях реализации прав и льгот ветеранов Великой Отечественной войны, ветеранов боевых действий, иных категорий граждан, установленных Федеральным законом «О ветеранах», утвержденной постановлением Минтруда России от 11.10.2000 г. № 69</a>
                <p className="mt-3"><Link to="/action/4" style={{ cursor: 'pointer' }}>Общая информация о выдаче удостоверения инвалида войны</Link></p>
            </div>

        </div>

    </Container >)
}

export default Act1;