import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import { Link } from 'react-router-dom';

const Act33 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/siroty"> Меры социальной поддержки детей-сирот и детей, оставшихся без попечения родителей</Link></li>
            </ol>
        </nav>
        <div className="name">Право на получение в областных государственных профессиональных образовательных организациях второго среднего профессионального образования по программе подготовки квалифицированных рабочих, служащих по очной форме обучения за счет средств областного бюджета</div>

        <div className='white-card'>

            <div className='card-body'>
                <p>Дети-сироты и дети, оставшиеся без попечения родителей, лица из числа детей-сирот и детей, оставшихся без попечения родителей, имеют право на однократное прохождение обучения по программам профессиональной подготовки по профессиям рабочих, должностям служащих по очной форме обучения за счет средств бюджетов субъектов Российской Федерации. За детьми-сиротами и детьми, оставшимися без попечения родителей, лицами из числа детей-сирот и детей, оставшихся без попечения родителей, прошедшими профессиональное обучение в рамках освоения образовательных программ среднего общего образования, образовательных программ среднего профессионального образования, сохраняется право на однократное прохождение обучения по программам профессиональной подготовки по профессиям рабочих, должностям служащих по очной форме обучения за счет средств бюджетов субъектов Российской Федерации</p>
            </div>

        </div>

    </Container >)
}

export default Act33;