import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt, faLink } from '@fortawesome/free-solid-svg-icons'
import {
    Link
} from 'react-router-dom';

function Home(props) {

    const redirect = () => props.history.push('/certificat');
    const redirectToSocialServices = () => props.history.push('/socialservices');
    const redirectToSiroty = () => props.history.push('/siroty');
    const redirectToChildren = () => props.history.push('/children');
    const redirectToMeasure = () => props.history.push('/measure');
    const redirectToHealth = () => props.history.push('/health');
    return (
        <Container>
            <Row>
                <Col md='12' lg='12' className='mb-3'>
                    <div className='head-list mt-30'>
                        <div className='title-head-list'>Список категорий</div>
                    </div>
                    <div className='white-box last pt-0'>
                        <div className="element_group">
                            <div className="group_string">
                                {/*<div className="custom-control p-0 attribute_30 w-100">*/}
                                {/*    <label className="label-group"></label>*/}
                                {/*</div>*/}

                                <div className="element_string">
                                    <div className="custom-control custom-radio list_item_block">
                                        <input type="radio" id="attribute_value01" name="attribute_0" className="custom-control-input" onClick={redirectToSocialServices} />
                                        <label className="custom-control-label label-normal label-group" id="attribute_value_label_01" htmlFor="attribute_value01">Социальное обслуживание граждан</label>
                                    </div>
                                    <div className="custom-control custom-radio list_item_block">
                                        <input data-cof="1" value='true' type="radio" id="attribute_value59" name="attribute_30" className="custom-control-input" onClick={redirect} />
                                        <label className="custom-control-label label-normal label-group" id="attribute_value_label_59" htmlFor="attribute_value59">Получение удостоверений</label>
                                    </div>
                                    <div className="custom-control custom-radio list_item_block">
                                        <input value="6" type="radio" id="attribute_value53" name="attribute_30" className="custom-control-input" onClick={redirectToMeasure} />
                                        <label className="custom-control-label label-normal label-group" id="attribute_value_label_52" htmlFor="attribute_value53">Меры социальной поддержки отдельным категориям граждан</label>
                                    </div>

                                    <div className="custom-control custom-radio list_item_block">
                                        <input value="6" type="radio" id="attribute_value51" name="attribute_30" className="custom-control-input" onClick={redirectToChildren} />
                                        <label className="custom-control-label label-normal label-group" id="attribute_value_label_51" htmlFor="attribute_value51">Социальная поддержка семей с детьми</label>
                                    </div>

                                    <div className="custom-control custom-radio list_item_block">
                                        <input value="6" type="radio" id="attribute_value50" name="attribute_30" className="custom-control-input" onClick={redirectToSiroty} />
                                        <label className="custom-control-label label-normal label-group" id="attribute_value_label_50" htmlFor="attribute_value50">Меры социальной поддержки детей-сирот и детей, оставшихся без попечения родителей</label>
                                    </div>
                                    <div className="custom-control custom-radio list_item_block">
                                        <input type="radio" id="attribute_value52" name="attribute_0" className="custom-control-input" onClick={redirectToHealth}/>
                                        <label className="custom-control-label label-normal label-group" id="attribute_value_label_52" htmlFor="attribute_value52">Отдых и оздоровление детей</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Home;
