import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                <li className="breadcrumb-item" aria-current="page"><Link to="/certificateBase" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами</Link></li>
            </ol>
        </nav>

        <div className='white-card'>

            <div className='card-body'>
                <a target="_blank" href="http://minsoc74.ru/sites/default/files/1_zayavlenie_vt.doc">Шаблон заявления</a>
                <br />

                <div className="small-title">Разъяснения по заполнению заявления</div>
                <p>В шапке заявления указываются: фамилия, имя, отчество заявителя, дата рождения; адрес регистрации по месту жительства (указываются индекс, населенный пункт, улица, номер дома, номер квартиры (при наличии)); серия и номер, дата выдачи и наименование органа, выдавшего паспорт гражданина Российской Федерации; реквизиты пенсионного удостоверения (при наличии), номер телефона для контактов.</p>
                <p>В тексте заявления:
                гражданами, претендующими на присвоение звания «Ветеран труда» на основании наград, указываются наименование наградного документа (например, удостоверение к медали «За отвагу», удостоверение к знаку «За трудовую доблесть», почетная грамота и так далее), реквизиты наградного документа и наименование органа (организации), принявшего решение о награждении либо выдавшего наградной документ;
</p>
                <p>гражданами, начавшими трудовую деятельность в несовершеннолетнем возрасте в период Великой Отечественной войны, указываются наименование документа, подтверждающего данный факт (трудовая книжка, справка архивного учреждения и другие), реквизиты документа и наименование органа (организации), выдавшего документ;</p>
                <p>далее указывается продолжительность трудового (страхового) стажа либо выслуги лет в календарном исчислении;</p>
                <p>далее указываются наименования документов, прилагаемых к заявлению, то есть документов, указанных в заявлении;</p>
                <p>далее указывается дата подачи заявления.</p>
                <p>*Справки о страховом стаже в отношении граждан, являющихся получателями пенсии, могут быть запрошены органами социальной защиты населения в рамках межведомственного информационного взаимодействия в уполномоченных органах, осуществляющих пенсионное обеспечение граждан.</p>
                <p>Граждане вправе самостоятельно по собственной инициативе представить справки о страховом стаже.</p>

                <br />
                <div className="small-title">Способ подачи заявления о присвоении звания «Ветеран труда»</div>
                <ul className="text">
                    <li><a target="_blank" href="https://gosuslugi74.ru/">Через Портал государственных и муниципальных услуг Челябинской области</a></li>
                    <li>при обращении в органы социальной защиты населения по месту жительства <a target="_blank" href="http://minsoc74.ru/municipalnye-upravleniya-i-uchrezhdeniya">Ссылка</a></li>
                    <li>при обращении в многофункциональные центры предоставления государственных и муниципальных услуг <a target="_blank" href="https://mfc-74.ru">Ссылка</a></li>
                    <li>посредством почтового отправления с описью вложения и уведомлением о вручении</li>
                </ul>

                <div className="small-title">Способ получения результата рассмотрения заявления о присвоении звания «Ветеран труда»</div>
                <ul className="text">
                    <li>при вынесении решения о присвоении звания «Ветеран труда» удостоверение ветерана труда может быть получено в органах социальной защиты населения по месту жительства
                    <a>Ссылка ни приложение 1 к Административному регламенту </a></li>
                    <li>решение об отказе в присвоении звания «Ветеран труда» направляется заявителю на домашний адрес почтовым отправлением
                        </li>

                </ul>

                <div className="small-title"><a target="_blank" href="http://minsoc74.ru/prisvoenie-zvaniya-veteran-truda-i-vydacha-udostovereniy-veteran-truda-0">Информация об услуге</a></div>

            </div>

        </div>

    </Container >)
}

export default Act1;