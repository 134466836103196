import React, { useState } from 'react';
import { Container, Form, FormGroup, Label, Input, Col, Row, Table, Button } from 'reactstrap';
import './Action.css'

const Act23 = () => {
    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><a href="/">Главная</a></li>
                <li className="breadcrumb-item"><a href="/socialservices">Меры социальной поддержки детей-сирот и детей, оставшихся без попечения родителей</a></li>
            </ol>
        </nav>
        <div className="name">Единовременная выплата денежных средств на приобретение мебели, ежемесячная выплата на приобретение предметов хозяйственного обихода, личной гигиены, игр, игрушек и книг </div>

        <div className='white-card'>

            <div className='card-body'>
                Единовременная выплата денежных средств на приобретение мебели, ежемесячная выплата на приобретение предметов хозяйственного обихода, личной гигиены, игр, игрушек и книг (на ребенка, передаваемого на воспитание в приемную семью на один год и более, приемной семье единовременно выплачиваются денежные средства на приобретение мебели в размере 16 297,16 рубля (на одного ребенка). Размер единовременной выплаты на приобретение мебели подлежит индексации в соответствии с коэффициентом, устанавливаемым законом Челябинской области об областном бюджете на очередной финансовый год и плановый период. 
            </div>

        </div>

    </Container>)
}

export default Act23;