import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import { Link } from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/children"> Социальная поддержка семей с детьми</Link></li>
            </ol>
        </nav>
        <div className="name">Выплата на содержание детей-сирот и детей, оставшихся без попечения родителей в семье опекуна (попечителя), приемной семье </div>

        <div className='white-card'>

            <div className='card-body'>
                <text>Правом обладают опекуны (попечители), приемные родители, проживающие на территории Челябинской области на детей-сирот и детей, оставшихся без попечения родителей, переданных под опеку (попечительство) и на воспитание в приемные семь.
                При наличии в составе многодетной семьи лиц, имеющих право на получение дополнительных мер социальной поддержки по оплате жилого помещения и коммунальных услуг по иным основаниям, указанные меры предоставляются по выбору семьи по одному из оснований.
                </text>
                <div className="small-title">Куда обращаться за назначением</div>
                <ul className="text">
                    <li>В орган социальной защиты населения по месту жительства или через многофункциональный центр</li>
                </ul>

                <div className="small-title">Нормативно правовые акты</div>
                <ul className="text">
                    <li><a target="_blank" href="http://docs.cntd.ru/document/819023006 ">Закон Челябинской области от 25 октября 2007 г. N 212-ЗО "О мерах социальной поддержки детей-сирот и детей, оставшихся без попечения родителей, вознаграждении, причитающемся приемному родителю, и социальных гарантиях приемной семье"</a></li>
                    <li><a target="_blank" href="https://docs.cntd.ru/document/424079167 ">Постановление Правительства Челябинской области от 20.06.2012г. № 334-П "Об административных регламентах предоставления государственных услуг в сфере социальной поддержки детей-сирот и детей, оставшихся без попечения родителей, а также лиц из числа детей-сирот и детей, оставшихся без попечения родителей" </a></li>
                    <li> <a target="_blank" href="http://www.kremlin.ru/acts/bank/27263 ">Федеральный закон от 24 апреля 2008 года № 48-ФЗ «Об опеке и попечительстве»</a> </li>

                </ul>
                <div className="small-title">Основание для отказа</div>
                <ul className="text">
                    <li>смерть подопечного ребенка, признание его безвестно отсутствующим, нахождение его в розыске;   </li>
                    <li>отсутствие факта обучения в образовательных организациях по очной форме;   </li>
                    <li>наличие противоречий в документах, представляемых заявителем;   </li>
                    <li>объявление несовершеннолетнего полностью дееспособным (эмансипированным) в соответствии с законодательством Российской Федерации;   </li>
                    <li>истечение срока, установленного для предоставления государственной услуги;   </li>
                    <li>достижение несовершеннолетним, переданным под опеку (попечительство), возраста 18 лет, а также достижение лицами, находившимися под опекой (попечительством), возраста 20 лет;   </li>
                    <li>устройство ребенка, переданного под опеку (попечительство), на полное государственное обеспечение, а также поступление лица, находившегося под опекой (попечительством), на обучение и устройство на полное государственное обеспечение в областные государственные профессиональные образовательные организации и областные государственные образовательные организации высшего образования;   </li>
                    <li>помещение несовершеннолетнего, а также лица, находившегося под опекой (попечительством), в учреждение, исполняющее наказание в виде лишения свободы;   </li>
                    <li>переезд опекуна (попечителя), а также лица, находившегося под опекой (попечительством), на постоянное место жительства за пределы Челябинской области;   </li>
                    <li>вступление несовершеннолетнего, переданного под опеку (попечительство), в брак;   </li>
                    <li>объявление несовершеннолетнего полностью дееспособным (эмансипированным) в соответствии с законодательством Российской Федерации;   </li>
                    <li>усыновление (удочерения) ребенка, находящегося под опекой (попечительством)</li>

                </ul>

                <div className="small-title">Подробные сведения размещены на Портале </div>
                <ul className="text">
                    <li> <a target="_blank" href="https://www.gosuslugi.ru/52723/2/info  ">для опекунов </a></li>
                    <li> <a target="_blank" href="https://www.gosuslugi.ru/90655/5/info ">для приемных родителей </a></li>
                </ul>
                <p>Подать заявление можно <a target="_blank" href="https://www.gosuslugi.ru/332575/2 ">здесь</a></p>
            </div>

        </div>

    </Container >)
}

export default Act1;