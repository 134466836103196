import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'

const Act6 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><a href="/">Главная</a></li>
                <li className="breadcrumb-item"><a href="/certificat">Получение удостоверений </a></li>
                <li className="breadcrumb-item active" aria-current="page">Лица, пострадавшие от политических репрессий</li>
            </ol>
        </nav>
        <div className="name">Лица, пострадавшие от политических репрессий</div>

        <div className='white-card'>

            <div className='card-body'>
                <div className="small-title">круг заявителей</div>
                <ul className="text">
                    <li>ветераны Великой Отечественной войны из числа лиц, проработавших в тылу в период с 22 июня 1941 года по 9 мая 1945 года не менее шести месяцев, исключая период работы на временно оккупированных территориях СССР, либо награжденных орденами или медалями СССР за самоотверженный труд в период Великой Отечественной войны;</li>
                    <li>ветераны труда и ветераны военной службы;</li>
                    <li>реабилитированные лица и лица, пострадавшие от политических репрессий;</li>
                    <li>ветераны труда Челябинской области.</li>
                </ul>
                <div className="small-title">срок предоставления государственной услуги</div>
                <ul className="text">
                    <li>Срок предоставления государственной услуги не должен превышать 62 календарных дней со дня обращения заявителя за предоставлением государственной услуги.</li>
                </ul>
                <div className="small-title">исчерпывающий перечень документов</div>
                <ul className="text">
                    <li>заявление о назначении ежемесячной денежной выплаты с указанием способа ее получения (через кредитную организацию путем зачисления сумм компенсации на счет заявителя, открытый им в кредитном учреждении, или через отделение федеральной почтовой связи, иные организации, осуществляющие доставку пенсии).
                    Ветеранами труда, ветеранами военной службы, ветеранами труда Челябинской области в заявлении указываются сведения о наличии либо отсутствии услуг местной телефонной связи.
                    При выборе заявителями способа получения ежемесячной денежной выплаты со счета в кредитной организации в заявлении указывается номер счета;
</li>
                    <li>документ, удостоверяющий личность;</li>
                    <li>документ, подтверждающий право на ежемесячную денежную выплату;</li>
                    <li>справка органа социальной защиты по месту жительства (пребывания) заявителя о неполучении им ежемесячной денежной выплаты по месту жительства (пребывания) (в случае если заявитель желает получать ежемесячную денежную выплату по месту пребывания (жительства);</li>
                    <li>справка органа социальной защиты населения по прежнему месту жительства (пребывания) заявителя о неполучении заявителем ежемесячной денежной выплаты по прежнему месту жительства (пребывания) (в случае изменения заявителем места жительства (пребывания);</li>
                    <li>пенсионное удостоверение либо справка (сведения) об установлении (назначении) пенсии в соответствии с Федеральным законом от 28 декабря 2013 года № 400-ФЗ «О страховых пенсиях», выданная территориальными органами Пенсионного фонда Российской Федерации (для ветеранов труда и ветеранов военной службы, за исключением граждан, достигших возраста 55 и 60 лет (соответственно женщины и мужчины).;</li>
                    <li>документ, подтверждающий регистрацию по месту жительства или по месту пребывания заявителя;</li>
                    <li>договор об оказании услуг местной телефонной связи (в случае если ветеранами труда, ветеранами военной службы, ветеранами труда Челябинской области указаны в заявлении сведения о наличии услуг местной телефонной связи);</li>
                    <li>документ, подтверждающий полномочия представителя заявителя (в случае если от имени заявителя выступает его представитель);</li>
                    <li>справка территориального органа Пенсионного фонда Российской Федерации о неполучении заявителем ежемесячной денежной выплаты, установленной в соответствии с нормативными правовыми актами Российской Федерации.</li>
                </ul>
                <div className="small-title">результат предоставления государственной услуги</div>
                <ul className="text">
                    <li>Результатом предоставления государственной услуги является предоставление ежемесячной денежной выплаты.</li>
                </ul>
                <div className="small-title">Основания для отказа в приеме документов </div>
                <ul className="text">
                    <li>Основания для отказа в приёме документов отсутствуют.</li>
                </ul>
                <div className="small-title">основания для отказа в предоставлении государственной услуги</div>
                <ul className="text">
                    <li>представление заявителем неполного пакета документов, обязанность по представлению которых возложена на заявителя;</li>
                    <li>оформление документов с нарушением требований, установленных Административным регламентом;</li>
                    <li>наличие противоречий в документах, представленных заявителем;</li>
                    <li>представление органом социальной защиты по месту жительства (пребывания), прежнему месту жительства (пребывания) заявителя справки о получении заявителем ежемесячной денежной выплаты по месту жительства (пребывания), прежнему месту жительства (пребывания);</li>
                    <li>представление территориальным органом Пенсионного фонда Российской Федерации сведений о получении заявителем ежемесячной денежной выплаты, установленной в соответствии с нормативными правовыми актами Российской Федерации.</li>
                </ul>
                <div className="small-title">право на досудебное (внесудебное) обжалование решений и действий (бездействия) должностных лиц </div>
                <ul className="text">
                    <li>В досудебном (внесудебном) порядке заявители могут обжаловать действия (бездействие) органов социальной защиты, Министерства социальных отношений Челябинской области, многофункционального центра, а также их должностных лиц, государственных гражданских служащих, муниципальных служащих, работников и принимаемые ими решения при предоставлении государственной услуги.</li>
                </ul>
                <div className="small-title">Форма заявления</div>
                <ul className="text">
                    <li>Заявление 6</li>
                </ul>
            </div>

        </div>

    </Container >)
}

export default Act6;