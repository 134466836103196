import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item active"><Link to="/measure" style={{ cursor: 'pointer' }}>Меры социальной поддержки отдельным категориям граждан</Link></li>
            </ol>

        </nav>
        <div className="name">Ежегодная денежная выплата гражданам, награжденным нагрудным знаком «Почетный донор России»</div>

        <div className='white-card'>

            <div className='card-body'>
                <div className="small-title">круг заявителей</div>
                <ul className="text">
                    <li>Граждане, награжденные нагрудным знаком «Почетный донор России», а также граждане, награжденные нагрудным знаком «Почетный донор СССР»</li>
                </ul>
                <div className="small-title">срок предоставления государственной услуги</div>
                <ul className="text">
                    <li>Выплата осуществляется один раз в год ежегодно, не позднее 1 апреля текущего года</li>
                </ul>

                <div className="small-title">исчерпывающий перечень документов</div>
                <ul className="text">
                    <li>Заявление с указанием сведений о реквизитах счета, открытого лицом, имеющим право на ежегодную денежную выплату (наименование организации, в которую должна быть перечислена ежегодная денежная выплата, банковский идентификационный код (БИК), идентификационный номер налогоплательщика (ИНН), присвоенные при постановке на учет в налоговом органе по месту нахождения организации, номер счета лица, имеющего право на ежегодную денежную выплату;</li>
                    <li>документ, удостоверяющий личность;</li>
                    <li>удостоверение о награждении нагрудным знаком «Почетный донор России» или удостоверение о награждении нагрудным знаком «Почетный донор СССР» утвержденных образцов. </li>
                </ul>

                <div className="small-title">результат предоставления государственной услуги</div>
                <ul className="text">
                    <li>Результатом предоставления государственной услуги является выплата.</li>

                </ul>
                <div className="small-title">Основания для отказа в приеме документов </div>
                <ul className="text">
                    <li>Основания для отказа в приёме документов отсутствуют.</li>
                </ul>

                <div className="small-title"><a target="_blank" href="http://minsoc74.ru/sites/default/files/16.docx">Форма заявления</a></div>

            </div>

        </div>

    </Container >)
}

export default Act1;