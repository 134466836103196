import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap';

export class CertificateStart extends Component {
    render() {
        return (
            <Container>
                <Row>
                    <Col md='12' lg='12'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb pl-0">
                                <li className="breadcrumb-item"><Link to="./"> Главная </Link> </li>
                                <li className="breadcrumb-item active" aria-current="page"> <Link to="/certificat" style={{ cursor: 'pointer' }}>Получение удостоверений</Link></li>
                            </ol>
                        </nav>
                    </Col>
                </Row>
                <Row>
                    <Col md='12' lg='12' className='mb-3'>
                        <div className='head-list-child mt-30'>
                            <div className='title-head-list title-head-list-child'>Получение удостоверений</div>
                        </div>
                        <div className='white-box-child last pt-0'>
                            <div className="element_group">
                                <div className="group_string">
                                    <div className="element_string attribute_0 ">
                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/certificateBase">
                                                <input type="radio" id="attribute_value0" name="attribute_0" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_59" htmlFor="attribute_value0">Получение удостоверения гражданами</label>
                                            </Link>
                                        </div>
                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/certificateRadiation">
                                                <input value='state:RadFirst' type="radio" id="attribute_value1" name="attribute_0" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_1" htmlFor="attribute_value1">Получение удостоверения гражданами, пострадавшими от воздействия радиации</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/certificateFamily">
                                                <input type="radio" id="attribute_value2" name="attribute_0" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_2" htmlFor="attribute_value2"> Получение удостоверения многодетной семьи Челябинской области</label>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}
