import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'

const Act13 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><a href="/">Главная</a></li>
                <li className="breadcrumb-item"><a href="/certificat">Получение удостоверений </a></li>
                <li className="breadcrumb-item active" aria-current="page">«Компенсационные выплаты за пользование услугами 
        местной телефонной связи и (или) за пользование услугами связи для целей проводного радиовещания»</li>
            </ol>
        </nav>
        <div className="name">«Компенсационные выплаты за пользование услугами 
        местной телефонной связи и (или) за пользование услугами связи для целей проводного радиовещания»
</div>

        <div className='white-card'>

            <div className='card-body'>
                <div className="small-title">круг заявителей</div>
                <ul className="text">
                    <li>инвалиды Великой Отечественной войны I и II групп;</li>
                    <li>инвалиды боевых действий I и II групп;</li>
                    <li>военнослужащие, ставшие инвалидами I и II групп вследствие ранения, контузии, увечья, полученных при исполнении обязанностей военной службы (служебных обязанностей);</li>
                    <li>лица, награждённые знаком «Жителю блокадного Ленинграда» и не являющиеся инвалидами.</li>
                </ul>
                <div className="small-title">срок предоставления государственной услуги</div>
                <ul className="text">
                    <li>Срок предоставления государственной услуги составляет 36 рабочих дней.</li>
                </ul>
                <div className="small-title">исчерпывающий перечень документов</div>
                <ul className="text">
                    <li>заявление о предоставлении компенсационных выплат с указанием способа их получения (через кредитную организацию или через отделение федеральной почтовой связи, иные организации, осуществляющие доставку пенсии);</li>
                    <li>документ, удостоверяющий личность;</li>
                    <li>документ, удостоверяющий право на меры социальной поддержки;</li>
                    <li>пенсионное удостоверение;</li>
                    <li>договор об оказании услуг местной телефонной связи (при наличии);</li>
                    <li>договор об оказании услуг связи для целей проводного радиовещания (при наличии);</li>
                    <li>платежный документ (квитанция на оплату), подтверждающий факт оплаты услуг местной телефонной связи и (или) услуг связи для целей проводного радиовещания в месяце, предшествующем месяцу подачи заявления со всеми необходимыми документами (в случае если у заявителя отсутствуют документы, указанные в пунктах 5 и 6);</li>
                    <li>документ, подтверждающий полномочия представителя заявителя (в случае если от заявителя выступает его представитель).</li>
                </ul>
                <div className="small-title">результат предоставления государственной услуги</div>
                <ul className="text">
                    <li>Результатом предоставления государственной услуги является выплата заявителю компенсационных выплат.</li>
                </ul>
                <div className="small-title">Основания для отказа в приеме документов </div>
                <ul className="text">
                    <li>Основания для отказа в приёме документов отсутствуют</li>
                </ul>
                <div className="small-title">основания для отказа в предоставлении государственной услуги</div>
                <ul className="text">
                    <li>представление заявителем неполного пакета документов, обязанность по представлению которых возложена на заявителя;</li>
                    <li>наличие противоречий в документах, представленных заявителем;</li>
                    <li>представление органом социальной защиты по месту жительства (пребывания) заявителя справки, подтверждающей, что компенсационные выплаты предоставляются по месту жительства (пребывания) заявителя;</li>
                    <li>представление органом социальной защиты по прежнему месту жительства (пребывания) заявителя справки, подтверждающей, что компенсационные выплаты предоставляются по прежнему месту жительства (пребывания) заявителя.</li>
                </ul>
                <div className="small-title">право на досудебное (внесудебное) обжалование решений и действий (бездействия) должностных лиц </div>
                <ul className="text">
                    <li>В досудебном (внесудебном) порядке заявители могут обжаловать действия (бездействие) органов социальной защиты, Министерства социальных отношений Челябинской области, многофункционального центра, а также их должностных лиц, государственных гражданских служащих, муниципальных служащих, работников и принимаемые ими решения при предоставлении государственной услуги.</li>
                </ul>
                <div className="small-title"><a target="_blank" href="http://minsoc74.ru/sites/default/files/13.docx">Форма заявления</a></div>
            </div>

        </div>

    </Container >)
}

export default Act13;