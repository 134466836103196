import React, { useState } from 'react';
import { Container, Form, FormGroup, Label, Input, Col, Row, Table, Button } from 'reactstrap';
import './Action.css'

const Act23 = () => {
    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><a href="/">Главная</a></li>
                <li className="breadcrumb-item"><a href="/socialservices">Меры социальной поддержки детей-сирот и детей, оставшихся без попечения родителей</a></li>
            </ol>
        </nav>
        <div className="name">Однократное предоставление благоустроенных жилых помещений специализированного жилищного фонда по договорам найма специализированных жилых помещений</div>

        <div className='white-card'>

            <div className='card-body'>
                Однократное предоставление благоустроенных жилых помещений специализированного жилищного фонда по договорам найма специализированных жилых помещений (детям-сиротам и детям, оставшимся без попечения родителей, а также лицам из их числа, которые не являются нанимателями жилых помещений по договорам социального найма или членами семьи нанимателя жилого помещения по договору социального найма либо собственниками жилых помещений, детям-сиротам и детям, оставшимся без попечения родителей, а также лицам из их числа, которые являются нанимателями жилых помещений по договорам социального найма или членами семьи нанимателя жилого помещения по договору социального найма либо собственниками жилых помещений, в случае, если их проживание в ранее занимаемых жилых помещениях признается невозможным, включенным в список подлежащих обеспечению жилыми помещениями детей-сирот и детей, оставшихся без попечения родителей, а также лиц из их числа). 
            </div>

        </div>

    </Container>)
}

export default Act23;