import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'

const Act11 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><a href="/">Главная</a></li>
                <li className="breadcrumb-item"><a href="/certificat">Получение удостоверений </a></li>
                <li className="breadcrumb-item active" aria-current="page">«Компенсация расходов на оплату жилых помещений и коммунальных услуг отдельным категориям граждан»</li>
            </ol>
        </nav>
        <div className="name">«Компенсация расходов на оплату жилых помещений и коммунальных услуг отдельным категориям граждан»</div>

        <div className='white-card'>

            <div className='card-body'>
                <div className="small-title">круг заявителей</div>
                <ul className="text">
                    <li>инвалиды Великой Отечественной войны, инвалиды боевых действий, а также военнослужащие и лица рядового и начальствующего состава органов внутренних дел, Государственной противопожарной службы, учреждений и органов уголовно-исполнительной системы, ставшие инвалидами вследствие ранения, контузии или увечья, полученных при исполнении обязанностей военной службы (служебных обязанностей);</li>
                    <li>участники Великой Отечественной войны, указанные в подпунктах «а» - «ж» и «и» подпункта 1 пункта 1 статьи 2 Федерального закона от 12 января 1995 года № 5-ФЗ «О ветеранах»;</li>
                    <li>участники Великой Отечественной войны, указанные в подпункте «з» подпункта 1 пункта 1 статьи 2 Федерального закона от 12 января 1995 года № 5-ФЗ «О ветеранах», ставшие инвалидами вследствие общего заболевания, трудового увечья или других причин (кроме лиц, инвалидность которых наступила вследствие их противоправных действий);</li>
                    <li>лица, награжденные знаком «Жителю блокадного Ленинграда», ставшие инвалидами вследствие общего заболевания, трудового увечья и других причин (кроме лиц, инвалидность которых наступила вследствие их противоправных действий);</li>
                    <li>члены семей погибших (умерших) инвалидов войны, участников Великой Отечественной войны и ветеранов боевых действий, а также члены семей военнослужащих, лиц рядового и начальствующего состава органов внутренних дел, Государственной противопожарной службы, учреждений и органов уголовно-исполнительной системы и органов государственной безопасности, погибших при исполнении обязанностей военной службы (служебных обязанностей);</li>
                    <li>ветераны боевых действий из числа лиц, указанных в подпунктах 1 - 4 пункта 1 статьи 3 Федерального закона от 12 января 1995 года № 5-ФЗ «О ветеранах»;</li>
                    <li>инвалиды (вне зависимости от наличия ограничения способности к трудовой деятельности);</li>
                    <li>семьи, имеющие детей-инвалидов;</li>
                    <li>ветераны подразделений особого риска и граждане, имеющие право на меры социальной поддержки по оплате жилого помещения и коммунальных услуг в соответствии с законодательством Российской Федерации, устанавливающим меры социальной поддержки ветеранам подразделений особого риска и отдельным категориям граждан, подвергшихся радиационному воздействию вследствие катастрофы на Чернобыльской АЭС, аварии на производственном объединении «Маяк» и ядерных испытаний на Семипалатинском полигоне;</li>
                    <li>лица, награжденные знаком «Жителю блокадного Ленинграда» и не являющиеся инвалидами;</li>
                    <li>военнослужащие, в том числе уволенные в запас (отставку), проходившие военную службу в воинских частях, учреждениях, военно-учебных заведениях, не входивших в состав действующей армии, в период с 22 июня 1941 года по 3 сентября 1945 года не менее шести месяцев; военнослужащие, награжденные орденами или медалями СССР за службу в указанный период.</li>
                    <li>лица, принимавшие участие в военно-стратегической операции «Анадырь» на территории Республики Куба в период Карибского кризиса с 1 июля 1962 года по 30 ноября 1963 года.</li>
                </ul>
                <div className="small-title">срок предоставления государственной услуги</div>
                <ul className="text">
                    <li>Срок предоставления государственной услуги не должен превышать 58 календарных дней со дня обращения.</li>
                </ul>
                <div className="small-title">исчерпывающий перечень документов</div>
                <ul className="text">
                    <li>заявление о назначении компенсации расходов с указанием способа получения компенсации расходов. При выборе заявителями способа получения компенсации расходов со счета в кредитной организации в заявлении указывается номер счета. Заявителями, указанными в подпунктах 7, 8 пункта 5 Административного регламента, в заявлении указываются сведения о принадлежности жилого помещения к государственному, муниципальному или частному жилищному фонду;</li>
                    <li>документ, удостоверяющий личность;</li>
                    <li>документ, подтверждающий право заявителя на меры социальной поддержки по оплате жилого помещения и коммунальных услуг;</li>
                    <li>сведения о размере фактических начислений на оплату жилых помещений и коммунальных услуг;</li>
                    <li>при отсутствии сведений о размере фактических начислений на оплату жилых помещений и коммунальных услуг - платежные документы, подтверждающие размер платежей за жилые помещения и коммунальные услуги, в том числе расходы на приобретение твердого топлива с учетом его доставки (для заявителей, имеющих право на меру социальной поддержки по оплате доставки твердого топлива);</li>
                    <li>справки о регистрации заявителя и членов его семьи по месту жительства (пребывания) в жилом помещении, расходы по оплате которого подлежат компенсации;</li>
                    <li>документы (сведения), подтверждающие отнесение лиц, проживающих совместно с заявителем, к членам его семьи;</li>
                    <li>документы (сведения), содержащие информацию о форме собственности, площади жилого помещения, расходы по оплате которого подлежат компенсации (для лиц, указанных в подпунктах 7, 8, 9 пункта 5 Административного регламента, а также для лиц, обращающихся в целях получения компенсации расходов на приобретение твердого топлива);</li>
                    <li>справка о неполучении заявителем компенсации расходов по месту жительства (пребывания), по прежнему месту жительства (пребывания);</li>
                    <li>документ, подтверждающий полномочия представителя заявителя (в случае если от имени заявителя выступает его представитель);</li>
                    <li>сведения об инвалидности, содержащиеся в федеральной государственной информационной системе «Федеральный реестр инвалидов», а в случае отсутствия указанных сведений в федеральной государственной информационной системе «Федеральный реестр инвалидов» - справка об установлении инвалидности (выписка из акта освидетельствования гражданина, признанного инвалидом), выдаваемая федеральным государственным учреждением медико-социальной экспертизы (для лиц, указанных в подпунктах 4, 7, 8 пункта 5 Административного регламента);</li>
                    <li>справка архивного учреждения Министерства обороны Российской Федерации, подтверждающая прохождение военной службы и участие в военно-стратегической операции «Анадырь» на территории Республики Куба в период Карибского кризиса с 1 июля 1962 года по 30 ноября 1963 года (для лиц, указанных в подпункте 14 пункта 5 Административного регламента).</li>

                </ul>
                <div className="small-title">результат предоставления государственной услуги</div>
                <ul className="text">
                    <li>Результатом предоставления государственной услуги является компенсация расходов</li>
                </ul>
                <div className="small-title">Основания для отказа в приеме документов </div>
                <ul className="text">
                    <li>Основания для отказа в приёме документов отсутствуют.</li>
                </ul>
                <div className="small-title">основания для отказа в предоставлении государственной услуги</div>
                <ul className="text">
                    <li>представление заявителем или его законным представителем неполного пакета документов, обязанность по представлению которых возложена на заявителя;</li>
                    <li>оформление документов с нарушением установленных требований;</li>
                    <li>наличие противоречий в документах, представленных заявителем или его законным представителем;</li>
                    <li>представление органом социальной защиты по месту жительства (пребывания), прежнему месту жительства (пребывания) заявителя справки, подтверждающей, что заявителю производится компенсация расходов по месту пребывания (жительства), прежнему месту жительства (пребывания);</li>
                    <li>истечение установленного срока инвалидности и получение органами социальной защиты информации об отсутствии документов (сведений), подтверждающих установление инвалидности на очередной срок;</li>
                    <li>необращение заявителя в орган социальной защиты в течение шести месяцев с первого числа месяца, следующего за месяцем приостановления компенсации расходов.</li>
                </ul>
                <div className="small-title">право на досудебное (внесудебное) обжалование решений и действий (бездействия) должностных лиц </div>
                <ul className="text">
                    <li>В досудебном (внесудебном) порядке заявители могут обжаловать действия (бездействие) органов социальной защиты, Министерства социальных отношений Челябинской области, многофункционального центра, а также их должностных лиц, государственных гражданских служащих, муниципальных служащих, работников и принимаемые ими решения при предоставлении государственной услуги.</li>
                </ul>
                <div className="small-title"><a target="_blank" href="http://minsoc74.ru/sites/default/files/11.docx">Форма заявления</a></div>
            </div>

        </div>

    </Container >)
}

export default Act11;