import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import { Link } from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/children"> Социальная поддержка семей с детьми</Link></li>
            </ol>
        </nav>
        <div className="name">Назначение и выплата ежемесячной денежной выплаты, назначаемой в случае рождения третьего ребенка и (или) последующих детей до достижения ребенком возраста трех лет </div>

        <div className='white-card'>

            <div className='card-body'>
                <text>Право имеет один из родителей, усыновителей, опекун</text>
                <div className="small-title">Условия предоставления выплаты</div>
                <ul className="text">
                    <li>в семьях со среднедушевым доходом, размер которого не превышает величину прожиточного минимума на душу населения в Челябинской области</li>
                    <li>в семьях со среднедушевым доходом, размер которого не превышает двукратную величину прожиточного минимума для трудоспособного населения  в Челябинской области во втором квартале года, предшествующего году подачи заявления (при подаче заявления на детей, рожденных начиная с 1 января 2020 года)</li>
                    <li>
                        <div>

                            <ul className="point">
                                в случае если родители в браке отец ребенка должен быть официально трудоустроен, либо иметь статус безработного, либо принадлежать к категориям лиц с отсутствием или ограничением возможности трудоустройства, к которым относятся:
                                             <li style={{ marginLeft: '2em' }}>лица, содержащиеся под стражей на период предварительного следствия и судебного разбирательства;</li>
                                <li style={{ marginLeft: '2em' }}>лица, находящиеся на длительном стационарном лечении (на период такого лечения);</li>
                                <li style={{ marginLeft: '2em' }}>лица, находящиеся в розыске до признания их в установленном порядке безвестно отсутствующими или объявления умершими;</li>
                                <li style={{ marginLeft: '2em' }}>неработающие трудоспособные лица, осуществляющие уход за нетрудоспособными гражданами, детьми-инвалидами и получающие компенсационные выплаты по уходу за указанными лицами;</li>
                                <li style={{ marginLeft: '2em' }}>граждане, осужденные к аресту или лишению свободы;</li>
                                <li style={{ marginLeft: '2em' }}>Матери, осуществляющие уход за ребенком до достижения им трехлетнего возраста, выплата назначается независимо от занятости</li>
                            </ul>
                        </div>
                    </li>
                    <li>право опекуна возникает только при условии неполучения им в установленном законодательством Челябинской области порядке денежных средств на содержание детей, находящихся под опекой.</li>
                </ul>


                <text>Порядок учета и исчисления величины среднедушевого дохода семьи определен Постановлением Губернатора Челябинской области от 2 августа 2012 года № 211 <a target="_blank" href="http://docs.cntd.ru/document/453120368">ссылка</a> </text>
                <text>Выплата назначается и выплачивается на каждого третьего и последующего ребенка в размере величины прожиточного минимума для детей, установленной в соответствии с законодательством Челябинской области во втором квартале года, предшествующего выплате ежемесячной денежной выплаты.</text>



                <div className="small-title">Правоустанавливающими документами являются:</div>
                <ul className="text">
                    <li>копия документа, удостоверяющего личность заявителя;</li>
                    <li>копии свидетельств о рождении детей;</li>
                    <li>документы, выданные уполномоченными органами и организациями о регистрации заявителя и ребенка, на которого назначается ежемесячная денежная выплата, а также других членов семьи заявителя по месту жительства на территории Челябинской области, подтверждающие их совместное проживание. </li>
                    <li>документы, подтверждающие доход каждого члена семьи за последние три месяца перед обращением за назначением и выплатой ежемесячной денежной выплаты, для определения среднедушевого дохода семьи, либо документы, подтверждающие отсутствие дохода семьи</li>
                    <li>Документы (сведения), необходимые для назначения ежемесячной выплаты, представляются заявителем или запрашиваются органом социальной защиты населения по месту жительства заявителя в рамках межведомственного взаимодействия в органах и (или) организациях, в распоряжении которых они находятся.
                    Заявитель вправе предоставить документы самостоятельно.</li>
                    <li>справка об обучении ребенка старше 18 лет в образовательной организации по очной форме обучения</li>
                </ul>

                <div className="small-title">Нормативно правовые акты </div>
                <ul className="text">
                    <li><a target="_blank" href="http://docs.cntd.ru/document/453121581 ">Закон Челябинской области от 30 августа 2012 г. N 371-ЗО "О ежемесячной денежной выплате, назначаемой в случае рождения третьего ребенка и (или) последующих детей до достижения ребенком возраста трех лет" </a></li>
                    <li><a target="_blank" href="http://docs2.cntd.ru/document/550136884 ">Постановление Правительства Челябинской области от 20 июня 2018 г. N 301-П "Об Административном регламенте предоставления государственной услуги "Назначение и выплата ежемесячной денежной выплаты, назначаемой в случае рождения третьего ребенка и (или) последующих детей до достижения ребенком возраста трех лет" и внесении изменений в некоторые постановления Правительства Челябинской области" </a></li>
                    <li><a target="_blank" href="http://docs.cntd.ru/document/453120368">Постановление Губернатора Челябинской области от 2 августа 2012 г. N 211 "О Порядке учета и исчисления величины среднедушевого дохода семьи, дающего право на получение пособия на ребенка и дополнительных мер социальной поддержки семей, имеющих детей" </a></li>
                </ul>

                <div className="small-title">Основание для отказа</div>
                <ul className="text">
                    <li>достижение ребенком возраста трех лет;</li>
                    <li>передача ребенка на полное государственное обеспечение;</li>
                    <li>помещение ребенка в организацию, оказывающую социальные услуги;</li>
                    <li>смерть ребенка;</li>
                    <li>вынесение судом решения об ограничении родительских прав, о лишении родительских прав, об отмене усыновления в отношении ребенка;</li>
                    <li>снятие получателя ежемесячной денежной выплаты с регистрационного учета по месту жительства на территории Челябинской области;</li>
                    <li>отсутствие факта совместного проживания заявителя с ребенком;</li>
                    <li>освобождение, отстранение опекуна от исполнения им своих обязанностей;</li>
                    <li>превышение размера среднедушевого дохода семьи по отношению к величине прожиточного минимума на душу населения в Челябинской области;</li>
                    <li>превышение размера среднедушевого дохода семьи по отношению к двукратной величине прожиточного минимума для трудоспособного населения, установленной в соответствии с законодательством Челябинской области во втором квартале года, предшествующего году подачи заявления, при рождении третьего ребенка и (или) последующих детей начиная с 1 января 2020 года;</li>
                    <li>смерть получателя ежемесячной денежной выплаты;</li>
                    <li>утрата статуса многодетной семьи;</li>
                    <li>если родители, усыновители, опекуны перестают считаться занятыми в соответствии с Законом Российской Федерации от 19 апреля 1991 года N 1032-I "О занятости населения в Российской Федерации" либо относиться к категориям лиц, указанным в абзацах седьмом - четырнадцатом пункта 5 настоящего Административного регламента.</li>
                </ul>
                <p>Подробные сведения размещены <a target="_blank" href="https://gosuslugi74.ru/pgu/services/info/targets.htm?id=14910@egServiceTarget&serviceId=9010@egService  ">здесь</a></p>
                <p>Подать заявление можно <a target="_blank" href="https://gosuslugi74.ru/pgu/services/info/targets.htm?id=14910@egServiceTarget&serviceId=9010@egService ">здесь</a></p>
            </div>

        </div>

    </Container >)
}

export default Act1;