import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import { Link } from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/children"> Социальная поддержка семей с детьми</Link></li>
            </ol>
        </nav>
        <div className="name">Областное единовременное пособие при рождении ребенка</div>

        <div className='white-card'>

            <div className='card-body'>
                <text>Пособие назначается и выплачивается следующим категориям лиц гражданам Российской Федерации, постоянно проживающим на территории Челябинской области; иностранным гражданам при условии оформления вида на жительство, либо разрешения на временное проживание и официального трудоустройства (подлежащим обязательному социальному страхованию на случай временной нетрудоспособности и в связи с материнством </text>
                <div className="small-title">Нормативно правовые акты</div>
                <ul className="text">
                    <li><a target="_blank" href="https://docs.cntd.ru/document/802041489 ">Закон Челябинской области от 27 октября 2005 г. N 417-ЗО "Об областном единовременном пособии при рождении ребенка" </a></li>
                    <li><a target="_blank" href="http://docs.cntd.ru/document/499506033">Постановление Правительства Челябинской области от 16 ноября 2011 г. N 424-П "Об Административном регламенте предоставления государственной услуги "Назначение и выплата областного единовременного пособия при рождении ребенка" </a></li>
                </ul>
                <div className="small-title">Размер пособия определяется в зависимости от очередности рождения ребенка</div>
                <text>На каждого ребенка, рождённого <strong>с 1 декабря 2021</strong> размер составляет 6 000 рублей (единовременно).
                на детей,<br/> рожденных <strong>по 30 ноября 2021</strong> в зависимости от очередности его рождения:</text>
                <ul className="text">
                    <li>при рождении первого ребенка - 2 000 рублей;</li>
                    <li>при рождении второго ребенка - 3 000 рублей;</li>
                    <li>при рождении третьего ребенка - 4 000 рублей;</li>
                    <li>при рождении четвертого ребенка - 5 000 рублей;</li>
                    <li>при рождении пятого ребенка и последующих детей - 6 000 рублей.</li>

                </ul>
                <div className="small-title">Основание для отказа</div>
                <ul className="text">
                    <li>Обращение за пособием по истечении двенадцати месяцев со дня рождения ребенка   </li>
                    <li>Отсутствие у заявителя регистрации по месту жительства (месту пребывания) на территории Челябинской области   </li>
                    <li>Наличие противоречий в документах, представляемых заявителем   </li>
                    <li>Лишение заявителя родительских прав либо ограничение его в родительских правах   </li>
                    <li>Нахождение ребенка на полном государственном обеспечении   </li>
                    <li>Рождение мертвого ребенка   </li>
                </ul>
                <p>Подробные сведения о пособии размещены <a target="_blank" href="https://gosuslugi74.ru/pgu/services/info/targets.htm?id=14908@egServiceTarget&serviceId=9009@egService">здесь</a></p>

                <p><strong>С 01 декабря 2021 достаточно подать заявление в электронном виде (однократное посещение УСЗН потребуется, ТОЛЬКО в случае если сведения (документы) об актах гражданского состояния (рождение ребенка, заключение/расторжение брака) на заявителя и (или) членов его семьи, выданы компетентным органом иностранного государства).</strong></p>
                <p>Подать заявление можно <a target="_blank" href="https://gosuslugi74.ru/pgu/services/info/targets.htm?id=14908@egServiceTarget&serviceId=9009@egService">здесь</a></p>
            </div>

        </div>

    </Container >)
}

export default Act1;