import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act4 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                <li className="breadcrumb-item"><Link to="/certificateBase" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами</Link></li>
                <li className="breadcrumb-item active" aria-current="page">«Выдача удостоверения инвалида Великой Отечественной войны и удостоверения инвалида о праве на льготы проживающим на территории Челябинской области инвалидам Великой Отечественной войны и приравненным к ним лицам»</li>
            </ol>
        </nav>
        <div className="name">«Выдача удостоверения инвалида Великой Отечественной войны и удостоверения инвалида о праве на льготы проживающим на территории Челябинской области инвалидам Великой Отечественной войны и приравненным к ним лицам»</div>

        <div className='white-card'>

            <div className='card-body'>
                <div className="small-title">круг заявителей</div>
                <ul className="text">
                    <li>проживающие на территории Челябинской области инвалиды Великой Отечественной войны, инвалиды боевых действий на территории СССР, на территории Российской Федерации и территориях других государств из числа лиц, указанных в статье 4 Федерального закона от 12 января 1995 года N 5-ФЗ "О ветеранах", а также военнослужащие, проходившие военную службу по призыву, ставшие инвалидами вследствие ранения, контузии или увечья, полученных при исполнении обязанностей военной службы, из числа лиц, указанных в пункте 3 статьи 14 Федерального закона от 12 января 1995 года N 5-ФЗ "О ветеранах", пенсионное обеспечение которых осуществляется территориальными органами Пенсионного фонда Российской Федерации.</li>
                </ul>
                <div className="small-title">срок предоставления государственной услуги</div>
                <ul className="text">
                    <li>Срок предоставления государственной услуги со дня регистрации в органе социальной защиты населения либо многофункциональном центре заявления о предоставлении государственной услуги со всеми необходимыми документами, обязанность по предоставлению которых возложена на заявителя, до вынесения Министерством социальных отношений Челябинской области решения о предоставлении либо об отказе в предоставлении государственной услуги не может превышать 30 календарных дней.</li>
                </ul>
                <div className="small-title">исчерпывающий перечень документов</div>
                <ul className="text">
                    <li>заявление</li>
                    <li>документ, удостоверяющий личность (при обращении представителя заявителя - документы, подтверждающие его полномочия);</li>
                    <li>справка, подтверждающая факт установления инвалидности, выдаваемая федеральными государственными учреждениями медико-социальной экспертизы (ранее выдаваемая врачебно-трудовой экспертной комиссией);</li>
                    <li>пенсионное удостоверение либо справка о получении пенсии.</li>
                    <li>фотография размером 2 х 3 сантиметра</li>
                </ul>
                <div className="small-title">результат предоставления государственной услуги</div>
                <ul className="text">
                    <li>Результат предоставления государственной услуги - выдача удостоверения инвалида Великой Отечественной войны или удостоверения инвалида о праве на льготы (далее именуется - удостоверение инвалида войны), дубликата удостоверения инвалида войны.</li>
                </ul>
                <div className="small-title">Основания для отказа в приеме документов </div>
                <ul className="text">
                    <li>неполного представления или отсутствия документов;</li>
                    <li>если документы имеют подчистки, приписки, зачеркнутые слова и иные не оговоренные в них исправления</li>
                    <li>непредоставление в течение 5 рабочих дней со дня регистрации заявления в органе социальной защиты населения оригиналов документов, обязанность по предоставлению которых возложена на заявителя (при подаче документов посредством заполнения электронной формы запроса с использованием федерального портала);</li>
                </ul>
                <div className="small-title">основания для отказа в предоставлении государственной услуги</div>
                <ul className="text">
                    <li>получение пенсии в соответствии с Законом Российской Федерации от 12 февраля 1993 года N 4468-1 "О пенсионном обеспечении лиц, проходивших военную службу, службу в органах внутренних дел, Государственной противопожарной службе, органах по контролю за оборотом наркотических средств и психотропных веществ, учреждениях и органах уголовно-исполнительной системы, и их семей</li>
                    <li>причина наступления инвалидности не соответствует причинам инвалидности, указанным в статье 4 и пункте 3 статьи 14 Федерального закона"О ветеранах".</li>
                </ul>
                <div className="small-title">право на досудебное (внесудебное) обжалование решений и действий (бездействия) должностных лиц </div>
                <ul className="text">
                    <li>В досудебном (внесудебном) порядке заявители могут обжаловать действия (бездействие) Министерства социальных отношений Челябинской области, органов социальной защиты населения, а также их должностных лиц, государственных служащих, муниципальных служащих и принимаемые ими решения при предоставлении государственной услуги</li>
                </ul>

                    <p><a target="_blank" href="http://minsoc74.ru/sites/default/files/4_zayavlenie_invalida_voyny.doc">Форма заявления</a></p>

            </div>

        </div>

    </Container >)
}

export default Act4;