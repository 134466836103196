import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import {
    Link
} from 'react-router-dom';


export class CertificateWow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            first: true,
            second: false,
            third: false,
            fourth: false,
            fifth: false,
            seventh: false,
            seventhDop: false
        };
    }

    handleStartClick(e) {
        this.setState({
            first: true,
            second: false,
            third: false,
            fourth: false,
            fifth: false,
            seventh: false,
            seventhDop: false
        })
    }


    render() {
        const { first, second, third, fourth, fifth, seventh, seventhDop } = this.state;
        return (
            <Container>
                <Row>
                    <Col md='12' lg='12'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb pl-0">
                                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"><Link to="/certificateBase" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами</Link></li>
                            </ol>
                        </nav>
                    </Col>
                </Row>
                <Row>
                    <Col md='12' lg='12' className='mb-3'>
                        <div className='head-list-child mt-30'>
                            <div className='title-head-list title-head-list-child'>Получение удостоверения ветерана Великой Отечественной войны</div>
                        </div>
                        <div className='white-box-child last pt-0'>

                            {first ? (<div className="element_group">
                                <div className="group_string">

                                    <div className="title-group">
                                        <label>Наличие постоянной регистрации по месту жительства на территории Челябинской области</label>
                                    </div>

                                    <div className="element_string attribute ">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value0" name="attribute_0" className="custom-control-input" onChange={() => this.setState({ first: false, second: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_0" htmlFor="attribute_value0">да</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value1" name="attribute_0" className="custom-control-input" onChange={() => this.setState({ first: false, seventh: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_1" htmlFor="attribute_value1">нет</label>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null
                            }


                            {second ? (<div className="element_group">
                                <div className="group_string">

                                    <div className="title-group">
                                        <label>Выдавалось ли Вам ранее удостоверение ветерана Великой Отечественной войны либо удостоверение о праве на льготы, образец которого утвержден до
                                            01.01.1992 г. для выдачи ветеранам войны?</label>
                                    </div>

                                    <div className="element_string attribute ">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value2" name="attribute_1" className="custom-control-input" onChange={() => this.setState({ second: false, third: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_2" htmlFor="attribute_value2">да</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value3" name="attribute_1" className="custom-control-input" onChange={() => this.setState({ second: false, seventhDop: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_3" htmlFor="attribute_value3">нет</label>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null
                            }

                            {third ? (<div className="element_group">
                                <div className="group_string">

                                    <div className="title-group">
                                        <label>Укажите, по какой категории ветеранов Вам ранее выдано удостоверение ветерана Великой Отечественной войны либо удостоверение о праве на льготы?</label>
                                    </div>

                                    <div className="element_string attribute">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value4" name="attribute_2" className="custom-control-input" onChange={() => this.setState({ third: false, fourth: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_4" htmlFor="attribute_value4">участник Великой Отечественной войны (<a target="_blank" href="http://www.kremlin.ru/acts/bank/7432/page/1">подпункт 1 пункта 1 статьи 2 Федерального закона «О ветеранах»</a> ) </label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value5" name="attribute_2" className="custom-control-input" onChange={() => this.setState({ third: false, fourth: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_5" htmlFor="attribute_value5">лица, работавшие на объектах противовоздушной обороны, местной противовоздушной обороны, на строительстве оборонительных сооружений, военно-морских баз, аэродромов и других военных объектов в пределах тыловых границ действующих фронтов, операционных зон действующих флотов, на прифронтовых участках железных и автомобильных дорог; члены экипажей судов транспортного флота, интернированные в начале Великой Отечественной войны в портах других государств (подпункт 2 пункта 1 статьи 2 (ссылка) Федерального закона «О ветеранах») </label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value6" name="attribute_2" className="custom-control-input" onChange={() => this.setState({ third: false, fourth: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_6" htmlFor="attribute_value6">лица, награжденные знаком «Жителю блокадного Ленинграда» (блокадники) (подпункт 3 пункта 1 статьи 2 (ссылка) Федерального закона «О ветеранах»)</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value7" name="attribute_2" className="custom-control-input" onChange={() => this.setState({ third: false, fourth: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_7" htmlFor="attribute_value7">лица, проработавшие в тылу в период с 22 июня 1941 года по 9 мая 1945 года не менее шести месяцев (исключая период работы на временно оккупированных территориях СССР); лица, награжденные орденами или медалями СССР за самоотверженный труд в период Великой Отечественной войны (труженики тыла) (подпункт 4 пункта 1 статьи 2 (ссылка) Федерального закона «О ветеранах») </label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/74">
                                                <input type="radio" id="attribute_value8" name="attribute_2" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_8" htmlFor="attribute_value8">ни одна из указанных категорий </label>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null
                            }

                            {fourth ? (<div className="element_group">
                                <div className="group_string">

                                    <div className="title-group">
                                        <label>Вы получаете пенсию в Пенсионном фонде Российской Федерации?</label>
                                    </div>

                                    <div className="element_string attribute ">
                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/75">
                                                <input type="radio" id="attribute_value9" name="attribute_3" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_9" htmlFor="attribute_value9">да</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/76">
                                                <input type="radio" id="attribute_value10" name="attribute_3" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_10" htmlFor="attribute_value10">нет</label>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>)
                                : null
                            }

                            {seventhDop ?
                                (<div className="element_group">
                                    <div className="group_string">

                                        <div className="title-group">
                                            <label>По какой категории Вы претендуете на получение удостоверения ветерана Великой Отечественной войны?</label>
                                        </div>

                                        <div className="element_string attribute ">
                                            <div className="custom-control custom-radio list_item_block">
                                                <Link to="/action/77">
                                                    <input type="radio" id="attribute_value11" name="attribute_4" className="custom-control-input" />
                                                    <label className="custom-control-label label-group" id="attribute_value_label_11" htmlFor="attribute_value11">участник Великой Отечественной войны из числа лиц, указанных в подпунктах «а» - «г», «ж» и «з» подпункта 1 пункта 1 статьи 2 (ссылка) Федерального закона «О ветеранах» </label>
                                                </Link>
                                            </div>

                                            <div className="custom-control custom-radio list_item_block">
                                                <Link to="/action/78">
                                                    <input type="radio" id="attribute_value12" name="attribute_4" className="custom-control-input" />
                                                    <label className="custom-control-label label-group" id="attribute_value_label_12" htmlFor="attribute_value12">участник Великой Отечественной войны из числа лиц, указанных в подпункте «д» подпункта 1 пункта 1 статьи 2 (ссылка) Федерального закона «О ветеранах» </label>
                                                </Link>
                                            </div>

                                            <div className="custom-control custom-radio list_item_block">
                                                <input type="radio" id="attribute_value13" name="attribute_4" className="custom-control-input" onChange={() => this.setState({ seventh: false, fifth: true })} />
                                                <label className="custom-control-label label-group" id="attribute_value_label_13" htmlFor="attribute_value13">участник Великой Отечественной войны из числа, указанных в подпункте «е» подпункта 1 пункта 1 статьи 2 (ссылка) Федерального закона «О ветеранах» военнослужащих, в том числе уволенных в запас (отставку), лиц рядового и начальствующего состава органов внутренних дел и органов государственной безопасности, бойцов и лиц командного состава истребительных батальонов, взводов и отрядов защиты народа, принимавших участие в боевых операциях по борьбе с десантами противника и боевых действиях совместно с воинскими частями, входившими в состав действующей армии, в период Великой Отечественной войны </label>
                                            </div>

                                            <div className="custom-control custom-radio list_item_block">
                                                <Link to="/action/80">
                                                    <input type="radio" id="attribute_value14" name="attribute_4" className="custom-control-input" />
                                                    <label className="custom-control-label label-group" id="attribute_value_label_14" htmlFor="attribute_value14">участник Великой Отечественной войны из числа, указанных в подпункте «е» подпункта 1 пункта 1 статьи 2 (ссылка) Федерального закона «О ветеранах» военнослужащих, в том числе уволенных в запас (отставку), лиц рядового и начальствующего состава органов внутренних дел и органов государственной безопасности, бойцов и лиц командного состава истребительных батальонов, взводов и отрядов защиты народа, принимавших участие в боевых операциях по ликвидации националистического подполья на территориях Украины, Белоруссии, Литвы, Латвии и Эстонии в период с 1 января 1944 г. по 31 декабря 1951 г., а также из числа лиц, принимавших участие в операциях по боевому тралению в подразделениях, не входивших в состав действующего флота, в период Великой Отечественной войны</label>
                                                </Link>
                                            </div>

                                            <div className="custom-control custom-radio list_item_block">
                                                <Link to="/action/75">
                                                    <input type="radio" id="attribute_value15" name="attribute_4" className="custom-control-input" />
                                                    <label className="custom-control-label label-group" id="attribute_value_label_15" htmlFor="attribute_value15">участник Великой Отечественной войны из числа, указанных в подпункте «е» подпункта 1 пункта 1 статьи 2 (ссылка) Федерального закона «О ветеранах» лиц, привлекавшихся организациями Осоавиахима СССР и органами местной власти к разминированию территорий и объектов, сбору боеприпасов и военной техники в период с 22 июня 1941 г. по 9 мая 1945 г. </label>
                                                </Link>
                                            </div>
                                            <div className="custom-control custom-radio list_item_block">
                                                <input type="radio" id="attribute_value16" name="attribute_4" className="custom-control-input" onChange={() => this.setState({ seventh: false, fifth: true })} />
                                                <label className="custom-control-label label-group" id="attribute_value_label_16" htmlFor="attribute_value16">  участник Великой Отечественной войны из числа, указанных в подпункте «и» подпункта 1 пункта 1 статьи 2 (ссылка) Федерального закона «О ветеранах» лиц, награжденных медалью «За оборону Ленинграда», а также инвалидов с детства вследствие ранения, контузии или увечья, связанных с боевыми действиями в период Великой Отечественной войны 1941 - 1945 годов </label>
                                            </div>
                                            <div className="custom-control custom-radio list_item_block">
                                                <Link to="/action/75">
                                                    <input type="radio" id="attribute_value17" name="attribute_4" className="custom-control-input" />
                                                    <label className="custom-control-label label-group" id="attribute_value_label_17" htmlFor="attribute_value17">лица, работавшие на объектах противовоздушной обороны, местной противовоздушной обороны, на строительстве оборонительных сооружений, военно-морских баз, аэродромов и других военных объектов в пределах тыловых границ действующих фронтов, операционных зон действующих флотов, на прифронтовых участках железных и автомобильных дорог (подпункт 2 пункта 1 статьи 2 (ссылка) Федерального закона «О ветеранах») </label>
                                                </Link>
                                            </div>
                                            <div className="custom-control custom-radio list_item_block">
                                                <Link to="/action/81">
                                                    <input type="radio" id="attribute_value18" name="attribute_4" className="custom-control-input" />
                                                    <label className="custom-control-label label-group" id="attribute_value_label_18" htmlFor="attribute_value18">члены экипажей судов транспортного флота, интернированные в начале Великой Отечественной войны в портах других государств (подпункт 2 пункта 1 статьи 2 (ссылка) Федерального закона «О ветеранах»)</label>
                                                </Link>
                                            </div>
                                            <div className="custom-control custom-radio list_item_block">
                                                <input type="radio" id="attribute_value19" name="attribute_4" className="custom-control-input" onChange={() => this.setState({ seventh: false, fifth: true })} />
                                                <label className="custom-control-label label-group" id="attribute_value_label_19" htmlFor="attribute_value19">лица, награжденные знаком «Жителю блокадного Ленинграда» (подпункт 3 пункта 1 статьи 2 (ссылка) Федерального закона «О ветеранах») </label>
                                            </div>
                                            <div className="custom-control custom-radio list_item_block">
                                                <input type="radio" id="attribute_value20" name="attribute_4" className="custom-control-input" onChange={() => this.setState({ seventh: false, fifth: true })} />
                                                <label className="custom-control-label label-group" id="attribute_value_label_20" htmlFor="attribute_value20">лица, проработавшие в тылу в период с 22 июня 1941 года по 9 мая 1945 года не менее шести месяцев, исключая период работы на временно оккупированных территориях СССР; лица, награжденные орденами или медалями СССР за самоотверженный труд в период Великой Отечественной войны (подпункт 4 пункта 1 статьи 2 (ссылка) Федерального закона «О ветеранах») </label>
                                            </div>

                                            <div className="custom-control custom-radio list_item_block">
                                                <Link to="/action/74">
                                                    <input type="radio" id="attribute_value21" name="attribute_4" className="custom-control-input" />
                                                    <label className="custom-control-label label-group" id="attribute_value_label_21" htmlFor="attribute_value21">ни одна из указанных категорий </label>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                                : null
                            }

                            {seventh ? (<div><h3>Гражданам, не имеющим постоянной регистрации по месту жительства на территории Челябинской области, удостоверение ветерана Великой Отечественной войны не выдается;</h3></div>) : null}

                            {fifth ? (<div className="element_group">
                                <div className="group_string">

                                    <div className="title-group">
                                        <label>Вы получаете пенсию в Пенсионном фонде Российской Федерации?</label>
                                    </div>

                                    <div className="element_string attribute ">
                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/75">
                                                <input type="radio" id="attribute_value22" name="attribute_5" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_22" htmlFor="attribute_value22">да</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/79">
                                                <input type="radio" id="attribute_value23" name="attribute_5" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_23" htmlFor="attribute_value23">нет</label>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null
                            }

                        </div>

                    </Col>
                </Row >
            </Container >
        );
    }
}
