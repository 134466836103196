import React, { useState } from 'react';
import { Container, Form, FormGroup, Label, Input, Col, Row, Table, Button } from 'reactstrap';
import './Action.css'

const Act23 = () => {
    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><a href="/">Главная</a></li>
                <li className="breadcrumb-item"><a href="/socialservices">Меры социальной поддержки детей-сирот и детей, оставшихся без попечения родителей</a></li>
            </ol>
        </nav>
        <div className="name">Право на получение алиментов от родителей </div>

        <div className='white-card'>

            <div className='card-body'>
                Право на получение алиментов от родителей (а в случае их смерти — пенсии по случаю потери кормильца, на льготы, предусмотренные для соответствующей категории (ребенок-инвалид, ребенок-сирота или ребенок, оставшийся без попечения родителей).
            </div>

        </div>

    </Container>)
}

export default Act23;