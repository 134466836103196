import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <ol className="breadcrumb pl-0">
                    <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                    <li className="breadcrumb-item active" aria-current="page"><Link to="/measure" style={{ cursor: 'pointer' }}>Меры социальной поддержки отдельным категориям граждан</Link></li>
                </ol>
            </ol>

        </nav>
        <div className="name">Назначение и выплата социального пособия на погребение, возмещение стоимости услуг по погребению</div>

        <div className='white-card'>

            <div className='card-body'>
                <div className="small-title">Право возникает</div>
                <ul className="text">
                    <li><strong>На выплату социального пособия</strong> - для физических лиц, взявших на себя обязанность осуществить погребение умершего</li>
                    <li><strong>На возмещение стоимости услуг по погребению</strong> – для юридических лиц, деятельность которых отнесена к специализированным службам по вопросам похоронного дела</li>
                </ul>
                <div className="small-title">Срок предоставления услуги:</div>
                <ul className="text">
                    <li>Назначение и выплата социального пособия на погребение умершего не может превышать одного рабочего дня с даты подачи заявления и необходимых документов.</li>
                    <li>Возмещение стоимости услуг по погребению, оказанных специализированными службами по вопросам похоронного дела, не может превышать десяти рабочих дней с даты подачи и необходимых документов.</li>
                </ul>

                <div className="small-title">Основание для отказа:</div>
                <ul className="text">
                    <li>Наличие противоречий в документах, представляемых заявителем</li>
                    <li>За пособием обратились по истечении 6 месяцев со дня смерти</li>
                </ul>

                <div className="small-title">Нормативно правовые акты (при нажатии на НПА можно по ссылке открыть его для просмотра)</div>
                <ul className="text">
                    <li>Постановление Правительства Челябинской области от 20 июня 2012 г. N 333-П "Об Административном регламенте предоставления государственной услуги "Назначение и выплата социального пособия на погребение, возмещение стоимости услуг по погребению" и внесении изменения в постановление Правительства Челябинской области от 18.12.2008 г. N 410-П" </li>
                    <li>Закон Челябинской области от 27 октября 2005 г. N 410-ЗО "О возмещении стоимости услуг по погребению и выплате социального пособия на погребение" </li>
                    <li>Постановление Правительства Челябинской области от 18 декабря 2008 г. N 410-П "О Порядке возмещения стоимости услуг по погребению, назначения и выплаты социального пособия на погребение"</li>
                </ul>

                <p><a target="_blank" href="https://gosuslugi74.ru/pgu/services/info/targets.htm?id=12925@egServiceTarget&serviceId=7558@egService">Подробные сведения</a></p>
                <p><a target="_blank" href="https://gosuslugi74.ru/pgu/services/info/targets.htm?id=12923@egServiceTarget&serviceId=7558@egService">Оформление социального пособия в случае, если умерший был неработающим и не являлся пенсионером </a></p>
                <p><a target="_blank" href="https://gosuslugi74.ru/pgu/services/info/targets.htm?id=12924@egServiceTarget&serviceId=7558@egService">Оформление социального пособия в случае рождения мертвого ребенка по истечении 154 дней беременности</a></p>
                <p><a target="_blank" href="https://gosuslugi74.ru/pgu/services/info/targets.htm?id=12925@egServiceTarget&serviceId=7558@egService">Оформление возмещения стоимости услуг по погребению для спецслужб</a></p>

            </div>

        </div>

    </Container >)
}

export default Act1;