import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item active"><Link to="/measure" style={{ cursor: 'pointer' }}>Меры социальной поддержки отдельным категориям граждан</Link></li>
            </ol>

        </nav>
        <div className="name">«Компенсация страховой премии по договору обязательного страхования гражданской ответственности владельцев транспортных средств в Челябинской области»</div>

        <div className='white-card'>

            <div className='card-body'>
                <div className="small-title">круг заявителей</div>
                <ul className="text">
                    <li>Инвалиды (в том числе дети-инвалиды), имеющие транспортные средства в соответствии с медицинскими показаниями</li>
                </ul>
                <div className="small-title">срок предоставления государственной услуги</div>
                <ul className="text">
                    <li>Компенсация выплачивается единовременно в месячный срок со дня принятия решения о назначении компенсации.</li>
                </ul>

                <div className="small-title">исчерпывающий перечень документов</div>
                <ul className="text">
                    <li>заявление о назначении компенсации, которое подается инвалидом или законным представителем инвалида (ребенка-инвалида) по форме в управление социальной защиты населения по месту жительства инвалида (ребенка-инвалида);</li>
                    <li>копия паспорта;</li>
                    <li>копия справки МСЭ (ВТЭк) об установлении группы инвалидности (с 01.07.2020 г. запрашивается органами социальной защиты населения в рамках межведомственного электронного взаимодействия);</li>
                    <li>копия страхового полиса обязательного страхования гражданской ответственности владельца транспортного средства;</li>
                    <li>копия квитанции об уплате страховой премии по договору;</li>
                    <li>копия паспорта транспортного средства, выписанного на имя инвалида или законного представителя ребенка-инвалида (в случае если паспорт транспортного средства оформлен в электронном виде – копия карточки учета транспортного средства, заверенная органами ГБДД);</li>
                    <li>документы (сведения) о наличии медицинских показаний для обеспечения транспортным средством, выдаваемые федеральным государственным учреждением медико-социальной экспертизы (врачебно-трудовой экспертной комиссией).</li>
                </ul>

                <div className="small-title">результат предоставления государственной услуги</div>
                <ul className="text">
                    <li>Результатом предоставления государственной услуги является выплата компенсации страховой премии.</li>
                </ul>
                <div className="small-title">Основания для отказа в приеме документов </div>
                <ul className="text">
                    <li>Основания для отказа в приёме документов отсутствуют.</li>
                </ul>

                <div className="small-title">основания для отказа в предоставлении государственной услуги</div>
                <ul className="text">
                    <li>использование транспортного средства лицом, имеющим право на компенсацию, и наряду с ним более чем двумя водителями, указанными в договоре обязательного страхования гражданской ответственности владельцев транспортных средств;
                    представление неполного пакета документов</li>

                </ul>
                <div className="small-title">право на досудебное (внесудебное) обжалование решений и действий (бездействия) должностных лиц </div>
                <ul className="text">
                    <li>В досудебном (внесудебном) порядке заявители могут обжаловать действия (бездействие) органов социальной защиты населения, Министерства социальных отношений Челябинской области, многофункционального центра, а также их должностных лиц, государственных гражданских служащих, муниципальных служащих, работников и принимаемые ими решения при предоставлении государственной услуги.</li>
                </ul>

                <div className="small-title"><a target="_blank" href="http://minsoc74.ru/sites/default/files/12.docx">Форма заявления</a></div>

            </div>

        </div>

    </Container >)
}

export default Act1;