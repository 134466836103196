import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                <li className="breadcrumb-item active" aria-current="page"><Link to="/certificateBase" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами</Link></li>
            </ol>
        </nav>

        <div className='white-card'>

            <div className='card-body'>
                <p>Гражданам, пенсионное обеспечение которых осуществляется в соответствии с Законом (ссылка) Российской Федерации «О пенсионном обеспечении лиц, проходивших военную службу, службу в органах внутренних дел, Государственной противопожарной службе, органах по контролю за оборотом наркотических средств и психотропных веществ, учреждениях и органах уголовно-исполнительной системы, войсках национальной гвардии Российской Федерации, органах принудительного исполнения Российской Федерации, и их семей», переоформление ранее выданных удостоверений о праве на льготы или удостоверения ветерана Великой Отечественной войны либо выдача дубликата удостоверения ветерана Великой Отечественной войны производится органами, осуществляющими их пенсионное обеспечение.</p>
                <a>Ссылка на пункт 8 Инструкции о порядке заполнения, выдачи и учета удостоверений ветерана Великой Отечественной войны, утвержденной постановлением Правительства Российской Федерации от 05.10.1999 г. № 1122</a>
                <p className="mt-3"><Link to="/action/3" style={{ cursor: 'pointer' }}>Общая информация о выдаче удостоверения ветерана Великой Отечественной войны</Link></p>
            </div>

        </div>

    </Container >)
}

export default Act1;