import React, { useState } from 'react';
import { Container, Form, FormGroup, Label, Input, Col, Row, Table,Button } from 'reactstrap';
import './Action.css'

const Act23 = () => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><a href="/">Главная</a></li>
                <li className="breadcrumb-item"><a href="/socialservices">Меры социальной поддержки детей-сирот и детей, оставшихся без попечения родителей</a></li>
            </ol>
        </nav>
        <div className="name">Обеспечение одеждой, обувью, мягким инвентарем, оборудованием (далее - материальное обеспечение) и единовременным денежным пособием</div>

        <div className='white-card'>

            <div className='card-body'>
                <div className="small-title">Обеспечение одеждой, обувью, мягким инвентарем, оборудованием (далее - материальное обеспечение) и единовременным денежным пособием имеют дети-сироты и дети, оставшиеся без попечения родителей, лица из их числа, а также лица, потерявшие в период обучения обоих родителей или единственного родителя (за исключением лиц, продолжающих обучение по основным профессиональным образовательным программам и (или) по программам профессиональной подготовки по профессиям рабочих, должностям служащих в профессиональных образовательных организациях и образовательных организациях высшего образования по очной форме обучения за счет средств соответствующих бюджетов), которые являются</div>
                <ul className="text">
                    <li> выпускниками областных государственных и муниципальных организаций для детей-сирот и детей, оставшихся без попечения родителей, областных государственных и муниципальных организаций, оказывающих социальные услуги (реализующих программы начального общего, основного общего и среднего общего образования), находившимися в них под надзором на полном государственном обеспечении за счет средств областного бюджета;</li>
                    <li> выпускниками областных государственных профессиональных образовательных организаций, областных государственных образовательных организаций высшего образования, обучавшимися по основным профессиональным образовательным программам и (или) по программам профессиональной подготовки по профессиям рабочих, должностям служащих по очной форме обучения за счет средств областного бюджета и находившимися в них в период своего обучения на полном государственном обеспечении;</li>
                    <li> лицами, закончившими свое пребывание под опекой (попечительством) и в приемных семьях, достигшими возраста 18 лет, получавшими денежные средства на свое содержание из областного бюджета;</li>
                    <li> выпускниками областных государственных специальных учебно-воспитательных учреждений открытого и закрытого типа, в которых они обучались и воспитывались за счет средств областного бюджета.</li>

                </ul>
            </div>

        </div>

    </Container>)
}

export default Act23;