import React, { Component } from 'react';
import { Container, Row, Col, UncontrolledCollapse } from 'reactstrap';
import {
    Link
} from 'react-router-dom';


export class Social extends Component {

    constructor(props) {
        super(props);
        this.state = {
            first: true,
            second: false,
            third: false,
            fourth: false,
            fifth: false
        };
    }

    handleStartClick(e) {
        this.setState({
            first: true,
            second: false,
            third: false,
            fourth: false,
            fifth: false
        })
    }


    render() {
        const { first, second, third, fourth, fifth } = this.state;
        return (
            <Container>
                <Row>
                    <Col md='12' lg='12'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb pl-0">
                                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                                <li className="breadcrumb-item active"><Link to="/measure" style={{ cursor: 'pointer' }}>Меры социальной поддержки отдельным категориям граждан</Link></li>
                            </ol>
                        </nav>
                    </Col>
                </Row>
                <Row>
                    <Col md='12' lg='12' className='mb-3'>
                        <div className='head-list-child mt-30'>
                            <div className='title-head-list title-head-list-child'>Социальная поддержка граждан, имеющих удостоверения (справку) о праве на льготы</div>
                        </div>

                        <div className='white-box-child last pt-0'>


                            {first ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Вам установлен льготный статус?</label>
                                    </div>

                                    <div className="element_string attribute ">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value0" name="attribute_0" className="custom-control-input" onChange={() => this.setState({ first: false, second: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_0" htmlFor="attribute_value0">да</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/">
                                                <input type="radio" id="attribute_value1" name="attribute_0" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_1" htmlFor="attribute_value1">нет</label>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null
                            }


                            {second ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Укажите, какой статус Вам установлен:</label>
                                    </div>

                                    <div className="element_string attribute ">
                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/94">
                                                <input type="radio" id="attribute_value2" name="attribute_1" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_2" htmlFor="attribute_value2">Ветеран труда, ветеран военной службы</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/94">
                                                <input type="radio" id="attribute_value3" name="attribute_1" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_3" htmlFor="attribute_value3">Ветеран труда Челябинской области:</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/94">
                                                <input type="radio" id="attribute_value4" name="attribute_1" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_4" htmlFor="attribute_value4">Ветераны Великой Отечественной войны из числа лиц, проработавших в тылу в период с 22 июня 1941 года по 9 мая 1945 года не менее шести месяцев, исключая период работы на временно оккупированных территориях СССР, либо награжденных орденами или медалями СССР за самоотверженный труд в период Великой Отечественной войны</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block" id="toggler">
                                            <input type="radio" id="attribute_value5" name="attribute_1" className="custom-control-input" />
                                            <label className="custom-control-label label-group" id="attribute_value_label_5" htmlFor="attribute_value5">Реабилитированные лица</label>
                                        </div>

                                        <UncontrolledCollapse className="p-3" toggler="#toggler">
                                            <ul className="list-group list_item_block">
                                                <li className="list-group-item"><Link to="/action/94">Ежемесячная денежная выплата отдельным категориям ветеранов, жертвам политических репрессий и ветеранам труда Челябинской области </Link></li>
                                                <li className="list-group-item"><Link to="/action/95">Возмещение реабилитированным лицам расходов на проезд на междугородном транспорте</Link></li>
                                            </ul>
                                        </UncontrolledCollapse>


                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/94">
                                                <input type="radio" id="attribute_value6" name="attribute_1" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_6" htmlFor="attribute_value6">Лица, пострадавшие от политических репрессий</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block" id="toggler01">
                                            <input type="radio" id="attribute_value7" name="attribute_1" className="custom-control-input" />
                                            <label className="custom-control-label label-group" id="attribute_value_label_7" htmlFor="attribute_value7">Дети погибших участников Великой Отечественной войны и приравненные к ним лица</label>
                                        </div>

                                        <UncontrolledCollapse className="p-3" toggler="#toggler01">
                                            <ul className="list-group list_item_block">
                                                <li className="list-group-item"><Link to="/action/96">Ежемесячное социальное пособие детям погибших участников Великой Отечественной войны и приравненным к ним лицам</Link></li>
                                                <li className="list-group-item"><Link to="/action/97">Возмещение детям погибших участников Великой Отечественной войны и приравненным к ним лицам расходов на проезд к месту захоронения отца (матери)</Link></li>
                                            </ul>
                                        </UncontrolledCollapse>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/98">
                                                <input type="radio" id="attribute_value8" name="attribute_1" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_8" htmlFor="attribute_value8">Педагогические работники, вышедшие на пенсию и проживающие в сельских населенных пунктах, рабочих поселках (поселках городского типа) Челябинской области, имеющие стаж работы не менее 10 лет в областных государственных и муниципальных образовательных организациях, расположенных в сельских населенных пунктах, рабочих поселках (поселках городского типа) Челябинской области</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/98">
                                                <input type="radio" id="attribute_value9" name="attribute_1" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_9" htmlFor="attribute_value9">Лица, перешедшие на пенсию и проживающие в сельских населенных пунктах и рабочих поселках (поселках городского типа) Челябинской области, имеющие стаж работы не менее 10 лет в областных государственных или муниципальных организациях культуры, медицинских организациях, образовательных организациях, учреждениях ветеринарной службы, физкультурно-спортивных организациях, организациях социального обслуживания, расположенных в сельских населенных пунктах и рабочих поселках (поселках городского типа) Челябинской области</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block" id="toggler1" >
                                            <input type="radio" id="attribute_value10" name="attribute_1" className="custom-control-input" />
                                            <label className="custom-control-label label-group" id="attribute_value_label_10" htmlFor="attribute_value10">Инвалиды и семьи, имеющие детей-инвалидов</label>
                                        </div>

                                        <UncontrolledCollapse className="p-3" toggler="#toggler1">
                                            <ul className="list-group list_item_block">
                                                <li className="list-group-item"><Link to="/action/99">Компенсация расходов на оплату жилых помещений и коммунальных услуг отдельным категориям граждан </Link></li>
                                                <li className="list-group-item"><Link to="/action/100">Компенсация страховой премии по договору обязательного страхования гражданской ответственности владельцев транспортных средств в Челябинской области</Link></li>
                                                <li className="list-group-item"><Link to="/action/102">Обеспечение жильем отдельных категорий ветеранов, инвалидов и семей, имеющих детей-инвалидов, нуждающихся в улучшении жилищных условий и вставших на учет до 1 января 2005 года, и ветеранов Великой Отечественной войны вне зависимости от даты постановки на учет</Link></li>
                                            </ul>
                                        </UncontrolledCollapse>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value11" name="attribute_1" className="custom-control-input" onChange={() => this.setState({ second: false, third: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_11" htmlFor="attribute_value11">Инвалиды Великой Отечественной войны, инвалиды боевых действий, а также военнослужащие и лица рядового и начальствующего состава органов внутренних дел, Государственной противопожарной службы, учреждений и органов уголовно-исполнительной системы, ставшие инвалидами вследствие ранения, контузии или увечья, полученных при исполнении обязанностей военной службы (служебных обязанностей)</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block" id="toggler2" >
                                            <input type="radio" id="attribute_value12" name="attribute_1" className="custom-control-input" />
                                            <label className="custom-control-label label-group" id="attribute_value_label_12" htmlFor="attribute_value12">Участники Великой Отечественной войны, указанные в подпунктах «а» - «ж» и «и» подпункта 1 пункта 1 статьи 2 Федерального закона от 12 января 1995 года № 5-ФЗ «О ветеранах</label>
                                        </div>

                                        <UncontrolledCollapse className="p-3" toggler="#toggler2">
                                            <ul className="list-group list_item_block">
                                                <li className="list-group-item"><Link to="/action/99">Компенсация расходов на оплату жилых помещений и коммунальных услуг отдельным категориям граждан </Link></li>
                                                <li className="list-group-item"><Link to="/action/102">Обеспечение жильем отдельных категорий ветеранов, инвалидов и семей, имеющих детей-инвалидов, нуждающихся в улучшении жилищных условий и вставших на учет до 1 января 2005 года, и ветеранов Великой Отечественной войны вне зависимости от даты постановки на учет</Link></li>
                                            </ul>
                                        </UncontrolledCollapse>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/99">
                                                <input type="radio" id="attribute_value13" name="attribute_1" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_13" htmlFor="attribute_value13">Участники Великой Отечественной войны, указанные в подпункте "з" подпункта 1 пункта 1 статьи 2 Федерального закона от 12 января 1995 года N 5-ФЗ "О ветеранах", ставшие инвалидами вследствие общего заболевания, трудового увечья или других причин (кроме лиц, инвалидность которых наступила вследствие их противоправных действий)</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value14" name="attribute_1" className="custom-control-input" onChange={() => this.setState({ second: false, fourth: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_14" htmlFor="attribute_value14">Лица, награжденные знаком «Жителю блокадного Ленинграда</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block" id="toggler3">
                                            <input type="radio" id="attribute_value15" name="attribute_1" className="custom-control-input" />
                                            <label className="custom-control-label label-group" id="attribute_value_label_15" htmlFor="attribute_value15">Члены семей погибших (умерших) инвалидов войны, участников Великой Отечественной войны и ветеранов боевых действий, а также члены семей военнослужащих, лиц рядового и начальствующего состава органов внутренних дел, Государственной противопожарной службы, учреждений и органов уголовно-исполнительной системы и органов государственной безопасности, погибших при исполнении обязанностей военной службы (служебных обязанностей):</label>
                                        </div>

                                        <UncontrolledCollapse className="p-3" toggler="#toggler3">
                                            <ul className="list-group list_item_block">
                                                <li className="list-group-item"><Link to="/action/99">Компенсация расходов на оплату жилых помещений и коммунальных услуг отдельным категориям граждан </Link></li>
                                                <li className="list-group-item"><Link to="/action/102">Обеспечение жильем отдельных категорий ветеранов, инвалидов и семей, имеющих детей-инвалидов, нуждающихся в улучшении жилищных условий и вставших на учет до 1 января 2005 года, и ветеранов Великой Отечественной войны вне зависимости от даты постановки на учет</Link></li>
                                            </ul>
                                        </UncontrolledCollapse>

                                        <div className="custom-control custom-radio list_item_block" id="toggler4">
                                            <input type="radio" id="attribute_value16" name="attribute_1" className="custom-control-input" />
                                            <label className="custom-control-label label-group" id="attribute_value_label_16" htmlFor="attribute_value16">Ветераны боевых действий из числа лиц, указанных в подпунктах                  1 - 4 пункта 1 статьи 3 Федерального закона от 12 января 1995 года № 5-ФЗ «О ветеранах».</label>
                                        </div>

                                        <UncontrolledCollapse className="p-3" toggler="#toggler4">
                                            <ul className="list-group list_item_block">
                                                <li className="list-group-item"><Link to="/action/99">Компенсация расходов на оплату жилых помещений и коммунальных услуг отдельным категориям граждан </Link></li>
                                                <li className="list-group-item"><Link to="/action/102">Обеспечение жильем отдельных категорий ветеранов, инвалидов и семей, имеющих детей-инвалидов, нуждающихся в улучшении жилищных условий и вставших на учет до 1 января 2005 года, и ветеранов Великой Отечественной войны вне зависимости от даты постановки на учет</Link></li>
                                            </ul>
                                        </UncontrolledCollapse>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/99">
                                                <input type="radio" id="attribute_value17" name="attribute_1" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_17" htmlFor="attribute_value17">Ветераны подразделений особого риска и граждане, имеющие право на меры социальной поддержки по оплате жилого помещения и коммунальных услуг в соответствии с законодательством Российской Федерации, устанавливающим меры социальной поддержки ветеранам подразделений особого риска и отдельным категориям граждан, подвергшихся радиационному воздействию вследствие катастрофы на Чернобыльской АЭС, аварии на производственном объединении "Маяк" и ядерных испытаний на Семипалатинском полигоне</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block" id="toggler5">
                                            <input type="radio" id="attribute_value18" name="attribute_1" className="custom-control-input" />
                                            <label className="custom-control-label label-group" id="attribute_value_label_18" htmlFor="attribute_value18">Военнослужащие, в том числе уволенные в запас (отставку), проходившие военную службу в воинских частях, учреждениях, военно-учебных заведениях, не входивших в состав действующей армии, в период с 22 июня 1941 года по 3 сентября 1945 года не менее шести месяцев; военнослужащие, награжденные орденами или медалями СССР за службу в указанный период</label>
                                        </div>

                                        <UncontrolledCollapse className="p-3" toggler="#toggler5">
                                            <ul className="list-group list_item_block">
                                                <li className="list-group-item"><Link to="/action/99">Компенсация расходов на оплату жилых помещений и коммунальных услуг отдельным категориям граждан </Link></li>
                                                <li className="list-group-item"><Link to="/action/102">Обеспечение жильем отдельных категорий ветеранов, инвалидов и семей, имеющих детей-инвалидов, нуждающихся в улучшении жилищных условий и вставших на учет до 1 января 2005 года, и ветеранов Великой Отечественной войны вне зависимости от даты постановки на учет</Link></li>
                                            </ul>
                                        </UncontrolledCollapse>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/99">
                                                <input type="radio" id="attribute_value19" name="attribute_1" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_19" htmlFor="attribute_value19">Лица, принимавшие участие в военно-стратегической операции "Анадырь" на территории Республики Куба в период Карибского кризиса с 1 июля 1962 года по 30 ноября 1963 года</label>
                                            </Link>
                                        </div>


                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value20" name="attribute_1" className="custom-control-input" onChange={() => this.setState({ second: false, fifth: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_20" htmlFor="attribute_value20">Иные лица, имеющие право на меры социальной поддержки</label>
                                        </div>
                                    </div>
                                </div>

                            </div>)
                                : null
                            }

                            {third ? (<div className="element_group">
                                <div className="group_string">

                                    <div className="title-group">
                                        <label>Выберите группу инвалидности</label>
                                    </div>

                                    <div className="element_string attribute ">
                                        <div className="custom-control custom-radio list_item_block" id="toggler6" >
                                            <input type="radio" id="attribute_value21" name="attribute_2" className="custom-control-input" />
                                            <label className="custom-control-label label-group" id="attribute_value_label_21" htmlFor="attribute_value21">I или II группа инвалидности</label>
                                        </div>

                                        <UncontrolledCollapse className="p-3" toggler="#toggler6">
                                            <ul className="list-group list_item_block">
                                                <li className="list-group-item"><Link to="/action/99">Компенсация расходов на оплату жилых помещений и коммунальных услуг отдельным категориям граждан </Link></li>
                                                <li className="list-group-item"><Link to="/action/101">Компенсационные выплаты за пользование услугами местной телефонной связи и (или) за пользование услугами связи для целей проводного радиовещания </Link></li>
                                                <li className="list-group-item"><Link to="/action/102">Обеспечение жильем отдельных категорий ветеранов, инвалидов и семей, имеющих детей-инвалидов, нуждающихся в улучшении жилищных условий и вставших на учет до 1 января 2005 года, и ветеранов Великой Отечественной войны вне зависимости от даты постановки на учет</Link></li>
                                            </ul>
                                        </UncontrolledCollapse>

                                        <div className="custom-control custom-radio list_item_block" id="toggler7">
                                            <input type="radio" id="attribute_value22" name="attribute_2" className="custom-control-input" />
                                            <label className="custom-control-label label-group" id="attribute_value_label_22" htmlFor="attribute_value22">III группа инвалидности</label>
                                        </div>

                                        <UncontrolledCollapse className="p-3" toggler="#toggler7">
                                            <ul className="list-group list_item_block">
                                                <li className="list-group-item"><Link to="/action/99">Компенсация расходов на оплату жилых помещений и коммунальных услуг отдельным категориям граждан </Link></li>
                                                <li className="list-group-item"><Link to="/action/102">Обеспечение жильем отдельных категорий ветеранов, инвалидов и семей, имеющих детей-инвалидов, нуждающихся в улучшении жилищных условий и вставших на учет до 1 января 2005 года, и ветеранов Великой Отечественной войны вне зависимости от даты постановки на учет</Link></li>
                                            </ul>
                                        </UncontrolledCollapse>

                                    </div>
                                </div>
                            </div>)
                                : null
                            }

                            {fourth ? (<div className="element_group">
                                <div className="group_string">

                                    <div className="title-group">
                                        <label>Установлена группа инвалидности</label>
                                    </div>

                                    <div className="element_string attribute ">

                                        <div className="custom-control custom-radio list_item_block" id="toggler8">
                                            <input type="radio" id="attribute_value23" name="attribute_3" className="custom-control-input" />
                                            <label className="custom-control-label label-group" id="attribute_value_label_23" htmlFor="attribute_value23">да</label>
                                        </div>
                                        <UncontrolledCollapse className="p-3" toggler="#toggler8">
                                            <ul className="list-group list_item_block">
                                                <li className="list-group-item"><Link to="/action/99">Компенсация расходов на оплату жилых помещений и коммунальных услуг отдельным категориям граждан </Link></li>
                                                <li className="list-group-item"><Link to="/action/102">Обеспечение жильем отдельных категорий ветеранов, инвалидов и семей, имеющих детей-инвалидов, нуждающихся в улучшении жилищных условий и вставших на учет до 1 января 2005 года, и ветеранов Великой Отечественной войны вне зависимости от даты постановки на учет</Link></li>
                                            </ul>
                                        </UncontrolledCollapse>

                                        <div className="custom-control custom-radio list_item_block" id="toggler9">
                                            <input type="radio" id="attribute_value24" name="attribute_3" className="custom-control-input" />
                                            <label className="custom-control-label label-group" id="attribute_value_label_24" htmlFor="attribute_value24">нет</label>
                                        </div>

                                        <UncontrolledCollapse className="p-3" toggler="#toggler9">
                                            <ul className="list-group list_item_block">
                                                <li className="list-group-item"><Link to="/action/99">Компенсация расходов на оплату жилых помещений и коммунальных услуг отдельным категориям граждан </Link></li>
                                                <li className="list-group-item"><Link to="/action/101">Компенсационные выплаты за пользование услугами местной телефонной связи и (или) за пользование услугами связи для целей проводного радиовещания </Link></li>
                                                <li className="list-group-item"><Link to="/action/102">Обеспечение жильем отдельных категорий ветеранов, инвалидов и семей, имеющих детей-инвалидов, нуждающихся в улучшении жилищных условий и вставших на учет до 1 января 2005 года, и ветеранов Великой Отечественной войны вне зависимости от даты постановки на учет</Link></li>
                                            </ul>
                                        </UncontrolledCollapse>

                                    </div>
                                </div>
                            </div>)
                                : null
                            }

                            {fifth ? (<div className="element_group">
                                <div className="group_string">

                                    <div className="title-group">
                                        <label>Иные лица, имеющие право на меры социальной поддержки</label>
                                    </div>

                                    <div className="element_string attribute ">

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/103">
                                                <input type="radio" id="attribute_value25" name="attribute_4" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_25" htmlFor="attribute_value25">Гражданин, осуществивший погребение реабилитированного лица</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/104">
                                                <input type="radio" id="attribute_value26" name="attribute_4" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_26" htmlFor="attribute_value26">Граждане, награжденные нагрудным знаком «Почетный донор России», а также граждане, награжденные нагрудным знаком «Почетный донор СССР»</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/105">
                                                <input type="radio" id="attribute_value27" name="attribute_4" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_27" htmlFor="attribute_value27">Неработающие собственники жилых помещений, достигшие возраста семидесяти лет, проживающие одиноко либо в семьях, состоящих из граждан достигших возраста семидесяти лет, и (или) инвалидов</label>
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                            </div>)
                                : null
                            }

                        </div>

                    </Col>
                </Row >
            </Container >
        );
    }
}
