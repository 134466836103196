import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                <li className="breadcrumb-item active" aria-current="page"><Link to="/certificateBase" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами</Link></li>
            </ol>
        </nav>

        <div className='white-card'>

            <div className='card-body'>
                <p>Супруга (супруг) погибшего (умершего) инвалида Великой Отечественной войны, инвалида боевых действий, участника Великой Отечественной войны или ветерана боевых действий, вступившие в повторный брак, права на получение удостоверения члена семьи погибшего (умершего), не имеют.</p>
                <p className="mt-3"><Link to="/action/5" style={{ cursor: 'pointer' }}>Общая информация о выдаче удостоверений о праве на льготы членам семей погибших (умерших) инвалидов войны, участников Великой Отечественной войны, ветеранов боевых действий, а также военнослужащих, проходивших военную службу по призыву и погибших при исполнении обязанностей военной службы</Link></p>
            </div>

        </div>

    </Container >)
}

export default Act1;