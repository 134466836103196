import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'

const Act17 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><a href="/">Главная</a></li>
                <li className="breadcrumb-item"><a href="/certificat">Получение удостоверений </a></li>
                <li className="breadcrumb-item active" aria-current="page">«Компенсация расходов на уплату взноса на капитальный ремонт общего имущества в многоквартирном доме отдельным категориям граждан»</li>
            </ol>
        </nav>
        <div className="name">«Компенсация расходов на уплату взноса на капитальный ремонт общего имущества в многоквартирном доме отдельным категориям граждан»</div>

        <div className='white-card'>

            <div className='card-body'>
                <div className="small-title">круг заявителей</div>
                <p>граждане, являющиеся собственниками жилых помещений в многоквартирных домах, используемых в качестве места постоянного проживания, из числа:</p>
                <ul className="text">
                    <li>одиноко проживающих неработающих граждан, достигших возраста семидесяти лет;</li>
                    <li>неработающих граждан, достигших возраста семидесяти лет, проживающих в семьях, состоящих из совместно проживающих неработающих граждан пенсионного возраста, которые достигли возраста семидесяти лет, и (или) инвалидов;</li>
                    <li>одиноко проживающих неработающих граждан, достигших возраста восьмидесяти лет;</li>
                    <li>неработающих граждан, достигших возраста восьмидесяти лет, проживающих в семьях, состоящих из совместно проживающих неработающих граждан пенсионного возраста, которые достигли возраста семидесяти лет, и (или) инвалидов.</li>
                </ul>
                <div className="small-title">срок предоставления государственной услуги</div>
                <ul className="text">
                    <li>Срок предоставления государственной услуги не должен превышать 44 рабочих дней со дня личного обращения заявителя в орган социальной защиты населения либо многофункциональный центр.</li>
                    <li>Срок предоставления государственной услуги при подаче заявления в электронной форме не должен превышать 44 рабочих дней со дня представления заявителем в орган социальной защиты населения документов, предусмотренных Административным регламентом, обязанность по представлению которых возложена на заявителя.</li>
                </ul>
                <div className="small-title">исчерпывающий перечень документов</div>
                <ul className="text">
                    <li>заявление о назначении компенсации расходов с указанием способа ее получения (через кредитную организацию путем зачисления компенсации расходов на счет заявителя, открытый им в кредитной организации, или через организацию почтовой связи);</li>
                    <li>документы, удостоверяющие личность заявителя и совместно проживающих с ним членов семьи;</li>
                    <li>справка о регистрации заявителя и членов его семьи по месту жительства в пределах Российской Федерации, выданная территориальным органом федерального органа исполнительной власти, уполномоченного на осуществление федерального государственного контроля (надзора) в сфере миграции;</li>
                    <li>сведения о трудовой деятельности в отношении гражданина пожилого возраста - в целях подтверждения факта неосуществления гражданином пожилого возраста трудовой деятельности;</li>
                    <li>сведения о трудовой деятельности в отношении совместно проживающих с гражданином пожилого возраста членов его семьи - в целях подтверждения факта неосуществления трудовой деятельности совместно проживающими с гражданином пожилого возраста членами его семьи;</li>
                    <li>документ, подтверждающий право собственности на занимаемое жилое помещение;</li>
                    <li>документы (сведения), подтверждающие факт установления инвалидности (при проживании в семье неработающих граждан, не достигших возраста семидесяти лет, имеющих инвалидность);</li>

                </ul>
                <div className="small-title">результат предоставления государственной услуги</div>
                <ul className="text">
                    <li>Результатом предоставления государственной услуги является компенсация отдельным категориям граждан расходов на уплату взноса на капитальный ремонт общего имущества в многоквартирном доме.</li>
                </ul>
                <div className="small-title">Основания для отказа в приеме документов </div>
                <ul className="text">
                    <li>непредставление либо неполное представление документов, указанных в Административном регламенте, обязанность по представлению которых возложена на гражданина пожилого возраста;</li>
                    <li>непредставление в течение 5 рабочих дней со дня регистрации заявления в органе социальной защиты населения оригиналов документов, обязанность по предоставлению которых возложена на заявителя (при подаче документов посредством заполнения электронной формы запроса с использованием регионального портала).</li>
                </ul>
                <div className="small-title">основания для отказа в предоставлении государственной услуги</div>
                <ul className="text">
                    <li>недостижение установленного возраста;</li>
                    <li>отсутствие права собственности на жилое помещение в многоквартирном доме, используемое в качестве места постоянного проживания на территории Челябинской области;</li>
                    <li>проживание с членами семьи, которые не достигли возраста семидесяти лет и (или) не являются инвалидами;</li>
                    <li>осуществление трудовой деятельности заявителем и членами его семьи;</li>
                    <li>отсутствие начисления взноса на капитальный ремонт общего имущества в многоквартирном доме;</li>
                    <li>отсутствие места постоянного проживания на территории Челябинской области.</li>
                </ul>
                <div className="small-title">право на досудебное (внесудебное) обжалование решений и действий (бездействия) должностных лиц </div>
                <ul className="text">
                    <li>В досудебном (внесудебном) порядке заявители могут обжаловать действия (бездействие) органов социальной защиты населения, Министерства социальных отношений Челябинской области, многофункционального центра, а также их должностных лиц, государственных гражданских служащих, муниципальных служащих, работников и принимаемые ими решения при предоставлении государственной услуги.</li>
                </ul>
                <div className="small-title"><a target="_blank" href="http://minsoc74.ru/sites/default/files/17.docx">Форма заявления</a></div>
            </div>

        </div>

    </Container >)
}

export default Act17;