import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                <li className="breadcrumb-item active" aria-current="page"><Link to="/certificateBase" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами</Link></li>
            </ol>
        </nav>

        <div className='white-card'>

            <div className='card-body'>
                <p><a target="_blank" href="http://minsoc74.ru/sites/default/files/5_zayavlenie_chsp.doc ">Шаблон заявления</a></p>

                <p className="font-weight-bold">Разъяснения по заполнению заявления</p>
                <p>В шапке заявления указываются:
                фамилия, имя, отчество заявителя, дата рождения; адрес регистрации по месту жительства (указываются индекс, населенный пункт, улица, номер дома, номер квартиры (при наличии)), серия и номер, дата выдачи и наименование органа, выдавшего паспорт, реквизиты пенсионного удостоверения либо информация об органе, осуществляющем пенсионное обеспечение (для граждан, являющихся получателями пенсии), номер телефона.</p>
                <p>Текст заявления стандартный для всех (заполнен).</p>
                <p>Далее указываются сведения (наименование документа, его реквизиты, наименование органа, выдавшего документ) обо всех документах, прилагаемых к заявлению, то есть о документах, являющихся основанием для выдачи удостоверения члена семьи погибшего (умершего).</p>

                <p>*Органами социальной защиты населения в порядке межведомственного электронного взаимодействия могут быть запрошены следующие виды сведений и документов:</p>
                <p>документы, подтверждающие родственное отношение к погибшему (умершему) ветерану;</p>
                <p>справка о получении пенсии;</p>
                <p>справка о регистрации заявителя и членов его семьи по месту жительства в пределах Российской Федерации;</p>
                <p>документы (сведения) о рождении ребенка (детей);</p>
                <p>документы (сведения) об установлении ребенку (детям) инвалидности до достижения им (ими) возраста 18 лет (при наличии сведений об инвалидности в федеральной государственной информационной системе «Федеральный реестр инвалидов». При отсутствии сведений в названной информационной системе заявитель самостоятельно представляет документы об инвалидности) .</p>
                <p>Заявитель вправе самостоятельно по собственной инициативе представить указанные документы.</p>
                <p>Далее указываются сведения о членах семьи, совместно проживающих с заявителем: количество, фамилия, имя, отчество, дата рождения и степень родства (мать, отец, сын, дочь, брат и так далее).</p>
                <p>Далее указывается дата подачи заявления.</p>

                <br/>
                <div className="small-title">Способ подачи заявления о выдаче удостоверения члена семьи погибшего (умершего)</div>
                <ul className="text">
                    <li>при обращении в органы социальной защиты населения по месту жительства  
                        <a target="_blank" href="http://minsoc74.ru/municipalnye-upravleniya-i-uchrezhdeniya">
                            Приложение 1 к Административному регламенту</a></li>
                    <li>при обращении в многофункциональные центры предоставления государственных и муниципальных услуг
                        <a target="_blank" href="https://mfc-74.ru">
                    Приложение 3 к Административному регламенту</a></li>
                    <li>посредством почтового отправления с описью вложения и уведомлением о вручении</li>
                </ul>


                <div className="small-title">Способ получения результата рассмотрения заявления о выдаче удостоверения члена семьи погибшего (умершего)</div>
                <ul className="text">
                    <li>при вынесении решения о выдаче удостоверения члена семьи погибшего (умершего) удостоверение может быть получено в органах социальной защиты населения по месту жительства </li>
                    <li>решение об отказе в выдаче удостоверения члена семьи погибшего (умершего) направляется заявителю на домашний адрес почтовым отправлением</li>
                </ul>

                <p className="mt-3"><Link to="/action/5" style={{ cursor: 'pointer' }}>Общая информация о выдаче удостоверений о праве на льготы членам семей погибших (умерших) инвалидов войны, участников Великой Отечественной войны, ветеранов боевых действий, а также военнослужащих, проходивших военную службу по призыву и погибших при исполнении обязанностей военной службы</Link></p>

            </div>

        </div>

    </Container >)
}

export default Act1;