import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                <li className="breadcrumb-item" aria-current="page"><Link to="/certificateBase" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами</Link></li>
            </ol>

        </nav>

        <div className='white-card'>

            <div className='card-body'>
                <div className="small-title">круг заявителей</div>
                <ul className="text">
                    <li>граждане Российской Федерации, постоянно проживающие на территории Челябинской области награжденные орденами или медалями СССР или Российской Федерации, либо удостоенные почетных званий СССР или Российской Федерации, либо награжденные почетными грамотами Президента Российской Федерации или удостоенные благодарности Президента Российской Федерации, либо награжденные ведомственными знаками отличия за заслуги в труде (службе) и продолжительную работу (службу) не менее 15 лет в соответствующей сфере деятельности (отрасли экономики) и имеющие трудовой (страховой) стаж, учитываемый для назначения пенсии, не менее 25 лет для мужчин и 20 лет для женщин или выслугу лет, необходимую для назначения пенсии за выслугу лет в календарном исчислении;</li>
                    <li>граждане Российской Федерации, постоянно проживающие на территории Челябинской области начавшие трудовую деятельность в несовершеннолетнем возрасте в период Великой Отечественной войны и имеющие трудовой (страховой) стаж не менее 40 лет для мужчин и 35 лет для женщин.</li>
                </ul>

                <a>Ссылка на пункт 4 раздела 1 Административного регламента</a>

            </div>

        </div>

    </Container >)
}

export default Act1;