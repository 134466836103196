import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css';
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                <li className="breadcrumb-item active" aria-current="page"><Link to="/certificateRadiation" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами, пострадавшими от воздействия радиации</Link></li>
            </ol>
        </nav>
        <div className="name">Пострадали от ВР вследствие аварии на ПО «Маяк»</div>

        <div className='white-card'>

            <div className='card-body'>
                <text>Если Вы пострадали от ВР вследствие аварии на ПО «Маяк» и проживаете (-ли) на загрязенной территории</text>
                <div className="small-title">Перечень документов:</div>
                <ul className="text">
                    <li>Паспорт РФ;</li>
                    <li>Заявление и согласие на обработку персональных данных;</li>
                    <li>Документы, подтверждающие факт проживания в населенных пунктах, входящих в Перечень населенных пунктов, эвакуации (переселения) и добровольного выезда из них - акты об отселении, акты оценки материального ущерба, выписки из домовых книг, справки, выданные архивами отделов народного образования, социальной защиты, здравоохранения, архивные документы ПО «Маяк», Уральского научно-практического центра радиационной медицины (УНПЦРМ);</li>
                    <li>Фото</li>

                </ul>
                <text>Если Вы принимали участие в ликвидации последствий  аварии на ПО Маяк</text>
                <div className="small-title">Перечень документов:</div>
                <ul className="text">
                    <li>Паспорт РФ;</li>
                    <li>Заявление согласие на обработку персональных данных;</li>
                    <li>Документ, подтверждающий участие граждан в работах по ликвидации:</li>
                    <li>командировочное удостоверение, выписка из приказа, справка, выданная предприятиями, организациями, учреждениями (или их правопреемниками), выполнявшими непосредственно работы на радиоактивно загрязненных территориях, с обязательным указанием периода выполнения этих работ и основания выдачи; выписка из архивных материалов ПО «Маяк», Южно-Уральского управления строительства и иных организаций;</li>
                    <li>Фото.</li>

                </ul>
            </div>

        </div>

    </Container >)
}

export default Act1;