import React, { useState } from 'react';
import { Container, Form, FormGroup, Label, Input, Col, Row, Table, Button } from 'reactstrap';
import './Action.css'

const Act23 = () => {
    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><a href="/">Главная</a></li>
                <li className="breadcrumb-item"><a href="/socialservices">Меры социальной поддержки детей-сирот и детей, оставшихся без попечения родителей</a></li>
            </ol>
        </nav>
        <div className="name">Сохранение полного государственного обеспечения и выплата государственной социальной стипендии</div>

        <div className='white-card'>

            <div className='card-body'>
                Сохранение полного государственного обеспечения и выплата государственной социальной стипендии (при предоставлении детям-сиротам и детям, оставшимся без попечения родителей, лицам из их числа, лицам, потерявшим в период обучения обоих родителей или единственного родителя, обучающимся по основным профессиональным образовательным программам в областных государственных профессиональных образовательных организациях, областных государственных образовательных организациях высшего образования по очной форме обучения за счет средств областного бюджета и находящимся на полном государственном обеспечении, академического отпуска по медицинским показаниям, отпуска по беременности и родам, отпуска по уходу за ребенком до достижения им возраста трех лет на весь период данных отпусков).
            </div>

        </div>

    </Container>)
}

export default Act23;