import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import {
    Link
} from 'react-router-dom';


export class CertificateChsp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            first: true,
            second: false,
            third: false,
            fourth: false,
            fifth: false,
            sixth: false
        };
    }

    render() {
        const { first, second, third, fourth, fifth, sixth } = this.state;
        return (
            <Container>
                <Row>
                    <Col md='12' lg='12'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb pl-0">
                                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"><Link to="/certificateBase" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами</Link></li>
                            </ol>
                        </nav>
                    </Col>
                </Row>
                <Row>
                    <Col md='12' lg='12' className='mb-3'>
                        <div className='head-list-child mt-30'>
                            <div className='title-head-list title-head-list-child'>Получение удостоверения о праве на льготы членам семей погибших (умерших) инвалидов войны, участников Великой Отечественной войны, ветеранов боевых  действий, а также военнослужащих, проходивших военную службу по призыву и погибших при исполнении обязанностей военной службы</div>
                        </div>
                        <div className='white-box-child last pt-0'>


                            {first ? (<div className="element_group">
                                <div className="group_string">

                                    <div className="title-group">
                                        <label>Наличие постоянной регистрации по месту жительства на территории Челябинской области</label>
                                    </div>

                                    <div className="element_string attribute ">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value0" name="attribute_0" className="custom-control-input" onChange={() => this.setState({ first: false, second: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_0" htmlFor="attribute_value0">да</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/73">
                                                <input type="radio" id="attribute_value1" name="attribute_0" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_1" htmlFor="attribute_value1">нет</label>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null
                            }


                            {second ? (<div className="element_group">
                                <div className="group_string">

                                    <div className="title-group">
                                        <label>К какой категории членов семей погибших (умерших) Вы относитесь?</label>
                                    </div>

                                    <div className="element_string attribute">
                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/87">
                                                <input type="radio" id="attribute_value4" name="attribute_2" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_4" htmlFor="attribute_value4">мать (отец) погибшего (умершего) инвалида Великой Отечественной войны, инвалида боевых действий, участника Великой Отечественной войны или ветерана боевых действий </label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value5" name="attribute_2" className="custom-control-input" onChange={() => this.setState({ second: false, third: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_5" htmlFor="attribute_value5">супруга (супруг) погибшего (умершего) инвалида Великой Отечественной войны или инвалида боевых действий</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value6" name="attribute_2" className="custom-control-input" onChange={() => this.setState({ second: false, third: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_6" htmlFor="attribute_value6">супруга (супруг) погибшего (умершего) участника Великой Отечественной войны </label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value7" name="attribute_2" className="custom-control-input" onChange={() => this.setState({ second: false, fourth: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_7" htmlFor="attribute_value7">супруга (супруг) погибшего (умершего) ветерана боевых действий</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value07" name="attribute_2" className="custom-control-input" onChange={() => this.setState({ second: false, sixth: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_07" htmlFor="attribute_value07">ребенок погибшего (умершего) инвалида Великой Отечественной войны, инвалида боевых действий, участника Великой Отечественной войны или ветерана боевых действий </label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value007" name="attribute_2" className="custom-control-input" onChange={() => this.setState({ second: false, sixth: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_007" htmlFor="attribute_value007">мать (отец), супруга (супруг), дети военнослужащего, проходившего военную службу по призыву и погибшего при исполнении обязанностей военной службы (служебных обязанностей) </label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value0007" name="attribute_2" className="custom-control-input" onChange={() => this.setState({ second: false, sixth: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_0007" htmlFor="attribute_value0007">нетрудоспособные члены семей погибших (умерших) инвалидов Великой Отечественной войны, инвалидов боевых действий, участников Великой Отечественной войны, ветеранов боевых действий и военнослужащих, проходивших военную службу по призыву и погибших при исполнении обязанностей военной службы </label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/5">
                                                <input type="radio" id="attribute_value8" name="attribute_2" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_8" htmlFor="attribute_value8">ни одна из указанных категорий </label>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null
                            }

                            {third ? (<div className="element_group">
                                <div className="group_string">

                                    <div className="title-group">
                                        <label>Вступали ли Вы в повторный брак?</label>
                                    </div>

                                    <div className="element_string attribute ">
                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/88">
                                                <input type="radio" id="attribute_value9" name="attribute_3" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_9" htmlFor="attribute_value9">да</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/87">
                                                <input type="radio" id="attribute_value10" name="attribute_3" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_10" htmlFor="attribute_value10">нет</label>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null
                            }

                            {fourth ? (<div className="element_group">
                                <div className="group_string">

                                    <div className="title-group">
                                        <label>Вступали ли Вы в повторный брак?</label>
                                    </div>

                                    <div className="element_string attribute ">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value11" name="attribute_4" className="custom-control-input" onChange={() => this.setState({ fourth: false, fifth: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_11" htmlFor="attribute_value11">нет</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/88">
                                                <input type="radio" id="attribute_value12" name="attribute_4" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_12" htmlFor="attribute_value12">да</label>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null
                            }


                            {fifth ? (<div className="element_group">
                                <div className="group_string">

                                    <div className="title-group">
                                        <label>Отметьте наличие у Вас одного из следующих оснований:</label>
                                    </div>

                                    <div className="element_string attribute ">
                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/87">
                                                <input type="radio" id="attribute_value13" name="attribute_5" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_13" htmlFor="attribute_value13">проживаю одиноко (зарегистрирован(а) по месту жительства один(а))</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/87">
                                                <input type="radio" id="attribute_value14" name="attribute_5" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_14" htmlFor="attribute_value14">проживаю с несовершеннолетним ребенком (детьми) </label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/87">
                                                <input type="radio" id="attribute_value15" name="attribute_5" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_15" htmlFor="attribute_value15">проживаю с ребенком (детьми), ставшим инвалидом (инвалидами) до достижения им (ими) возраста 18 лет </label>
                                            </Link>
                                        </div>
                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/87">
                                                <input type="radio" id="attribute_value16" name="attribute_5" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_16" htmlFor="attribute_value16">проживаю ребенком (детьми), не достигшим (не достигшими) возраста 23 лет и обучающимся (обучающимися) в образовательных организациях по очной форме обучения </label>
                                            </Link>
                                        </div>
                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/87">
                                                <input type="radio" id="attribute_value17" name="attribute_5" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_17" htmlFor="attribute_value17">получаю пенсию по случаю потери кормильца в Пенсионном фонде Российской Федерации</label>
                                            </Link>
                                        </div>
                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/89">
                                                <input type="radio" id="attribute_value18" name="attribute_5" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_18" htmlFor="attribute_value18">ни одно из указанных оснований</label>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null
                            }

                            {sixth ? (<div className="element_group">
                                <div className="group_string">

                                    <div className="title-group">
                                        <label>Получаете ли Вы пенсию по случаю потери кормильца в Пенсионном фонде Российской Федерации?</label>
                                    </div>

                                    <div className="element_string attribute ">
                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/87">
                                                <input type="radio" id="attribute_value22" name="attribute_6" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_22" htmlFor="attribute_value22">да</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/90">
                                                <input type="radio" id="attribute_value23" name="attribute_6" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_23" htmlFor="attribute_value23">нет</label>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null
                            }

                        </div>

                    </Col>
                </Row >
            </Container >
        );
    }
}
