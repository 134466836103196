import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item active" aria-current="page"><Link to="/health" style={{ cursor: 'pointer' }}>Отдых и оздоровление детей</Link></li>
            </ol>
        </nav>

        <div className='white-card'>

            <div className='card-body'>
                <p>Бесплатные путевки в загородные лагеря отдыха и оздоровления детей предоставляются детям школьного возраста до достижения ими 18 лет, находящимся в трудной жизненной ситуации. Выдача путевок осуществляется органами социальной защиты населения муниципальных районов и городских округов Челябинской области по месту жительства заявителя. Путевки на отдых и оздоровление предоставляются гражданам в порядке очередности. </p>
                <a>Постановление Правительства Челябинской области от 14 апреля 2010 г. № 131-П «О Положении о порядке предоставления путевок в загородные лагеря отдыха и оздоровления детей детям, находящимся в трудной жизненной ситуации, и о порядке расходования средств областного бюджета на указанные цели»</a>
            </div>

        </div>

    </Container >)
}

export default Act1;