import React, { useState } from 'react';
import { Container, Form, FormGroup, Label, Input, Col, Row, Table,Button } from 'reactstrap';
import './Action.css'

const Act23 = () => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><a href="/">Главная</a></li>
                <li className="breadcrumb-item"><a href="/socialservices">Социальное обслуживание граждан</a></li>
                <li className="breadcrumb-item active" aria-current="page">Предоставление социальных услуг поставщиками социальных услуг в форме социального обслуживания на дому</li>
            </ol>
        </nav>
        <div className="name">Предоставление социальных услуг поставщиками социальных услуг в форме социального обслуживания на дому</div>

        <div className='white-card'>

            <div className='card-body'>
                <text><a target="_blank" href="https://minsoc.gov74.ru/files/upload/minsoc/%D0%94%D0%B5%D1%8F%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D1%8C/adm_reglament.docx">Административный регламент </a> </text>
                <div className="small-title">Категория населения, являющаяся получателем государственной услуги</div>
                <text>К обстоятельствам, ухудшающим или способным ухудшить условия жизнедеятельности граждан, при которых гражданину предоставляются социальные услуги на дому, относятся:</text>
                <ul className="text">
                    <li> полная или частичная утрата способности либо возможности осуществлять самообслуживание и (или) самостоятельно передвигаться, обеспечивать основные жизненные потребности в силу заболевания, травмы, возраста или наличия инвалидности в случаях отсутствия необходимости в стационарном социальном обслуживании. Критерии выбора указанных форм социального обслуживания устанавливаются Министерством социальных отношений Челябинской области;</li>
                    <li> наличие в семье инвалида, в том числе ребенка-инвалида, нуждающегося в постоянном постороннем уходе.</li>
                </ul>
                <div className="small-title">Перечень документов, необходимых для предоставления государственной услуги</div>
                <ul className="text">
                    <li>заявление гражданина (его законного представителя) по форме, установленной Министерством труда и социальной защиты Российской Федерации;</li>
                    <li>копию документа, удостоверяющего личность заявителя (его законного представителя);</li>
                    <li>документ, выданный уполномоченным органом (организацией), о регистрации заявителя, супруга (супруги) заявителя и членов семьи заявителя по месту жительства (месту пребывания) на территории Челябинской области, подтверждающего их совместное проживание;</li>
                    <li>заключение врачебной комиссии медицинской организации о степени утраты способности заявителя к самообслуживанию и (или) самостоятельному передвижению и об отсутствии противопоказаний к предоставлению социальных услуг на дому (для граждан, не являющихся инвалидами);</li>
                    <li>копию документа, подтверждающего факт установления инвалидности заявителя, выданного федеральным государственным учреждением медико-социальной экспертизы, либо копию документа, подтверждающего факт установления инвалидности заявителя, выданного врачебно-трудовой экспертной комиссией (для заявителей, являющихся инвалидами), и копию индивидуальной программы реабилитации заявителя, выданной федеральным государственным учреждением медико-социальной экспертизы (при наличии);</li>
                    <li>копии документов, подтверждающих статус лица, имеющего право на внеочередное или преимущественное получение социальных услуг на дому в соответствии с действующим законодательством;</li>
                    <li>документы, подтверждающие среднедушевой доход заявителя, за 12 месяцев, предшествующих дате обращения за получением социальных услуг;</li>
                    <li>копию документа, подтверждающего регистрацию в системе индивидуального (персонифицированного) учета</li>
                </ul>
                <div className="small-title">Место получения государственной услуги, адрес, контактный телефон</div>
                <ul className="text">
                    <li>Муниципальные учреждения Комплексные центры социального обслуживания населения муниципальных образований Челябинской области <a target="_blank" href="http://rpost.minsoc74.ru/rpost/reestr_last.html?PageSize=10&Sort=0&PageNumber=1">реестр поставщиков социальных услуг </a></li>
                </ul>
                <div className="small-title">Сроки оказания государственной услуги</div>
                <ul className="text">
                    <li>Датой подачи заявления на получение социальных услуг считается день подачи заявления и всех необходимых документов.
                    Решение о признании гражданина нуждающимся в социальном обслуживании принимается в течение 5 рабочих дней с даты подачи заявления.
                    В случае признания гражданина нуждающимся в социальном облуживании формируется индивидуальная программа предоставления социальных услуг по форме, установленной Министерством труда и социальной защиты Российской Федерации, в двух экземплярах. Один экземпляр индивидуальной программы, подписанный уполномоченным органом, передается гражданину или его законному представителю в срок не более чем десять рабочих дней с даты подачи гражданином заявления. Второй экземпляр индивидуальной программы остается в уполномоченном органе.
</li>
                </ul>
            </div>

        </div>

    </Container >)
}

export default Act23;