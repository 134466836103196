import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                <li className="breadcrumb-item active" aria-current="page"><Link to="/certificateBase" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами</Link></li>
            </ol>
        </nav>

        <div className='white-card'>

            <div className='card-body'>
                <p>Гражданам, не имеющим постоянной регистрации по месту жительства на территории Челябинской области, удостоверение члена семьи погибшего (умершего) инвалида войны, участника Великой Отечественной войны, ветерана боевых действий либо военнослужащего, проходившего военную службу по призыву и погибшего при исполнении обязанностей военной службы (далее – удостоверение члена семьи погибшего (умершего), не выдается.</p>
                <a target="_blank" href="https://pravmin74.ru/normativnye-pravovye-akty/postanovleniya-pravitelstva/postanovlenie-pravitelstva-ot-16-noyabrya-20-2 "> Пункт 4 раздела I Административного регламента предоставления государственной услуги «Выдача удостоверений о праве на льготы членам семей погибших (умерших) инвалидов войны, участников Великой Отечественной войны, ветеранов боевых действий, а также военнослужащих, проходивших военную службу по призыву и погибших при исполнении обязанностей военной службы», утвержденного постановлением Правительства Челябинской области от 16.11.2011 г. № 425-П (далее – Административный регламент)</a>
                <p className="mt-3"><Link to="/action/5" style={{ cursor: 'pointer' }}>Общая информация о выдаче удостоверений о праве на льготы членам семей погибших (умерших) инвалидов войны, участников Великой Отечественной войны, ветеранов боевых действий, а также военнослужащих, проходивших военную службу по призыву и погибших при исполнении обязанностей военной службы</Link></p>
            </div>

        </div>

    </Container >)
}

export default Act1;