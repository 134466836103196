import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                <li className="breadcrumb-item active" aria-current="page"><Link to="/certificateBase" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами</Link></li>
            </ol>
        </nav>

        <div className='white-card'>

            <div className='card-body'>
                <p><a target="_blank" href="http://minsoc74.ru/sites/default/files/3_zayavlenie_vov.doc">Шаблон заявления</a></p>
                <p>Разъяснения по заполнению заявления</p>
                <p>В шапке заявления указываются:
                фамилия, имя, отчество заявителя, дата рождения; адрес регистрации по месту жительства (указываются индекс, населенный пункт, улица, номер дома, номер квартиры (при наличии)), серия и номер, дата выдачи и наименование органа, выдавшего паспорт гражданина Российской Федерации (иного документа, удостоверяющего личность), реквизиты пенсионного удостоверения (либо информация об органе, осуществляющем пенсионное обеспечение).
</p>
                <p>Текст заявления стандартный для всех (заполнен).</p>
                <div className="small-title">Далее указываются наименования документов, прилагаемых к заявлению:</div>
                <ul className="text">
                    <li>Сведения о ранее выданном удостоверении.
                    Указываются наименование и реквизиты ранее выданного удостоверения ветерана Великой Отечественной войны либо удостоверений о праве на льготы, образцы которых утверждены до 1 января 1992 года, наименование органа, выдавшего удостоверение. Далее указывается номер статьи Федерального закона «О ветеранах», согласно записям в удостоверении, в соответствии с которой гражданин имеет право на льготы.
                    В случае, если ранее удостоверение ветерана Великой Отечественной войны или иное удостоверение о праве на льготы не выдавалось, указывается причина. Например:
                    Удостоверение «ветерана Великой Отечественной войны не получал, не обращался»;
                    Удостоверение «не получал, не имел права».
                    </li>
                    <li>Далее указаны сведения о приложении фотографии размером 3 см х 4 см (заполнены).</li>
                    <li>Далее указываются сведения о других документах, прилагаемых к заявлению и подтверждающих основания для выдачи удостоверения ветерана Великой Отечественной войны.</li>
                </ul>

                <p>Основания для выдачи удостоверения ветерана Великой Отечественной войны определены для каждой категории ветеранов Инструкцией (ссылка) о порядке и условиях реализации прав и льгот ветеранов Великой Отечественной войны, ветеранов боевых действий, иных категорий граждан, установленных Федеральным законом (ссылка) «О ветеранах», утвержденной постановлением (ссылка) Министерства труда и социального развития Российской Федерации от 11 октября 2000 г. № 69.</p>
                <p>*Справка о пенсионном обеспечении может быть запрошена органами социальной защиты населения в порядке межведомственного электронного взаимодействия.</p>
                <p>Заявитель вправе самостоятельно по собственной инициативе представить документы о пенсионном обеспечении.</p>
                <p>Далее указываются сведения о факте получения мер социальной поддержки по иным основаниям.</p>
                <p>Далее указывается количество совместно проживающих членов семьи.</p>
                <p>Далее указывается дата подачи заявления.</p>
                <a>Ссылка на образец заполнения заявления о выдаче удостоверения ветерана Великой Отечественной войны</a>

                <div className="small-title">Способ подачи заявления о выдаче удостоверения ветерана Великой Отечественной войны</div>
                <ul className="text">
                    <li>при обращении в органы социальной защиты населения по месту жительства
                    Ссылка на приложение 1 к Административному регламенту</li>
                    <li>при обращении в многофункциональные центры предоставления государственных и муниципальных услуг
                        Ссылка ни приложение 3 к Административному регламенту</li>
                    <li>посредством почтового отправления с описью вложения и уведомлением о вручении</li>
                </ul>


                <div className="small-title">Способ получения результата рассмотрения заявления о выдаче удостоверения ветерана Великой Отечественной войны</div>
                <ul className="text">
                    <li>при вынесении решения о выдаче удостоверения ветерана Великой Отечественной войны удостоверение может быть получено в органах социальной защиты населения по месту жительства 
                    Ссылка ни приложение 1 к Административному регламенту 
</li>
                    <li>решение об отказе в выдаче удостоверения ветерана Великой Отечественной войны направляется заявителю на домашний адрес почтовым отправлением</li>
                </ul>

                <p><Link to="/action/3" style={{ cursor: 'pointer' }}>Общая информация о выдаче удостоверения ветерана Великой Отечественной войны</Link></p>
            </div>

        </div>

    </Container >)
}

export default Act1;