import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import {
    Link
} from 'react-router-dom';


export class CertificateIwow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            first: true,
            second: false,
            third: false
        };
    }


    render() {
        const { first, second, third } = this.state;
        return (
            <Container>
                <Row>
                    <Col md='12' lg='12'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb pl-0">
                                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"><Link to="/certificateBase" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами</Link></li>
                            </ol>
                        </nav>
                    </Col>
                </Row>
                <Row>
                    <Col md='12' lg='12' className='mb-3'>
                        <div className='head-list-child mt-30'>
                            <div className='title-head-list title-head-list-child'>Получение удостоверения инвалида Великой Отечественной войны или удостоверения инвалида о праве на льготы</div>
                        </div>
                        <div className='white-box-child last pt-0'>


                            {first ? (<div className="element_group">
                                <div className="group_string">

                                    <div className="title-group">
                                        <label>Наличие постоянной регистрации по месту жительства на территории Челябинской области</label>
                                    </div>

                                    <div className="element_string attribute ">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value0" name="attribute_0" className="custom-control-input" onChange={() => this.setState({ first: false, second: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_0" htmlFor="attribute_value0">да</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/82">
                                                <input type="radio" id="attribute_value1" name="attribute_0" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_1" htmlFor="attribute_value1">нет</label>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null
                            }


                            {second ? (<div className="element_group">
                                <div className="group_string">

                                    <div className="title-group">
                                        <label>Являетесь ли Вы получателем пенсии в Пенсионном фонде Российской Федерации</label>
                                    </div>

                                    <div className="element_string attribute ">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value9" name="attribute_3" className="custom-control-input" onChange={() => this.setState({ second: false, third: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_9" htmlFor="attribute_value9">да</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/83">
                                                <input type="radio" id="attribute_value10" name="attribute_3" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_10" htmlFor="attribute_value10">нет</label>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null
                            }

                            {third ? (<div className="element_group">
                                <div className="group_string">

                                    <div className="title-group">
                                        <label>К какой категории граждан Вы относитесь?</label>
                                    </div>

                                    <div className="element_string attribute">
                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/84">
                                                <input type="radio" id="attribute_value4" name="attribute_2" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_4" htmlFor="attribute_value4">инвалид Великой Отечественной войны из числа лиц, указанных в  <a target="_blank" href="http://www.kremlin.ru/acts/bank/7432/page/1">статье 4  Федерального закона «О ветеранах»</a>  </label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/84">
                                                <input type="radio" id="attribute_value5" name="attribute_2" className="custom-control-input" />
                                            </Link>
                                            <label className="custom-control-label label-group" id="attribute_value_label_5" htmlFor="attribute_value5">инвалид боевых действий из числа лиц, указанных в статье 4 (ссылка) Федерального закона «О ветеранах»</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/84">
                                                <input type="radio" id="attribute_value6" name="attribute_2" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_6" htmlFor="attribute_value6">  военнослужащие, проходившие военную службу по призыву, ставшие инвалидами вследствие ранения, контузии или увечья, полученных при исполнении обязанностей военной службы </label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/85">
                                                <input type="radio" id="attribute_value8" name="attribute_2" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_8" htmlFor="attribute_value8">ни одна из указанных категорий </label>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null
                            }

                        </div>

                    </Col>
                </Row >
            </Container >
        );
    }
}
