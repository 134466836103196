import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item active"><Link to="/measure" style={{ cursor: 'pointer' }}>Меры социальной поддержки отдельным категориям граждан</Link></li>
            </ol>

        </nav>
        <div className="name">«Обеспечение жильем отдельных категорий ветеранов, инвалидов и семей, имеющих детей-инвалидов, нуждающихся в улучшении жилищных условий и вставших на учет до 1 января 2005 года, и ветеранов Великой Отечественной войны вне зависимости от даты постановки на учет»</div>

        <div className='white-card'>

            <div className='card-body'>
                <div className="small-title">К числу лиц, имеющих право на обеспечение жилым помещением за счет федерального бюджета, вставших на учет в качестве нуждающихся в улучшении жилищных условий до 1 января 2005 года, относятся</div>
                <ul className="text">
                    <li>Инвалиды и семьи, имеющие детей-инвалидов</li>
                    <li>Инвалиды боевых действий, а также военнослужащие и лица рядового и начальствующего состава органов внутренних дел, Государственной противопожарной службы, учреждений и органов уголовно-исполнительной системы, ставшие инвалидами вследствие ранения, контузии или увечья, полученных при исполнении обязанностей военной службы (служебных обязанностей)</li>
                    <li>Члены семей погибших (умерших) ветеранов боевых действий, а также члены семей военнослужащих, лиц рядового и начальствующего состава органов внутренних дел, Государственной противопожарной службы, учреждений и органов уголовно-исполнительной системы и органов государственной безопасности, погибших при исполнении обязанностей военной службы (служебных обязанностей):</li>
                    <li>Ветераны боевых действий из числа лиц, указанных в подпунктах 1 - 4 пункта 1 статьи 3 Федерального закона от 12 января 1995 года № 5-ФЗ «О ветеранах».</li>
                </ul>
                <div className="small-title">К числу лиц, имеющих право на обеспечение жилым помещением за счет федерального бюджета независимо от даты постановки на учет в качестве нуждающихся в улучшении жилищных условий, относятся:</div>
                <ul className="text">
                    <li>инвалиды Великой Отечественной войны;</li>
                    <li>участники Великой Отечественной войны, в том числе военнослужащие, проходившие военную службу в воинских частях, учреждениях, военно-учебных заведениях, не входивших в состав действующей армии, в период с 22 июня 1941 года по 3 сентября 1945 года не менее шести месяцев, военнослужащие, награжденные орденами или медалями СССР за службу в указанный период;</li>
                    <li>лица, награжденные знаком «Жителю блокадного Ленинграда»;</li>
                    <li>лица, работавшие в период Великой Отечественной войны на объектах противовоздушной обороны, местной противовоздушной обороны, строительстве оборонительных сооружений, военно-морских баз, аэродромов и других военных объектов в пределах тыловых границ действующих фронтов, операционных зон действующих флотов, на прифронтовых участках железных и автомобильных дорог, а также члены экипажей судов транспортного флота, интернированных в начале Великой Отечественной войны в портах других государств;</li>
                    <li>члены семей погибших (умерших) инвалидов Великой Отечественной войны и участников Великой Отечественной войны, члены семей погибших в Великой Отечественной войне лиц из числа личного состава групп самозащиты объектовых и аварийных команд местной противовоздушной обороны, а также члены семей погибших работников госпиталей и больниц города Ленинграда.</li>
                </ul>

                <div className="small-title">срок предоставления государственной услуги</div>
                <ul className="text">
                    <li>при обеспечении заявителя жилым помещением в форме жилищной субсидии - не позднее 15 декабря года, в котором выдано уведомление о предоставлении жилищной субсидии</li>
                    <li>при обеспечении заявителя жилым помещением в форме единовременной денежной выплаты - 2 года со дня перечисления единовременной денежной выплаты.</li>
                </ul>

                <div className="small-title">исчерпывающий перечень документов</div>
                <ul className="text">
                    <li>заявление;</li>
                    <li>копии документов, удостоверяющих личность гражданина и членов его семьи;</li>
                    <li>копии документов, удостоверяющих право на меры социальной поддержки;</li>
                    <li>копия документа (сведения), подтверждающего регистрацию в системе индивидуального (персонифицированного) учета в системе обязательного пенсионного страхования;</li>
                    <li>копия документа (сведения), содержащего информацию о фамилии гражданина, имеющего право на меры социальной поддержки, которая была у него при рождении;</li>
                    <li>выписка из решения органа по учету и распределению жилых помещений о постановке на учет в качестве нуждающегося в улучшении жилищных условий;</li>
                    <li>копии документов, содержащих сведения о составе семьи гражданина и степени родства ее членов (свидетельство о рождении, свидетельство о заключении брака, решение об усыновлении (удочерении), судебное решение о признании членом семьи);</li>
                    <li>справки о регистрации гражданина и членов его семьи по месту жительства в пределах Российской Федерации, выданные территориальным органом федерального органа исполнительной власти, уполномоченного на осуществление федерального государственного контроля (надзора) в сфере миграции;</li>
                    <li>документ органа, осуществляющего государственную регистрацию прав на недвижимое имущество и сделок с ним, о наличии (отсутствии) жилых помещений на праве собственности у гражданина, имеющего право на меры социальной поддержки, и членов его семьи;</li>
                    <li>документ органа (организации) технической инвентаризации о наличии (отсутствии) жилого помещения на праве собственности у гражданина, имеющего право на меры социальной поддержки, и членов его семьи;</li>
                    <li>правоустанавливающие документы на занимаемое гражданином ранее и (или) в настоящее время жилое помещение;</li>
                    <li>реквизиты счета, открытого в кредитной организации, - для ветеранов, претендующих на получение единовременной денежной выплаты;</li>
                    <li>справка установленной формы о наличии у инвалида тяжелой формы хронического заболевания, при котором невозможно совместное проживание граждан в одной квартире, - для инвалидов, претендующих на получение субсидии во внеочередном порядке;</li>
                    <li>сведения об ознакомлении с нормами, содержащими информацию о том, на какие цели не может быть направлена субсидия или единовременная денежная выплата.</li>
                </ul>

                <p>Документы могут быть представлены в копиях, заверенных в установленном законодательством порядке либо заверенных органами местного самоуправления, после их сверки с подлинниками документов.</p>

                <div className="small-title">результат предоставления государственной услуги</div>
                <ul className="text">
                    <li>выделение жилищной субсидии на приобретение жилого помещения в собственность;</li>
                    <li>предоставление единовременной денежной выплаты на строительство или приобретение жилого помещения.</li>

                </ul>
                <div className="small-title">Основания для отказа в приеме документов </div>
                <ul className="text">
                    <li>неполное представление или отсутствие документов, установленных Административным регламентом, обязанность по представлению которых возложена на заявителя;</li>
                </ul>

                <div className="small-title">основания для отказа в предоставлении государственной услуги</div>
                <ul className="text">
                    <li>в предоставлении государственной услуги отказывается в случае, если заявитель не относится к категориям заявителей, указанным в пункте 5 Административного регламента, и не имеет права на меры социальной поддержки по обеспечению жильем.</li>
                </ul>
                <div className="small-title">право на досудебное (внесудебное) обжалование решений и действий (бездействия) должностных лиц </div>
                <ul className="text">
                    <li>В досудебном (внесудебном) порядке заявители могут обжаловать действия (бездействие) органов социальной защиты населения, Министерства социальных отношений Челябинской области, многофункционального центра, а также их должностных лиц, государственных гражданских служащих, муниципальных служащих, работников и принимаемые ими решения при предоставлении государственной услуги.</li>
                    <li>Жалоба на нарушение порядка предоставления государственной услуги - требование заявителя или его законного представителя о восстановлении или защите нарушенных прав или законных интересов заявителя, Министерством социальных отношений Челябинской области, органом местного самоуправления, должностными лицами Министерства социальных отношений Челябинской области, органов местного самоуправления, государственным служащим, муниципальным служащим при получении данным заявителем государственной услуги.</li>
                </ul>

                <div className="small-title"><a target="_blank" href="http://minsoc74.ru/sites/default/files/14.docx">Форма заявления</a></div>

            </div>

        </div>

    </Container >)
}

export default Act1;