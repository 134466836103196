import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'

const Act15 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><a href="/">Главная</a></li>
                <li className="breadcrumb-item"><a href="/certificat">Получение удостоверений </a></li>
                <li className="breadcrumb-item active" aria-current="page">«Возмещение расходов, связанных с погребением реабилитированного лица»</li>
            </ol>
        </nav>
        <div className="name">«Возмещение расходов, связанных с погребением реабилитированного лица»</div>

        <div className='white-card'>

            <div className='card-body'>
                <div className="small-title">круг заявителей</div>
                <ul className="text">
                    <li>супруг (супруга), близкие родственники, иные родственники, законные представители умершего или иные лица, взявшие на себя обязанность осуществить погребение реабилитированного лица.</li>
                    <li>От имени заявителей могут выступать их представители, уполномоченные в порядке, установленном законодательством Российской Федерации.</li>
                </ul>
                <div className="small-title">срок предоставления государственной услуги</div>
                <ul className="text">
                    <li>не должен превышать 48 календарных дней</li>
                </ul>
                <div className="small-title">исчерпывающий перечень документов</div>
                <ul className="text">
                    <li>заявление о возмещении расходов, связанных с погребением реабилитированного лица, с указанием способа получения суммы возмещения (через кредитную организацию, отделение федеральной почтовой связи или иные организации, осуществляющие доставку пенсии);</li>
                    <li>документ, удостоверяющий личность заявителя, его законного представителя;</li>
                    <li>свидетельство о смерти реабилитированного лица;</li>
                    <li>свидетельство о реабилитации или справка о реабилитации установленной формы;</li>
                    <li>документ, подтверждающий регистрацию по месту жительства или по месту пребывания умершего реабилитированного лица;</li>
                    <li>платежные документы, подтверждающие факт произведенных затрат (кассовые чеки, в оговоренных законодательством Российской Федерации случаях - бланки строгой отчетности соответствующей формы);</li>
                    <li>документ, подтверждающий полномочия законного представителя заявителя (в случае если от имени заявителя выступает его законный представитель).</li>
                </ul>
                <div className="small-title">результат предоставления государственной услуги</div>
                <ul className="text">
                    <li>возмещение расходов</li>
                </ul>
                <div className="small-title">Основания для отказа в приеме документов </div>
                <ul className="text">
                    <li>отсутствуют</li>
                </ul>
                <div className="small-title">основания для отказа в предоставлении государственной услуги</div>
                <ul className="text">
                    <li>представление заявителем неполного пакета документов, предусмотренных Административным регламентом, и обязанность по представлению которых возложена на заявителя;</li>
                    <li>оформление документов с нарушением требований, установленных Административным регламентом;</li>
                    <li>наличие противоречий в документах, представленных заявителем;</li>
                    <li>представление органом социальной защиты по месту жительства (пребывания) умершего реабилитированного лица справки, подтверждающей, что заявителю или его законному представителю осуществлялось возмещение расходов, связанных с погребением реабилитированного лица, по месту жительства (пребывания) умершего реабилитированного лица;</li>
                    <li>обращение за возмещением расходов позднее шести месяцев со дня смерти реабилитированного лица.</li>
                </ul>
                <div className="small-title">право на досудебное (внесудебное) обжалование решений и действий (бездействия) должностных лиц </div>
                <ul className="text">
                    <li>В досудебном (внесудебном) порядке заявители могут обжаловать действия (бездействие) органов социальной защиты, Министерства социальных отношений Челябинской области, многофункционального центра, а также их должностных лиц, государственных гражданских служащих, муниципальных служащих, работников и принимаемые ими решения при предоставлении государственной услуги.</li>
                </ul>
                <div className="small-title"><a target="_blank" href="http://minsoc74.ru/sites/default/files/15.docx">Форма заявления</a></div>
            </div>

        </div>

    </Container >)
}

export default Act15;