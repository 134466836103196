import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                <li className="breadcrumb-item active" aria-current="page"><Link to="/certificateBase" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами</Link></li>
            </ol>
        </nav>

        <div className='white-card'>

            <div className='card-body'>
                <p>Право на получение удостоверения (дубликата удостоверения) ветерана Великой Отечественной войны имеют лица, указанные в статье 2 (ссылка) Федерального закона «О ветеранах».</p>
                <a>Ссылка на подпункт 2 пункта 4 раздела I Административного регламента</a>
                <p className="mt-3"><Link to="/action/3" style={{ cursor: 'pointer' }}>Общая информация о выдаче удостоверения ветерана Великой Отечественной войны</Link></p>
            </div>

        </div>

    </Container >)
}

export default Act1;