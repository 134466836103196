import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import { Link } from 'react-router-dom';

const Act41 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/siroty"> Меры социальной поддержки детей-сирот и детей, оставшихся без попечения родителей</Link></li>
            </ol>
        </nav>
        <div className="name">Единовременная выплата на ремонт жилого помещения, находящегося в собственности ребенка-сироты или ребенка, оставшегося без попечения родителей (до достижения возраста 18 лет или эмансипации</div>

        <div className='white-card'>

            <div className='card-body'>
                <p>Дети-сироты и дети, оставшиеся без попечения родителей, имеющие в собственности жилое помещение, нуждающееся в проведении ремонта и не признанное непригодным для проживания в установленном законодательством Российской Федерации порядке, имеют право на однократное получение единовременной выплаты на ремонт одного жилого помещения за счет средств областного бюджета в случае, если в указанном жилом помещении не проживают граждане по договору коммерческого найма либо законные представители ребенка-сироты или ребенка, оставшегося без попечения родителей</p>
            </div>

        </div>

    </Container >)
}

export default Act41;