import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                <li className="breadcrumb-item active" aria-current="page"><Link to="/certificateBase" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами</Link></li>
            </ol>
        </nav>

        <div className='white-card'>

            <div className='card-body'>
                <p>Гражданам, не имеющим постоянной регистрации по месту жительства на территории Челябинской области, удостоверение члена семьи погибшего (умершего) инвалида войны, участника Великой Отечественной войны, ветерана боевых действий либо военнослужащего, проходившего военную службу по призыву и погибшего при исполнении обязанностей военной службы (далее – удостоверение члена семьи погибшего (умершего), не выдается.
                    <br />
                    <a target="_blank" href="http://minsoc74.ru/vydacha-udostovereniy-o-prave-na-lgoty-chlenam-semey-pogibshih-umershih-invalidov-voyny-uchastnikov">Подробнее</a></p>
  
            </div>

        </div>

    </Container >)
}

export default Act1;