import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css';
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                <li className="breadcrumb-item active" aria-current="page"><Link to="/certificateFamily" style={{ cursor: 'pointer' }}>Получение удостоверения многодетной семьи Челябинской области</Link></li>
            </ol>
        </nav>
        <div className="name">Получение удостоверения многодетной семьи Челябинской области</div>

        <div className='white-card'>

            <div className='card-body'>
                <p>Для получения удостоверения многодетной семьи Челябинской области за-явителю необходимо обратиться с заявлением в Управление социальной за-щиты населения по месту жительства (пребывания).</p>
                <p>Заявителями на получение государственной услуги могут быть один из роди-телей (усыновителей) либо лицо, его заменяющее (опекун, попечитель), по-стоянно проживающие на территории Челябинской области и имеющие на своем содержании трех и более детей (в том числе усыновленных, взятых под опеку (попечительство), пасынков и падчериц) в возрасте до восемнадцати лет.</p>
                <p>Документы, необходимые для выдачи удостоверения указаны в пункте 11 Административного регламента предоставления государственной услуги «Выдача удостоверения многодетной семьи Челябинской области», утвер-жденного постановлением Правительства Челябинской области от 29 октября 2014 г. № 526-П «Об Административном регламенте предоставления госу-дарственной услуги «Выдача удостоверения многодетной семьи Челябинской области» и внесении изменений в постановление Правительства Челябинской области от 07.07.2014 г. № 310-П»</p>
            </div>

        </div>

    </Container >)
}

export default Act1;