import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                <li className="breadcrumb-item active" aria-current="page"><Link to="/certificateBase" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами</Link></li>
            </ol>
        </nav>

        <div className='white-card'>

            <div className='card-body'>
                <p>Органами социальной защиты населения удостоверение инвалида Великой Отечественной войны или удостоверение инвалида о праве на льготы выдается проживающим на территории Челябинской области инвалидам Великой Отечественной войны, инвалидам боевых действий на территории СССР, на территории Российской Федерации и территориях других государств из числа лиц, указанных в статье 4 Федерального закона от 12 января 1995 года № 5-ФЗ «О ветеранах», а также военнослужащим, проходившим военную службу по призыву, ставшим инвалидами вследствие ранения, контузии или увечья, полученных при исполнении обязанностей военной службы, из числа лиц, указанных в пункте 3 статьи 14 Федерального закона от 12 января 1995 года № 5-ФЗ «О ветеранах», пенсионное обеспечение которых осуществляется территориальными органами Пенсионного фонда Российской Федерации.</p>
                <a target="_blank" href="http://minsoc74.ru/vydacha-udostovereniya-invalida-velikoy-otechestvennoy-voyny-i-udostovereniya-invalida-o-prave-na-0">Подробнее</a>
                <p className="mt-3"><Link to="/action/4" style={{ cursor: 'pointer' }}>Общая информация о выдаче удостоверения инвалида войны</Link></p>
            </div>

        </div>

    </Container >)
}

export default Act1;