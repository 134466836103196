import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                <li className="breadcrumb-item active" aria-current="page"><Link to="/certificateBase" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами</Link></li>
            </ol>
        </nav>

        <div className='white-card'>

            <div className='card-body'>
                <p>Гражданам из числа военнослужащих, в том числе уволенных в запас (отставку), лиц рядового и начальствующего состава органов внутренних дел и органов государственной безопасности, бойцов и лиц командного состава истребительных батальонов, взводов и отрядов защиты народа, принимавших участие в боевых операциях по ликвидации националистического подполья на территориях Украины, Белоруссии, Литвы, Латвии и Эстонии в период с 1 января 1944 г. по 31 декабря 1951 г., а также из числа лиц, принимавших участие в операциях по боевому тралению в подразделениях, не входивших в состав действующего флота, в период Великой Отечественной войны, которым ранее удостоверение ветерана Великой Отечественной войны не выдавалось, удостоверение выдается уполномоченными органами Министерства обороны Российской Федерации, Министерства внутренних дел Российской Федерации и Федеральной службы безопасности Российской Федерации по месту жительства.</p>
                <a>Ссылка на подпункт «в» пункта 2 Инструкции о порядке заполнения, выдачи и учета удостоверений ветерана Великой Отечественной войны, утвержденной постановлением Правительства Российской Федерации от 05.10.1999 г. № 1122</a>
                <p className="mt-3"><Link to="/action/3" style={{ cursor: 'pointer' }}>Общая информация о выдаче удостоверения ветерана Великой Отечественной войны</Link></p>
            </div>

        </div>

    </Container >)
}

export default Act1;