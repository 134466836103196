import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import { Link } from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/children"> Социальная поддержка семей с детьми</Link></li>
            </ol>
        </nav>
        <div className="name">Ежемесячное пособие на детей военнослужащих, проходящих военную службу по призыву </div>

        <div className='white-card'>

            <div className='card-body'>

                <h3>Важно знать!</h3>
                <p>По заявлениям, поданным с 1 января 2022 года, при определении права по уровню дохода семьи учитываются доходы каждого члена семьи за расчётный период - 12 месяцев, предшествующих 4 месяцам до месяца подачи заявления о назначении пособия (например, при обращении в январе 2022 года – расчетный период с 01.09.2020 по 31.08.2021).</p>

                <text>Пособие назначается и выплачивается следующим категориям лиц гражданам Российской Федерации, постоянно проживающим на территории Челябинской области; иностранным гражданам при условии оформления вида на жительство, либо разрешения на временное проживание и официального трудоустройства (подлежащим обязательному социальному страхованию на случай временной нетрудоспособности и в связи с материнством </text>
                <text>Право имеет один из родителей при условии, что среднедушевой доход семьи ниже величины прожиточного минимума в среднем на душу населения, установленного в Челябинской области</text>
                <text>Дополнительное условие: в случае, если отец ребенка проходит военную службу по призыву</text>
                <div className="small-title">Нормативно правовые акты</div>
                <ul className="text">
                    <li><Link target={"_blank"} to={{ pathname: "https://www.uszntzr74.ru/otdel-semi-i-naznacheniya-detskih-posobii/normativno-pravovye-dokumenty/zakon-chelyabinskoi-oblasti-ot-28-oktyabrya-2004-g-n-299-zo-o-ezhemesyachnom-posobii-na-rebenka" }}>Закон Челябинской области  от 28 октября 2004 г. N 299-ЗО  "О пособии на ребенка" </Link></li>
                    <li><Link target={"_blank"} to={{ pathname: "http://docs.cntd.ru/document/453120368" }}>Постановление Губернатора Челябинской области от 02.08.2012 г. N 211 "О Порядке учета и исчисления величины среднедушевого дохода семьи, дающего право на получение пособия на ребенка и дополнительных мер социальной поддержки семей, имеющих детей". </Link>  </li>
                    <li><Link terget={"_blank"} to={{ pathname: "http://minsoc74.ru/sites/default/files/postanovlenie_ot_22_dekabrya_2004_g_n_167_p_v_red._ot_14.07.2021.docx" }}>Постановление Правительства Челябинской области  от 22 декабря 2004 г. N 167-П  "Об утверждении Положения о порядке назначения и выплаты пособия на ребенка"</Link></li>
                    <li><Link target={"_blank"} to={{ pathname: "http://minsoc74.ru/sites/default/files/postanovlenie_ot_18_aprelya_2012_g_n_187_p_v_red._ot_14.07.2021.docx " }}>Постановление Правительства Челябинской области от 18 апреля 2012 г . N 187-П "Об Административном регламенте предоставления государственной услуги "Назначение и выплата пособия на ребенка" и внесении изменений в некоторые постановления Правительства Челябинской области" </Link> </li>
                </ul>
                <div className="small-title">Основание для отказа</div>
                <ul className="text">
                    <li>Смерть ребенка, на которого назначается или назначено пособие;   </li>
                    <li>Отбывание ребенком, на которого назначается либо назначено пособие, наказания в местах лишения свободы по приговору суда;   </li>
                    <li>Отмена усыновления в отношении ребенка, на которого назначается либо назначено пособие;   </li>
                    <li>Отсутствие документа, подтверждающего дальнейшее обучение в общеобразовательном учреждении ребенка, достигшего возраста шестнадцати лет;   </li>
                    <li>Признание ребенка безвестно отсутствующим либо умершим;   </li>
                    <li>Превышение дохода семьи, дающего право на получение пособия на ребенка;   </li>
                    <li>Отсутствие факта совместного проживания родителя (усыновителя) с ребенком, на которого назначается либо назначено пособие;   </li>
                    <li>Лишение родительских прав в отношении ребенка, на которого назначается либо назначено пособие;   </li>
                    <li>Ограничение судом родительских прав в отношении ребенка, на которого назначается либо назначено пособие;   </li>
                    <li>Истечение срока, установленного для предоставления государственной услуги (пособие на ребенка назначается и выплачивается до достижения ребенком возраста шестнадцати лет (на учащегося общеобразовательного учреждения - до окончания им обучения, но не более чем до достижения им возраста восемнадцати лет);   </li>
                    <li>Наличие противоречий в документах, представляемых заявителем;   </li>
                    <li>Помещение ребенка на полное государственное обеспечение;   </li>
                    <li>Смерть получателя пособия   </li>
                    <li>Объявление ребенка в возрасте до восемнадцати лет, на которого назначается либо назначено пособие, полностью дееспособным в соответствии с законодательством Российской Федерации;   </li>
                    <li>Снятие заявителя с регистрационного учета по месту жительства (месту пребывания) на территории Челябинской области;</li>
                </ul>
                <p>Подробные сведения о пособии размещены <Link target={"_blank"} to={{ pathname: "https://minsoc.gov74.ru/minsoc/other/ezhemesyachnoe-posobie-na-rebenka-0.htm" }}>по ссылке</Link></p>
                <p>Подать заявление можно
                    <Link target={"_blank"} to={{ pathname: "https://gosuslugi74.ru/pgu/services/info/targets.htm?id=16074@egServiceTarget&serviceId=9816@egService" }}> по ссылке</Link>
                </p>
            </div>

        </div>

    </Container >)
}

export default Act1;