import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'

const Act22 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><a href="/">Главная</a></li>
                <li className="breadcrumb-item"><a href="/jku">Субсидии на ЖКУ</a></li>
                <li className="breadcrumb-item active" aria-current="page">Предоставление гражданам субсидии на оплату жилого помещения и коммунальных услуг. Раздел 5</li>
            </ol>
        </nav>
        <div className="name">Предоставление гражданам субсидии на оплату жилого помещения и коммунальных услуг. Раздел 5</div>

        <div className='white-card'>

            <div className='card-body'>
                <ul className="text">
                    <li>ЕСЛИ ДОХОДЫ ВАШЕЙ СЕМЬИ НА ОПЛАТУ ЖКУ СОСТАВЛЯЮТ МЕНЕЕ 22 % ОТ СОВОКУПНОГО ДОХОДА СЕМЬИ, ТО ПРАВО НА ПОЛУЧЕНИЕ СУБСИДИИ НА ОПЛАТУ ЖИЛОГО ПОМЕЩЕНИЯ И КОММУНАЛЬНЫХ УСЛУГ <strong> ОТСУТСТВУЕТ</strong></li>
                </ul>
                <div className="small-title">Наименование государственной услуги</div>
                <ul className="text">
                    <li>Предоставление гражданам субсидии на оплату жилого помещения и коммунальных услуг.
                          <br />
                        <a target="_blank" href="http://minsoc74.ru/zakonodatelstvo/normativno-pravovye-akty">Пункт2  постановления Правительства Челябинской области от 20 июля 2016 г. № 351-П 
                        « О региональных стандартах нормативной площади жилого помещения, используемых для расчета субсидий на оплату жилого помещения и коммунальных услуг, и максимально допустимой доли расходов граждан на оплату жилого помещения и коммунальных услуг в совокупном доходе семьи»</a>
                    </li>
                </ul>
            </div>
        </div>

    </Container>)
}

export default Act22;