import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import { Link } from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/children"> Социальная поддержка семей с детьми</Link></li>
            </ol>
        </nav>
        <div className="name">Денежная выплата по оплате жилого помещения и коммунальных услуг многодетным семьям (ЕЖЕМЕСЯЧНО)</div>

        <div className='white-card'>

            <div className='card-body'>
                <text>Право имеет многодетная семья при наличии регистрации по месту жительства (пребывания) на территории Челябинской области у всех членов семьи при условии, что среднедушевой доход  ниже величины прожиточного минимума в среднем на душу населения, установленного в Челябинской области</text>
                <text>При наличии в составе многодетной семьи лиц, имеющих право на получение дополнительных мер социальной поддержки по оплате жилого помещения и коммунальных услуг по иным основаниям, указанные меры предоставляются по выбору семьи по одному из оснований.</text>
                <text>Порядок учета и исчисления величины среднедушевого дохода семьи определен <a target="_blank" href="https://pravmin74.ru/normativnye-pravovye-akty/postanovleniya-gubernatora/postanovlenie-gubernatora-chelyabinskoi-obl-670"> Постановлением Губернатора Челябинской области от 2 августа 2012 года № 211</a></text>
                <div className="small-title">Нормативно правовые акты</div>
                <ul className="text">
                    <li><a target="_blank" href="http://docs.cntd.ru/document/895247773 ">Закон Челябинской области от 31 марта 2010 г. N 548-ЗО "О статусе и дополнительных мерах социальной поддержки многодетной семьи в Челябинской области" </a></li>
                    <li><a target="_blank" href="http://docs.cntd.ru/document/895254198 ">Постановление Правительства Челябинской области от 15 апреля 2010 года N 139-П «О Порядке предоставления многодетной семье дополнительных мер социальной поддержки по оплате жилого помещения и коммунальных услуг»</a></li>
                    <li><a target="_blank" href="http://docs.cntd.ru/document/453120368">Постановление Губернатора Челябинской области от 2 августа 2012 г. N 211 "О Порядке учета и исчисления величины среднедушевого дохода семьи, дающего право на получение пособия на ребенка и дополнительных мер социальной поддержки семей, имеющих детей"</a></li>
                    <li><a target="_blank" href="https://pravmin74.ru/normativnye-pravovye-akty/postanovleniya-pravitelstva/postanovlenie-pravitelstva-chelyabinskoi-o-765 ">Постановление Правительства Челябинской области от 23 мая 2012 г. N 249-П"Об Административном регламенте предоставления государственной услуги"Назначение многодетной семье ежемесячной денежной выплаты по оплате жилого помещения и коммунальных услуг" и внесении изменения в постановление Правительства Челябинской области от 15.04.2010 г. N 139-П"</a></li>
                </ul>
                <div className="small-title">Основание для отказа</div>
                <ul className="text">
                    <li>изменение места жительства (места пребывания) родителей (законных представителей), детей, утрата ими регистрации по месту жительства (месту пребывания).   </li>
                    <li>изменение состава семьи.   </li>
                    <li>объявление ребенка (детей) в возрасте до восемнадцати лет полностью дееспособным в соответствии с законодательством Российской Федерации (при наличии в семье 3 несовершеннолетних детей);   </li>
                    <li>лишение родительских прав либо ограничение судом родительских прав в отношении ребенка (детей).   </li>
                    <li>наличие противоречий в документах, представляемых заявителем.   </li>
                    <li>помещение ребенка (детей) на полное государственное обеспечение (при наличии в семье 3 несовершеннолетних детей).   </li>
                    <li>достижение детьми (ребенком) совершеннолетия (при наличии в семье трех несовершеннолетних детей).   </li>
                    <li>если среднедушевой доход семьи превышает величину прожиточного минимума на душу населения в Челябинской области.   </li>
                </ul>
                <p>Подробные сведения размещены <a target="_blank" href="https://www.gosuslugi.ru/107618/2/info ">здесь</a></p>
                <p>Подать заявление можно <a target="_blank" href="https://www.gosuslugi.ru/332575/2">здесь</a></p>
            </div>

        </div>

    </Container >)
}

export default Act1;