import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import { Link } from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/children"> Социальная поддержка семей с детьми</Link></li>
            </ol>
        </nav>
        <div className="name">Единовременное пособие при рождении ребенка</div>

        <div className='white-card'>

            <div className='card-body'>
                <div className="small-title">Право возникает:</div>
                <ul className="text">
                    <li>в случае если оба родителя, либо лицо, их заменяющее, не работают (не служат) либо обучаются по очной форме обучения в профессиональных образовательных организациях, образовательных организациях высшего образования, образовательных организациях дополнительного профессионального образования и научных организациях, единовременное пособие при рождении ребенка назначается и выплачивается органом социальной защиты населения по месту жительства (месту пребывания, месту фактического проживания) одного из родителей, либо лица, его заменяющего.</li>
                    <li>в случае если один из родителей, либо лицо, его заменяющее, работает (служит), а другой родитель либо лицо, его заменяющее, не работает (не служит), единовременное пособие при рождении ребенка назначается и выплачивается по месту работы (службы) родителя либо лица, его заменяющего.</li>
                </ul>
                <div className="small-title">Нормативно правовые акты</div>
                <ul className="text">
                    <li><a target="_blank" href="http://publication.pravo.gov.ru/Document/View/0001202007040001">Конституция Российской Федерации от 12.12.1993 г. </a></li>
                    <li><a target="_blank" href="https://docs.cntd.ru/document/9035383">Федеральный закон от 19.05.1995 № 81-ФЗ «О государственных пособиях гражданам, имеющим детей».</a></li>
                    <li><a target="_blank" href="http://publication.pravo.gov.ru/Document/View/0001201804160048">Приказ Министерства труда и социальной защиты РФ от 23 марта 2018 г. N 186н "Об утверждении Административного регламента по предоставлению органами государственной власти субъектов Российской Федерации государственной услуги в сфере переданных полномочий Российской Федерации по назначению государственных пособий гражданам, имеющим детей"</a></li>
                </ul>
                <div className="small-title">Основание для отказа</div>
                <ul className="text">
                    <li>Рождение мертвого ребенка   </li>
                    <li>Представление документов, не соответствующих требованиям или неполного пакета документов, необходимых для назначения пособия   </li>
                    <li>Лишение заявителя родительских прав   </li>
                    <li>Нахождение ребенка на полном государственном обеспечении   </li>
                    <li>Обращение за пособием по истечении шести месяцев со дня рождения ребенка   </li>
                </ul>
                <p>Подробные сведения размещены <a target="_blank" href="https://uszn46.eps74.ru/htmlpages/Show/legislation/SEM/Edinovremennoeposobieprirozhde "> по ссылке</a></p>
                <p>Для неработающих родителей подать заявление можно <a target="_blank" href="https://www.gosuslugi.ru/332575/2/form/order/814610862 "> по ссылке</a></p>
            </div>

        </div>

    </Container >)
}

export default Act1;