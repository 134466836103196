import React, { useState } from 'react';
import { Container, Form, FormGroup, Label, Input, Col, Row, Table,Button } from 'reactstrap';
import './Action.css'

const Act23 = () => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><a href="/">Главная</a></li>
                <li className="breadcrumb-item"><a href="/socialservices">Социальное обслуживание граждан</a></li>
                <li className="breadcrumb-item active" aria-current="page">Предоставление социальных услуг поставщиками социальных услуг в полустационарной форме в условиях временного приюта</li>
            </ol>
        </nav>
        <div className="name">Предоставление социальных услуг поставщиками социальных услуг в полустационарной форме в условиях временного приюта</div>

        <div className='white-card'>

            <div className='card-body'>
                <text><a target="_blank" href="https://minsoc.gov74.ru/files/upload/minsoc/%D0%94%D0%B5%D1%8F%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D1%8C/adm_reglament.docx">Административный регламент </a> </text>
                <div className="small-title">Категория населения, являющаяся получателем государственной услуги</div>
                <text>Социальные услуги в полустационарной форме в условиях временного приюта (далее именуются - социальные услуги) предоставляются гражданам, при наличии обстоятельств, ухудшающих или способных ухудшить условия их жизнедеятельности, в том числе:</text>
                <ul className="text">
                    <li>отсутствие определенного места жительства, в том числе у лица из числа детей-сирот и детей, оставшихся без попечения родителей, не достигшего возраста двадцати трех лет и завершившего пребывание в организации для детей-сирот и детей, оставшихся без попечения родителей, или иной организации;</li>
                    <li>утрата социально полезных связей в связи с освобождением из мест лишения свободы;</li>
                    <li>наличие внутрисемейного конфликта, в том числе с лицами с наркотической или алкогольной зависимостью, лицами, имеющими пристрастие к азартным играм, лицами, страдающими психическими расстройствами, наличие насилия в семье;</li>
                    <li>утрата жилого помещения в результате стихийных бедствий;</li>
                    <li>тсутствие работы и средств к существованию при наличии обстоятельств, указанных в подпунктах 1, 2.</li>
                </ul>
                <div className="small-title">Перечень документов, необходимых для предоставления государственной услуги</div>
                <ul className="text">
                    <li>заявление гражданина (его законного представителя) по форме, установленной Министерством труда и социальной защиты Российской Федерации;</li>
                    <li>документ, удостоверяющий личность заявителя либо его законного представителя (при наличии);</li>
                    <li>документ, подтверждающий полномочия законного представителя (при обращении законного представителя);</li>
                    <li>документ, подтверждающий место жительства и (или) пребывания, фактического проживания заявителя (при наличии);</li>
                    <li>копию документа, подтверждающего факт установления инвалидности заявителя, выданного федеральным государственным учреждением медико-социальной экспертизы, либо копию документа, подтверждающего факт установления инвалидности заявителя, выданного врачебно-трудовой экспертной комиссией (для заявителей, являющихся инвалидами), и копию индивидуальной программы реабилитации заявителя, выданной федеральным государственным учреждением медико-социальной экспертизы (при наличии); </li>
                    <li>копию документа, подтверждающего регистрацию в системе индивидуального (персонифицированного) учета;</li>
                    <li>документы, подтверждающие среднедушевой доход заявителя за 12 месяцев, предшествующих дате обращения (при наличии дохода).</li>

                </ul>
                <div className="small-title">Место получения государственной услуги, адрес, контактный телефон</div>
                <ul className="text">
                    <li>Муниципальные учреждения социального обслуживания для лиц без определённого места жительства и занятий  <a target="_blank" href="http://rpost.minsoc74.ru/rpost/reestr_last.html?PageSize=10&Sort=0&PageNumber=1">реестр поставщиков социальных услуг </a></li>
                </ul>
                <div className="small-title">Сроки оказания государственной услуги</div>
                <ul className="text">
                    <li>Решение о признании гражданина нуждающимся в социальном обслуживании в условиях временного приюта либо об отказе в признании гражданина нуждающимся в социальном обслуживании в условиях временного приюта принимается уполномоченным органом в день обращения гражданина.
                    В случае признания гражданина нуждающимся в социальном облуживании формируется индивидуальная программа предоставления социальных услуг по форме, установленной Министерством труда и социальной защиты Российской Федерации, в двух экземплярах. Один экземпляр индивидуальной программы, подписанный уполномоченным органом, передается гражданину или его законному представителю в срок не более чем один рабочий день с даты подачи гражданином заявления. Второй экземпляр индивидуальной программы остается в уполномоченном органе.
                    Прием на социальное обслуживание в условиях временного приюта граждан старше 18 лет осуществляется на основании индивидуальной программы и результатов параклинических исследований (бактериологические исследования на кишечную группу возбудителей, гельминтозы, дифтерию, сифилис, флюорографическое исследование).
                    При наличии положительных результатов параклинических исследований представляется справка соответствующих врачей-специалистов о возможности нахождения заявителя в организации социального обслуживания
</li>
                </ul>
            </div>

        </div>
    </Container >)
}

export default Act23;