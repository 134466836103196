import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item active"><Link to="/measure" style={{ cursor: 'pointer' }}>Меры социальной поддержки отдельным категориям граждан</Link></li>
            </ol>

        </nav>
        <div className="name">«Предоставление мер социальной поддержки в виде компенсации расходов на оплату жилых помещений, отопления и освещения отдельным категориям граждан, работающих и проживающих в сельских населенных пунктах и рабочих поселках Челябинской области»</div>

        <div className='white-card'>

            <div className='card-body'>
                <div className="small-title">круг заявителей</div>
                <ul className="text">
                    <li>педагогические работники, вышедшие на пенсию и проживающие в сельских населенных пунктах, рабочих поселках (поселках городского типа) Челябинской области, имеющие стаж работы не менее 10 лет в областных государственных и муниципальных образовательных организациях, расположенных в сельских населенных пунктах, рабочих поселках (поселках городского типа) Челябинской области;</li>
                    <li>лица, перешедшие на пенсию и проживающие в сельских населенных пунктах и рабочих поселках (поселках городского типа) Челябинской области, имеющие стаж работы не менее 10 лет в областных государственных или муниципальных организациях культуры, медицинских организациях, образовательных организациях, учреждениях ветеринарной службы, физкультурно-спортивных организациях, организациях социального обслуживания, расположенных в сельских населенных пунктах и рабочих поселках (поселках городского типа) Челябинской области.</li>
                    <li>От имени заявителей могут выступать их представители, уполномоченные в порядке, установленном законодательством Российской Федерации, выступать от их имени.</li>
                </ul>
                <div className="small-title">срок предоставления государственной услуги</div>
                <ul className="text">
                    <li>Срок предоставления государственной услуги не должен превышать 58 календарных дней.</li>
                </ul>

                <div className="small-title">исчерпывающий перечень документов</div>
                <ul className="text">
                    <li> заявление о предоставлении компенсации расходов с указанием способа ее получения (через кредитную организацию или через отделение федеральной почтовой связи);</li>
                    <li> копия документа, удостоверяющего личность;</li>
                    <li> копия пенсионного удостоверения либо справка о назначении пенсии, выданная территориальными органами Пенсионного фонда Российской Федерации;</li>
                    <li> документ о регистрации в жилом помещении, за которое начисляются платежи за жилое помещение, отопление и освещение;</li>
                    <li> документ, подтверждающий полномочия представителя заявителя (в случае если от имени заявителя выступает его представитель);</li>
                    <li> справка образовательной организации по последнему месту работы о наличии стажа работы в областных государственных и муниципальных образовательных организациях, расположенных в сельских населенных пунктах и рабочих поселках (поселках городского типа) Челябинской области, не менее 10 лет и о получении мер социальной поддержки по оплате жилых помещений, отопления и освещения на момент перехода на пенсию (для педагогических работников, вышедших на пенсию);</li>
                    <li> справка организации (учреждения) по последнему месту работы о наличии стажа работы в областных государственных или муниципальных организациях культуры, медицинских организациях, образовательных организациях, учреждениях ветеринарной службы, физкультурно-спортивных организациях, организациях социального обслуживания, расположенных в сельских населенных пунктах и рабочих поселках (поселках городского типа) Челябинской области, не менее 10 лет (для перешедших на пенсию работников организаций культуры, медицинских организаций, образовательных организаций, учреждений ветеринарной службы, физкультурно-спортивных организаций, организаций социального обслуживания).</li>
                </ul>

                <p>Документы, указанные в пунктах 3, 4, могут быть запрошены органами социальной защиты в рамках межведомственного информационного взаимодействия.</p>

                <div className="small-title">результат предоставления государственной услуги</div>
                <ul className="text">
                    <li>компенсация расходов</li>
                </ul>
                <div className="small-title">Основания для отказа в приеме документов </div>
                <ul className="text">
                    <li>Основания для отказа в приёме документов и для приостановления предоставления государственной услуги отсутствуют</li>
                </ul>

                <div className="small-title">основания для отказа в предоставлении государственной услуги</div>
                <ul className="text">
                    <li>представление заявителем неполного пакета документов;</li>
                    <li>оформление документов с нарушением требований;</li>
                    <li>наличие противоречий в документах, представленных заявителем;</li>
                    <li>представление органом социальной защиты по месту жительства заявителя справки, подтверждающей, что заявителю производилась компенсация расходов по месту жительства</li>
                </ul>
                <div className="small-title">право на досудебное (внесудебное) обжалование решений и действий (бездействия) должностных лиц </div>
                <ul className="text">
                    <li>В досудебном (внесудебном) порядке заявители могут обжаловать действия (бездействие) органов социальной защиты населения, Министерства социальных отношений Челябинской области, многофункционального центра, а также их должностных лиц, государственных гражданских служащих, муниципальных служащих, работников и принимаемые ими решения при предоставлении государственной услуги.</li>
                    <li>Жалоба на нарушение порядка предоставления государственной услуги - требование заявителя или его законного представителя о восстановлении или защите нарушенных прав или законных интересов заявителя органом социальной защиты населения, Министерством социальных отношений Челябинской области, многофункциональным центром, должностным лицом органа социальной защиты населения, должностным лицом Министерства социальных отношений Челябинской области, работником многофункционального центра, государственным гражданским служащим, муниципальным служащим при получении данным заявителем государственной услуги.</li>
                </ul>

                <div className="small-title"><a target="_blank" href="http://minsoc74.ru/sites/default/files/10.docx">Форма заявления</a></div>

            </div>

        </div>

    </Container >)
}

export default Act1;