import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css';
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                <li className="breadcrumb-item active" aria-current="page"><Link to="/certificateRadiation" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами, пострадавшими от воздействия радиации</Link></li>
            </ol>
        </nav>
        <div className="name">Пострадали от ВР вследствие ядерных испытаний на Семипалатинском полигоне </div>

        <div className='white-card'>

            <div className='card-body'>
                <text>принимали участие  в ядерных испытаниях на Семипалатинском полигоне</text>
                <ul className="text">
                    <li>Необходимо обращаться в военный комиссариат по месту жительства</li>


                </ul>
                <text>проживали  на загрязенной территории вследствие ядерных испытаний на Семипалатинском полигоне</text>
                <div className="small-title">Перечень документов:</div>
                <ul className="text">
                    <li>Заявление и согласие на обработку персональных данных</li>
                    <li>Паспорт РФ;</li>
                    <li>документ, подтверждающий факт проживания в населенном пункте, включенном в утвержденные Правительством Российской Федерации перечни населенных пунктов, подвергшихся радиационному воздействию вследствие ядерных испытаний на Семипалатинском полигоне, в периоды радиационного воздействия (выписки из похозяйственных или домовых книг, архивов жилищно-эксплуатационных управлений (жилищно-коммунальных отделов), справки паспортных столов, справки, выданные архивами образовательных учреждений, или другие документы, подтверждающие факт проживания в населенных пунктах, включенных в перечни).</li>
                </ul>
            </div>

        </div>

    </Container >)
}

export default Act1;