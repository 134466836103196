import React, { Component } from 'react';
import { Container, Row, Col, UncontrolledCollapse, Button, CardBody, Card } from 'reactstrap';
import {
    Link
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'


export class CertificateBase extends Component {

    constructor(props) {
        super(props);
        this.state = {
            first: true,
            firstYes: false,
            firstNo: false,
            levelGroup: false,
            isLevel: false,
            isChangeLevel: false,
            isVt1: false, isVt2: false, isVt3: false,
            isVtCh1: false, isVtCh2: false, isVtCh3: false, isVtCh4: false, isVtCh5: false
        };
    }
    render() {
        const { first, firstYes, firstNo, levelGroup, isLevel, isChangeLevel,
            isVt1, isVt2, isVt3,
            isVtCh1, isVtCh2, isVtCh3, isVtCh4, isVtCh5
        } = this.state;
        return (
            <Container>
                <Row>
                    <Col md='12' lg='12'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb pl-0">
                                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"><Link to="/certificateBase" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами</Link></li>
                            </ol>
                        </nav>
                    </Col>
                </Row>
                <Row>
                    <Col md='12' lg='12' className='mb-3'>
                        <div className='head-list-child mt-30'>
                            <div className='title-head-list title-head-list-child'>Получение удостоверения гражданами</div>
                        </div>

                        <div className='white-box-child last pt-0'>

                            {first ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Вам установлен льготный статус?</label>
                                    </div>

                                    <div className="element_string attribute_30">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input data-cof="1" value='true' type="radio" id="attribute_value59" name="attribute_30" className="custom-control-input" onChange={() => this.setState({ first: false, firstYes: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_59" htmlFor="attribute_value59">Да</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input data-cof="0" value="false" type="radio" id="attribute_value60" name="attribute_30" className="custom-control-input" onChange={() => this.setState({ first: false, firstNo: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_60" htmlFor="attribute_value60">Нет</label>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null
                            }

                            {firstNo ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Укажите, на установление какого статуса Вы претендуете:</label>
                                    </div>

                                    <div className="element_string">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value58" name="attribute_30" className="custom-control-input" onChange={() => this.setState({ firstNo: false, isVt1: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_58" htmlFor="attribute_value58">«Ветеран труда»</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/108">
                                                <input value="2" type="radio" id="attribute_value57" name="attribute_30" className="custom-control-input" onChange={() => this.setState({ firstNo: false, isVtCh1: true })} />
                                                <label className="custom-control-label label-group" id="attribute_value_label_57" htmlFor="attribute_value57">«Ветеран труда Челябинской области»</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/certificateWow">
                                                <input value="3" type="radio" id="attribute_value56" name="attribute_30" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_56" htmlFor="attribute_value56">«Ветеран Великой Отечественной войны»</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/certificateIwow">
                                                <input value="4" type="radio" id="attribute_value55" name="attribute_30" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_55" htmlFor="attribute_value55">«Инвалид Отечественной войны или инвалид боевых действий»</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/certificateChsp">
                                                <input value="5" type="radio" id="attribute_value54" name="attribute_30" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_54" htmlFor="attribute_value54">«Член семьи погибшего (умершего) инвалида, участника войны, ветерана боевых действий, военнослужащего, погибшего при исполнении обязанностей военной службы»</label>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null}

                            {firstYes ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Укажите, какой статус Вам установлен:</label>
                                    </div>

                                    <div className="element_string">
                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/94">
                                                <input value="6" type="radio" id="attribute_value53" name="attribute_30" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_52" htmlFor="attribute_value53"> Ветеран труда, ветеран военной службы</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/94">
                                                <input value="6" type="radio" id="attribute_value51" name="attribute_30" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_51" htmlFor="attribute_value51">Ветеран труда Челябинской области</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/94">
                                                <input value="6" type="radio" id="attribute_value50" name="attribute_30" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_50" htmlFor="attribute_value50">Ветераны Великой Отечественной войны из числа лиц, проработавших в тылу в период с 22 июня 1941 года по 9 мая 1945 года не менее шести месяцев, исключая период работы на временно оккупированных территориях СССР, либо награжденных орденами или медалями СССР за самоотверженный труд в период Великой Отечественной войны</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block" id="togglerr">
                                            <input value="6;7" type="radio" id="attribute_value49" name="attribute_30" className="custom-control-input" />
                                            <label className="custom-control-label label-group" id="attribute_value_label_49" htmlFor="attribute_value49">Реабилитированные лица</label>
                                        </div>

                                        <UncontrolledCollapse className="p-3" toggler="#togglerr">
                                            <ul className="list-group list_item_block">
                                                <li className="list-group-item"><Link to="/action/94">«Ежемесячная денежная выплата отдельным категориям ветеранов, жертвам политических репрессий и ветеранам труда Челябинской области»</Link></li>
                                                <li className="list-group-item"><Link to="/action/95">«Возмещение реабилитированным лицам расходов на проезд на междугородном транспорте»</Link></li>
                                            </ul>
                                        </UncontrolledCollapse>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/94">
                                                <input value="6" type="radio" id="attribute_value48" name="attribute_30" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_48" htmlFor="attribute_value48">Лица, пострадавшие от политических репрессий</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block" id="toggler">
                                            <input value="8;9" type="radio" id="attribute_value47" name="attribute_30" className="custom-control-input" />
                                            <label className="custom-control-label label-group" id="attribute_value_label_47" htmlFor="attribute_value47">Дети погибших участников Великой Отечественной войны и приравненные к ним лица</label>
                                        </div>

                                        <UncontrolledCollapse className="p-3" toggler="#toggler">
                                            <ul className="list-group list_item_block">
                                                <li className="list-group-item"><Link to="/action/8">«Ежемесячное социальное пособие детям погибших участников Великой Отечественной войны и приравненным к ним лицам»</Link></li>
                                                <li className="list-group-item"><Link to="/action/9">«Возмещение детям погибших участников Великой Отечественной войны и приравненным к ним лицам расходов на проезд к месту захоронения отца (матери)»</Link></li>
                                            </ul>
                                        </UncontrolledCollapse>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/98">
                                                <input value="10" type="radio" id="attribute_value46" name="attribute_30" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_46" htmlFor="attribute_value46">Педагогические работники, вышедшие на пенсию и проживающие в сельских населенных пунктах, рабочих поселках (поселках городского типа) Челябинской области, имеющие стаж работы не менее 10 лет в областных государственных и муниципальных образовательных организациях, расположенных в сельских населенных пунктах, рабочих поселках (поселках городского типа) Челябинской области</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/98">
                                                <input value="10" type="radio" id="attribute_value45" name="attribute_30" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_45" htmlFor="attribute_value45">Лица, перешедшие на пенсию и проживающие в сельских населенных пунктах и рабочих поселках (поселках городского типа) Челябинской области, имеющие стаж работы не менее 10 лет в областных государственных или муниципальных организациях культуры, медицинских организациях, образовательных организациях, учреждениях ветеринарной службы, физкультурно-спортивных организациях, организациях социального обслуживания, расположенных в сельских населенных пунктах и рабочих поселках (поселках городского типа) Челябинской области.</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block" id="toggler0">
                                            <input value="11;12;14" type="radio" id="attribute_value44" name="attribute_30" className="custom-control-input" />
                                            <label className="custom-control-label label-group" id="attribute_value_label_44" htmlFor="attribute_value44">Инвалиды и семьи, имеющие детей-инвалидов</label>
                                        </div>

                                        <UncontrolledCollapse className="p-3" toggler="#toggler0">
                                            <ul className="list-group list_item_block">
                                                <li className="list-group-item"><Link to="/action/11">«Компенсация расходов на оплату жилых помещений и коммунальных услуг отдельным категориям граждан»</Link></li>
                                                <li className="list-group-item"><Link to="/action/12">«Компенсация страховой премии по договору обязательного страхования гражданской ответственности владельцев транспортных средств в Челябинской области»</Link></li>
                                                <li className="list-group-item"><Link to="/action/14">«Обеспечение жильем отдельных категорий ветеранов, инвалидов и семей, имеющих детей-инвалидов, нуждающихся в улучшении жилищных условий и вставших на учет до 1 января 2005 года, и ветеранов Великой Отечественной войны вне зависимости от даты постановки на учет»</Link></li>
                                            </ul>
                                        </UncontrolledCollapse>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input value="state:levelGroup" type="radio" id="attribute_value43" name="attribute_30" className="custom-control-input" onChange={() => this.setState({ firstYes: false, levelGroup: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_43" htmlFor="attribute_value43">Инвалиды Великой Отечественной войны, инвалиды боевых действий, а также военнослужащие и лица рядового и начальствующего состава органов внутренних дел, Государственной противопожарной службы, учреждений и органов уголовно-исполнительной системы, ставшие инвалидами вследствие ранения, контузии или увечья, полученных при исполнении обязанностей военной службы (служебных обязанностей).</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block" id="toggler2">
                                            <input value="11;14" type="radio" id="attribute_value40" name="attribute_30" className="custom-control-input" />
                                            <label className="custom-control-label label-group" id="attribute_value_label_40" htmlFor="attribute_value40">Участники Великой Отечественной войны, указанные в подпунктах «а» - «ж» и «и» подпункта 1 пункта 1 статьи 2 Федерального закона от 12 января 1995 года № 5-ФЗ «О ветеранах».</label>
                                        </div>

                                        <UncontrolledCollapse className="p-3" toggler="#toggler2">
                                            <ul className="list-group list_item_block">
                                                <li className="list-group-item"><Link to="/action/11">«Компенсация расходов на оплату жилых помещений и коммунальных услуг отдельным категориям граждан»</Link></li>
                                                <li className="list-group-item"><Link to="/action/14">«Обеспечение жильем отдельных категорий ветеранов, инвалидов и семей, имеющих детей-инвалидов, нуждающихся в улучшении жилищных условий и вставших на учет до 1 января 2005 года, и ветеранов Великой Отечественной войны вне зависимости от даты постановки на учет»</Link></li>
                                            </ul>
                                        </UncontrolledCollapse>

                                        <div className="custom-control custom-radio list_item_block" id="toggler2020">
                                            <input value="11" type="radio" id="attribute_value39" name="attribute_30" className="custom-control-input" />
                                            <label className="custom-control-label label-group" id="attribute_value_label_39" htmlFor="attribute_value39">Участники Великой Отечественной войны, указанные в подпункте "з" подпункта 1 пункта 1 статьи 2 Федерального закона от 12 января 1995 года N 5-ФЗ "О ветеранах", ставшие инвалидами вследствие общего заболевания, трудового увечья или других причин (кроме лиц, инвалидность которых наступила вследствие их противоправных действий)</label>
                                        </div>


                                        <UncontrolledCollapse className="p-3" toggler="#toggler2020">
                                            <ul className="list-group list_item_block">
                                                <li className="list-group-item"><Link to="/action/11">«Компенсация расходов на оплату жилых помещений и коммунальных услуг отдельным категориям граждан»</Link></li>
                                                <li className="list-group-item"><Link to="/action/14">«Обеспечение жильем отдельных категорий ветеранов, инвалидов и семей, имеющих детей-инвалидов, нуждающихся в улучшении жилищных условий и вставших на учет до 1 января 2005 года, и ветеранов Великой Отечественной войны вне зависимости от даты постановки на учет»</Link></li>
                                            </ul>
                                        </UncontrolledCollapse>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input value="state:isLevel" type="radio" id="attribute_value38" name="attribute_30" className="custom-control-input" onChange={() => this.setState({ firstYes: false, isLevel: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_38" htmlFor="attribute_value38">Лица, награжденные знаком «Жителю блокадного Ленинграда»</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block" id="toggler3">
                                            <input value="11;14" type="radio" id="attribute_value35" name="attribute_30" className="custom-control-input" />
                                            <label className="custom-control-label label-group" id="attribute_value_label_35" htmlFor="attribute_value35">Члены семей погибших (умерших) инвалидов войны, участников Великой Отечественной войны и ветеранов боевых действий, а также члены семей военнослужащих, лиц рядового и начальствующего состава органов внутренних дел, Государственной противопожарной службы, учреждений и органов уголовно-исполнительной системы и органов государственной безопасности, погибших при исполнении обязанностей военной службы (служебных обязанностей)</label>
                                        </div>

                                        <UncontrolledCollapse className="p-3" toggler="#toggler3">
                                            <ul className="list-group list_item_block">
                                                <li className="list-group-item"><Link to="/action/11">«Компенсация расходов на оплату жилых помещений и коммунальных услуг отдельным категориям граждан»</Link></li>
                                                <li className="list-group-item"><Link to="/action/14">«Обеспечение жильем отдельных категорий ветеранов, инвалидов и семей, имеющих детей-инвалидов, нуждающихся в улучшении жилищных условий и вставших на учет до 1 января 2005 года, и ветеранов Великой Отечественной войны вне зависимости от даты постановки на учет»</Link></li>
                                            </ul>
                                        </UncontrolledCollapse>

                                        <div className="custom-control custom-radio list_item_block" id="toggler4">
                                            <input value="11;14" type="radio" id="attribute_value34" name="attribute_30" className="custom-control-input" />
                                            <label className="custom-control-label label-group" id="attribute_value_label_34" htmlFor="attribute_value34">Ветераны боевых действий из числа лиц, указанных в подпунктах                  1 - 4 пункта 1 статьи 3 Федерального закона от 12 января 1995 года № 5-ФЗ «О ветеранах».</label>
                                        </div>

                                        <UncontrolledCollapse className="p-3" toggler="#toggler4">
                                            <ul className="list-group list_item_block">
                                                <li className="list-group-item"><Link to="/action/11">«Компенсация расходов на оплату жилых помещений и коммунальных услуг отдельным категориям граждан»</Link></li>
                                                <li className="list-group-item"><Link to="/action/14">«Обеспечение жильем отдельных категорий ветеранов, инвалидов и семей, имеющих детей-инвалидов, нуждающихся в улучшении жилищных условий и вставших на учет до 1 января 2005 года, и ветеранов Великой Отечественной войны вне зависимости от даты постановки на учет»</Link></li>
                                            </ul>
                                        </UncontrolledCollapse>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/11">
                                                <input value="11" type="radio" id="attribute_value33" name="attribute_30" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_33" htmlFor="attribute_value33">Ветераны подразделений особого риска и граждане, имеющие право на меры социальной поддержки по оплате жилого помещения и коммунальных услуг в соответствии с законодательством Российской Федерации, устанавливающим меры социальной поддержки ветеранам подразделений особого риска и отдельным категориям граждан, подвергшихся радиационному воздействию вследствие катастрофы на Чернобыльской АЭС, аварии на производственном объединении "Маяк" и ядерных испытаний на Семипалатинском полигоне</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block" id="toggler5">
                                            <input value="11;14" type="radio" id="attribute_value32" name="attribute_30" className="custom-control-input" />
                                            <label className="custom-control-label label-group" id="attribute_value_label_32" htmlFor="attribute_value32">Военнослужащие, в том числе уволенные в запас (отставку), проходившие военную службу в воинских частях, учреждениях, военно-учебных заведениях, не входивших в состав действующей армии, в период с 22 июня 1941 года по 3 сентября 1945 года не менее шести месяцев; военнослужащие, награжденные орденами или медалями СССР за службу в указанный период</label>
                                        </div>

                                        <UncontrolledCollapse className="p-3" toggler="#toggler5">
                                            <ul className="list-group list_item_block">
                                                <li className="list-group-item"><Link to="/action/11">«Компенсация расходов на оплату жилых помещений и коммунальных услуг отдельным категориям граждан»</Link></li>
                                                <li className="list-group-item"><Link to="/action/14">«Обеспечение жильем отдельных категорий ветеранов, инвалидов и семей, имеющих детей-инвалидов, нуждающихся в улучшении жилищных условий и вставших на учет до 1 января 2005 года, и ветеранов Великой Отечественной войны вне зависимости от даты постановки на учет»</Link></li>
                                            </ul>
                                        </UncontrolledCollapse>


                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/11">
                                                <input value="11" type="radio" id="attribute_value31" name="attribute_30" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_31" htmlFor="attribute_value31">Лица, принимавшие участие в военно-стратегической операции "Анадырь" на территории Республики Куба в период Карибского кризиса с 1 июля 1962 года по 30 ноября 1963 года</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input value="state:isChangeLevel" type="radio" id="attribute_value30" name="attribute_30" className="custom-control-input" onChange={() => this.setState({ firstYes: false, isChangeLevel: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_30" htmlFor="attribute_value30">Иные лица, имеющие право на меры социальной поддержки</label>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null}

                            {levelGroup ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Выберите группу инвалидности</label>
                                    </div>

                                    <div className="element_string">
                                        <div className="custom-control custom-radio list_item_block" id="toggler6">
                                            <input value="11;13;14" type="radio" id="attribute_value42" name="attribute_30" className="custom-control-input" />
                                            <label className="custom-control-label label-group" id="attribute_value_label_42" htmlFor="attribute_value42">I или II группа инвалидности.</label>
                                        </div>

                                        <UncontrolledCollapse className="p-3" toggler="#toggler6">
                                            <ul className="list-group list_item_block">
                                                <li className="list-group-item"><Link to="/action/11">«Компенсация расходов на оплату жилых помещений и коммунальных услуг отдельным категориям граждан»</Link></li>
                                                <li className="list-group-item"><Link to="/action/13">«Компенсационные выплаты за пользование услугами местной телефонной связи и (или) за пользование услугами связи для целей проводного радиовещания»</Link></li>
                                                <li className="list-group-item"><Link to="/action/14">«Обеспечение жильем отдельных категорий ветеранов, инвалидов и семей, имеющих детей-инвалидов, нуждающихся в улучшении жилищных условий и вставших на учет до 1 января 2005 года, и ветеранов Великой Отечественной войны вне зависимости от даты постановки на учет»</Link></li>
                                            </ul>
                                        </UncontrolledCollapse>

                                        <div className="custom-control custom-radio list_item_block" id="toggler7">
                                            <input value="11;14" type="radio" id="attribute_value41" name="attribute_30" className="custom-control-input" />
                                            <label className="custom-control-label label-group" id="attribute_value_label_41" htmlFor="attribute_value41">III группа инвалидности.</label>
                                        </div>


                                        <UncontrolledCollapse className="p-3" toggler="#toggler7">
                                            <ul className="list-group list_item_block">
                                                <li className="list-group-item"><Link to="/action/11">«Компенсация расходов на оплату жилых помещений и коммунальных услуг отдельным категориям граждан»</Link></li>
                                                <li className="list-group-item"><Link to="/action/14">«Обеспечение жильем отдельных категорий ветеранов, инвалидов и семей, имеющих детей-инвалидов, нуждающихся в улучшении жилищных условий и вставших на учет до 1 января 2005 года, и ветеранов Великой Отечественной войны вне зависимости от даты постановки на учет»</Link></li>
                                            </ul>
                                        </UncontrolledCollapse>


                                    </div>
                                </div>
                            </div>)
                                : null}

                            {isLevel ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Установлена группа инвалидности</label>
                                    </div>

                                    <div className="element_string">
                                        <div className="custom-control custom-radio list_item_block" id="toggler8">
                                            <input value="11;14" type="radio" id="attribute_value37" name="attribute_30" className="custom-control-input" />
                                            <label className="custom-control-label label-group" id="attribute_value_label_37" htmlFor="attribute_value37">Да</label>
                                        </div>

                                        <UncontrolledCollapse className="p-3" toggler="#toggler8">
                                            <ul className="list-group list_item_block">
                                                <li className="list-group-item"><Link to="/action/11">«Компенсация расходов на оплату жилых помещений и коммунальных услуг отдельным категориям граждан»</Link></li>
                                                <li className="list-group-item"><Link to="/action/14">«Обеспечение жильем отдельных категорий ветеранов, инвалидов и семей, имеющих детей-инвалидов, нуждающихся в улучшении жилищных условий и вставших на учет до 1 января 2005 года, и ветеранов Великой Отечественной войны вне зависимости от даты постановки на учет»</Link></li>
                                            </ul>
                                        </UncontrolledCollapse>


                                        <div className="custom-control custom-radio list_item_block" id="toggler9">
                                            <input value="11;13;14" type="radio" id="attribute_value36" name="attribute_30" className="custom-control-input" />
                                            <label className="custom-control-label label-group" id="attribute_value_label_36" htmlFor="attribute_value36">Нет</label>
                                        </div>

                                        <UncontrolledCollapse className="p-3" toggler="#toggler9">
                                            <ul className="list-group list_item_block">
                                                <li className="list-group-item"><Link to="/action/11">«Компенсация расходов на оплату жилых помещений и коммунальных услуг отдельным категориям граждан»</Link></li>
                                                <li className="list-group-item"><Link to="/action/13">«Компенсационные выплаты за пользование услугами местной телефонной связи и (или) за пользование услугами связи для целей проводного радиовещания»</Link></li>
                                                <li className="list-group-item"><Link to="/action/14">«Обеспечение жильем отдельных категорий ветеранов, инвалидов и семей, имеющих детей-инвалидов, нуждающихся в улучшении жилищных условий и вставших на учет до 1 января 2005 года, и ветеранов Великой Отечественной войны вне зависимости от даты постановки на учет»</Link></li>
                                            </ul>
                                        </UncontrolledCollapse>

                                    </div>
                                </div>
                            </div>) : null}

                            {isChangeLevel ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Выберите категорию</label>
                                    </div>

                                    <div className="element_string">
                                        <Link to="/action/15">
                                            <div className="custom-control custom-radio list_item_block">
                                                <input value="15" type="radio" id="attribute_value29" name="attribute_30" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_29" htmlFor="attribute_value29">Гражданин, осуществивший погребение реабилитированного лица:</label>
                                            </div>
                                        </Link>

                                        <Link to="/action/16">
                                            <div className="custom-control custom-radio list_item_block">
                                                <input value="16" type="radio" id="attribute_value28" name="attribute_30" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_28" htmlFor="attribute_value28">Граждане, награжденные нагрудным знаком «Почетный донор России», а также граждане, награжденные нагрудным знаком «Почетный донор СССР»:</label>
                                            </div>
                                        </Link>

                                        <Link to="/action/17">
                                            <div className="custom-control custom-radio list_item_block">
                                                <input value="17" type="radio" id="attribute_value27" name="attribute_30" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_27" htmlFor="attribute_value27">Неработающие собственники жилых помещений, достигшие возраста семидесяти лет, проживающие одиноко либо в семьях, состоящих из граждан достигших возраста семидесяти лет, и (или) инвалидов</label>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>) : null}

                            {isVt1 ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Принадлежность к гражданству</label>
                                    </div>

                                    <div className="element_string">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value029" name="attribute_030" className="custom-control-input" onChange={() => this.setState({ isVt1: false, isVt2: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_029" htmlFor="attribute_value029">Гражданин Российской Федерации </label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/65">
                                                <input type="radio" id="attribute_value28" name="attribute_030" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_028" htmlFor="attribute_value028">Гражданин иностранного государства (лицо без гражданства) </label>
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                            </div>) : null}

                            {isVt2 ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Наличие постоянной регистрации по месту жительства на территории Челябинской области</label>
                                    </div>

                                    <div className="element_string">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value031" name="attribute_031" className="custom-control-input" onChange={() => this.setState({ isVt2: false, isVt3: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_031" htmlFor="attribute_value031">Да</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/66">
                                                <input type="radio" id="attribute_value32" name="attribute_031" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_032" htmlFor="attribute_value032">Нет</label>
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                            </div>) : null}

                            {isVt3 ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Наличие оснований для присвоения звания «Ветеран труда»</label>
                                    </div>

                                    <div className="element_string">
                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/67">
                                                <input type="radio" id="attribute_value033" name="attribute_032" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_033" htmlFor="attribute_value033">наличие орденов или медалей СССР или Российской Федерации, либо почетных званий СССР или Российской Федерации, либо почетных грамот или благодарностей Президента Российской Федерации, либо ведомственных знаков отличия за заслуги в труде (службе) и наличие трудового (страхового) стажа, учитываемого для назначения пенсии, не менее 25 лет для мужчин и 20 лет для женщин или выслуги лет, необходимой для назначения пенсии за выслугу лет в календарном исчислении </label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/67">
                                                <input type="radio" id="attribute_value34" name="attribute_032" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_034" htmlFor="attribute_value034">начало трудовой деятельности в несовершеннолетнем возрасте в период Великой Отечественной войны и наличие трудового (страхового) стажа не менее 40 лет для мужчин и 35 лет для женщин</label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/107">
                                                <input type="radio" id="attribute_value35" name="attribute_032" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_035" htmlFor="attribute_value035">ни одно из указанных оснований </label>
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                            </div>) : null}

                            {isVtCh1 ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Принадлежность к гражданству</label>
                                    </div>

                                    <div className="element_string">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value036" name="attribute_033" className="custom-control-input" onChange={() => this.setState({ isVtCh1: false, isVtCh2: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_036" htmlFor="attribute_value036">Гражданин Российской Федерации </label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/68">
                                                <input type="radio" id="attribute_value37" name="attribute_033" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_037" htmlFor="attribute_value037">Гражданин иностранного государства (лицо без гражданства) </label>
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                            </div>) : null}

                            {isVtCh2 ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Наличие постоянной регистрации по месту жительства на территории Челябинской области</label>
                                    </div>

                                    <div className="element_string">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value038" name="attribute_034" className="custom-control-input" onChange={() => this.setState({ isVtCh2: false, isVtCh3: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_038" htmlFor="attribute_value038">Да</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/69">
                                                <input type="radio" id="attribute_value39" name="attribute_034" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_039" htmlFor="attribute_value039">Нет</label>
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                            </div>) : null}

                            {isVtCh3 ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Укажите наличие звания «Ветеран труда» в соответствии с Федеральным законом «О ветеранах»</label>
                                    </div>

                                    <div className="element_string">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value040" name="attribute_035" className="custom-control-input" onChange={() => this.setState({ isVtCh3: false, isVtCh4: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_040" htmlFor="attribute_value040">нет</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/72">
                                                <input type="radio" id="attribute_value40" name="attribute_035" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_040" htmlFor="attribute_value040">да</label>
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                            </div>) : null}

                            {isVtCh4 ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Наличие критериев для обращения за присвоением звания «Ветеран труда Челябинской области»</label>
                                    </div>

                                    <div className="element_string">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value041" name="attribute_036" className="custom-control-input" onChange={() => this.setState({ isVt2: false, isVt3: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_041" htmlFor="attribute_value041">установлена (назначена) страховая пенсия по старости в соответствии с Федеральным законом «О страховых пенсиях» </label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input type="radio" id="attribute_value42" name="attribute_036" className="custom-control-input" onChange={() => this.setState({ isVt2: false, isVt3: true })} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_042" htmlFor="attribute_value042">достижение возраста 55 лет (для женщин) и 60 лет (для мужчин)</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/70">
                                                <input type="radio" id="attribute_value43" name="attribute_036" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_043" htmlFor="attribute_value043">ни одно из указанных оснований </label>
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                            </div>) : null}

                            {isVtCh5 ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Наличие оснований для присвоения звания «Ветеран труда Челябинской области»</label>
                                    </div>

                                    <div className="element_string">
                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/71">
                                                <input type="radio" id="attribute_value044" name="attribute_037" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_044" htmlFor="attribute_value044">наличие страхового стажа не менее 40 лет для мужчин и 35 лет для женщин и наград Челябинской области, наград Законодательного Собрания Челябинской области, почетных грамот Законодательного Собрания Челябинской области или Губернатора Челябинской области  </label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/71">
                                                <input type="radio" id="attribute_value045" name="attribute_037" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_045" htmlFor="attribute_value045">рождение пяти и более детей и воспитание их до достижения ими возраста восьми лет </label>
                                            </Link>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <Link to="/action/2">
                                                <input type="radio" id="attribute_value046" name="attribute_037" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_046" htmlFor="attribute_value046">ни одно из указанных оснований </label>
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                            </div>) : null}



                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }

}