import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'

const Act19 = () => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><a href="/">Главная</a></li>
                <li className="breadcrumb-item"><a href="/jku">Субсидии на ЖКУ</a></li>
                <li className="breadcrumb-item active" aria-current="page">Предоставление гражданам субсидии на оплату жилого помещения и коммунальных услуг. Раздел 2</li>
            </ol>
        </nav>
        <div className="name">Предоставление гражданам субсидии на оплату жилого помещения и коммунальных услуг. Раздел 2</div>

        <div className='white-card'>

            <div className='card-body'>
                <ul className="text">
                    <li>ЕСЛИ ВЫ НЕ ИМЕЕТЕ ПОСТОЯННУЮ РЕГИСТРАЦИЮ, ТО ПРАВО НА ПОЛУЧЕНИЕ СУБСИДИИ НА ОПЛАТУ ЖИЛОГО ПОМЕЩЕНИЯ И КОММУНАЛЬНЫХ УСЛУГ <strong> ОТСУТСТВУЕТ</strong></li>
                </ul>
                <div className="small-title">Наименование государственной услуги</div>
                <ul className="text">
                    <li>Предоставление гражданам субсидии на оплату жилого помещения и коммунальных услуг. 
                       <br />
                        <a target="_blank" href="http://minsoc74.ru/zakonodatelstvo/normativno-pravovye-akty">Пункт 8 Правил предоставления субсидий
                        на оплату жилого помещения и коммунальных услуг, утвержденных постановлением Правительства РФ от 14 декабря 2005 г. № 761
 </a>
                        </li>
                </ul>
            </div>
        </div>

    </Container >)
}

export default Act19;