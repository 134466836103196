import React, { useState } from 'react';
import { Container, Form, FormGroup, Label, Input, Col, Row, Table,Button } from 'reactstrap';
import './Action.css'

const Act23 = () => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><a href="/">Главная</a></li>
                <li className="breadcrumb-item"><a href="/socialservices">Социальное обслуживание граждан</a></li>
                <li className="breadcrumb-item active" aria-current="page">Предоставление социальных услуг поставщиками социальных услуг в стационарной форме</li>
            </ol>
        </nav>
        <div className="name">Предоставление социальных услуг поставщиками социальных услуг в стационарной форме</div>

        <div className='white-card'>

            <div className='card-body'>
                <text><a target="_blank" href="http://minsoc74.ru/stacionarnoe-obluzhivanie#overlay-context=socialnoe-obsluzhivanie">ссылка на соответствующую информацию, размещенную на официальном сайте Министерства социальных отношений Челябинской области</a></text>
                <div className="small-title">Категория населения, являющаяся получателем государственной услуги</div>
                <text>Социальные услуги в стационарной форме социального обслуживания предоставляются гражданам пожилого возраста, инвалидам, в том числе детям-инвалидам, иным категориям граждан при наличии обстоятельств, ухудшающих или способных ухудшить условия их жизнедеятельности, в том числе:</text>
                <ul className="text">
                    <li> полная утрата способности либо возможности осуществлять самообслуживание и (или) самостоятельно передвигаться, обеспечивать основные жизненные потребности в силу заболевания, травмы, возраста или наличия инвалидности при невозможности предоставления ему социальных услуг на дому;</li>
                    <li> частичная утрата способности либо возможности осуществлять самообслуживание и (или) самостоятельно передвигаться, обеспечивать основные жизненные потребности в силу заболевания, травмы, возраста или наличия инвалидности при невозможности предоставления им социальных услуг на дому. Критерии выбора указанных форм социального обслуживания устанавливаются Министерством социальных отношений Челябинской области;</li>
                    <li> проживание в семье, члены которой по объективным причинам (болезнь, убытие в командировку или отпуск и тому подобное) временно не могут осуществлять уход при наличии обстоятельств, указанных выше в подпунктах 1, 2.</li>
                </ul>
                <div className="small-title">Перечень документов, необходимых для предоставления государственной услуги</div>
                <ul className="text">
                    <li>заявление гражданина (его законного представителя) по форме, утвержденной Министерством труда и социальной защиты Российской Федерации. Заявление о помещении в стационарную организацию социального обслуживания несовершеннолетнего лица представляется его родителями или иными законными представителями;</li>
                    <li>копия документа, удостоверяющего личность заявителя, его законного представителя, в том числе страницы с отметкой, подтверждающей регистрацию заявителя по месту жительства, копия свидетельства о рождении (для несовершеннолетних заявителей);</li>
                    <li>заключение врачебной комиссии медицинской организации с участием врача-психиатра о рекомендуемом профиле стационарной организации социального обслуживания по установленной форме;</li>
                    <li>заключение врачебной комиссии медицинской организации о степени утраты способности заявителя к самообслуживанию и (или) самостоятельному передвижению по форме, установленной Министерством (для заявителей, не являющихся инвалидами);</li>
                    <li>заключение медицинской организации о наличии (отсутствии) медицинских противопоказаний, в связи с наличием которых заявителю может быть отказано, в том числе временно, в предоставлении социальных услуг в стационарной форме, по форме, установленной Министерством здравоохранения Российской Федерации;</li>
                    <li>копия справки, подтверждающей факт установления инвалидности заявителя, выданной федеральным государственным учреждением медико-социальной экспертизы, либо копия справки, подтверждающей факт установления инвалидности заявителя, выданной врачебно-трудовой экспертной комиссией (для заявителей, являющихся инвалидами);</li>
                    <li>копия индивидуальной программы реабилитации инвалида, выданной федеральным государственным учреждением медико-социальной экспертизы (для заявителей, являющихся инвалидами);</li>
                    <li>выписка из медицинской карты (акт первичного психиатрического освидетельствования), выданная психоневрологической медицинской организацией (для заявителей, имеющих психическое расстройство) по установленной форме;</li>
                    <li>копии документов, подтверждающих статус лица, имеющего право на внеочередное или преимущественное поступление в стационарную организацию социального обслуживания в соответствии с действующим законодательством;</li>
                    <li> две фотографии формата 3 x 4 сантиметра;</li>
                    <li> копия сертификата о профилактических прививках (для заявителей, являющихся несовершеннолетними, - в обязательном порядке, для совершеннолетних заявителей - при наличии);</li>
                    <li> заключение психолого-медико-педагогической комиссии (для заявителей, являющихся несовершеннолетними);</li>
                    <li> документ, подтверждающий полномочия представителя заявителя (в случае если от имени заявителя выступает его представитель);</li>
                    <li> акт органа опеки и попечительства о помещении ребенка под надзор в организацию для детей-сирот (для несовершеннолетних заявителей из числа детей-сирот и детей, оставшихся без попечения родителей);</li>
                    <li> копия страхового свидетельства обязательного пенсионного страхования.</li>
                </ul>
                <div className="small-title">Место получения государственной услуги, адрес, контактный телефон</div>
                <ul className="text">
                    <li>Уполномоченные органы местного самоуправления (информация
                    о месте нахождения уполномоченных органов местного самоуправления, их почтовых адресах, справочных телефонах, адресах электронной почты размещена в полной версии Административного регламента  предоставления государственной услуги «Предоставление социальных услуг
                    поставщиками социальных услуг
                    в стационарной форме») <a target="_blank" href="http://publication.pravo.gov.ru/Document/View/7400201601150002"></a></li>
                </ul>
                <div className="small-title">Сроки оказания государственной услуги</div>
                <ul className="text">
                    <li>Датой подачи заявления на получение государственной  услуги считается день подачи заявления и всех необходимых документов.
                    Решение о признании гражданина нуждающимся в социальном обслуживании принимается в течение 5 рабочих дней с даты подачи заявления.
                    В случае признания гражданина нуждающимся в социальном облуживании формируется индивидуальная программа предоставления социальных услуг по форме, установленной Министерством труда и социальной защиты Российской Федерации, в двух экземплярах. Один экземпляр индивидуальной программы, подписанный уполномоченным органом, передается гражданину или его законному представителю в срок не более чем десять рабочих дней с даты подачи гражданином заявления. Второй экземпляр индивидуальной программы остается в уполномоченном органе.
</li>
                </ul>
            </div>

        </div>

    </Container >)
}

export default Act23;