import React, { useState } from 'react';
import { Container, Form, FormGroup, Label, Input, Col, Row, Table,Button } from 'reactstrap';
import './Action.css'

const Act23 = () => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><a href="/">Главная</a></li>
                <li className="breadcrumb-item"><a href="/socialservices">Социальное обслуживание граждан</a></li>
                <li className="breadcrumb-item active" aria-current="page">Предоставление социальных услуг поставщиками социальных услуг в стационарной форме.</li>
            </ol>
        </nav>
        <div className="name">Предоставление социальных услуг поставщиками социальных услуг в стационарной форме</div>

        <div className='white-card'>

            <div className='card-body'>
                <text><a target="_blank" href="https://minsoc.gov74.ru/files/upload/minsoc/%D0%94%D0%B5%D1%8F%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D1%8C/adm_reglament.docx">Административный регламент </a> </text>
                <div className="small-title">Категория населения, являющаяся получателем государственной услуги</div>
                <text>«Социальные услуги в стационарной форме социального обслуживания предоставляются гражданам пожилого возраста, инвалидам, в том числе детям-инвалидам, иным категориям граждан при наличии обстоятельств, ухудшающих или способных ухудшить условия их жизнедеятельности, в том числе:</text>
                <ul className="text">
                    <li>полная утрата способности либо возможности осуществлять самообслуживание и (или) самостоятельно передвигаться, обеспечивать основные жизненные потребности в силу заболевания, травмы, возраста или наличия инвалидности при невозможности предоставления ему социальных услуг на дому;</li>
                    <li>частичная утрата способности либо возможности осуществлять самообслуживание и (или) самостоятельно передвигаться, обеспечивать основные жизненные потребности в силу заболевания, травмы, возраста или наличия инвалидности при невозможности предоставления им социальных услуг на дому. Критерии выбора указанных форм социального обслуживания устанавливаются Министерством социальных отношений Челябинской области;</li>
                    <li>проживание в семье, члены которой по объективным причинам (болезнь, убытие в командировку или отпуск и тому подобное) временно не могут осуществлять уход при наличии обстоятельств, указанных выше в подпунктах 1, 2;</li>
                    <li>проживание в семье, члены которой, осуществляющие уход, госпитализированы в медицинскую организацию в экстренной или неотложной форме, при наличии обстоятельств, указанных в подпунктах 1, 2.</li>
                </ul>
                <div className="small-title">Перечень документов, необходимых для предоставления государственной услуги</div>
                <ul className="text">
                    <li>1) заявление гражданина (его законного представителя) по форме, утвержденной Министерством труда и социальной защиты Российской Федерации.
                    Для лиц, признанных в установленном порядке недееспособными, если такое лицо по своему состоянию не способно подать личное заявление, основанием для предоставления социальных услуг в стационарной форме является решение органа опеки и попечительства, принятое на основании заключения врачебной комиссии с участием врача-психиатра. Заключение должно содержать сведения о наличии у лица психического расстройства, лишающего его возможности находиться в иной организации социального обслуживания, предоставляющей социальные услуги в стационарной форме, а в отношении дееспособного лица - также и об отсутствии оснований для постановки перед судом вопроса о признании его недееспособным;</li>
                <li>2) документ, удостоверяющий личность заявителя, его представителя (в случае если от имени заявителя выступает его представитель), свидетельство о рождении или сведения о рождении ребенка, содержащиеся в федеральной государственной информационной системе "Единый государственный реестр записи актов гражданского состояния" (для несовершеннолетних граждан);</li>
                <li>3) заключение врачебной комиссии медицинской организации с участием врача-психиатра о рекомендуемом профиле стационарной организации социального обслуживания;</li>
                <li>4) заключение врачебной комиссии медицинской организации о степени утраты способности заявителя к самообслуживанию и (или) самостоятельному передвижению по форме, установленной Министерством (для заявителей, не являющихся инвалидами);</li>
                <li>5) заключение медицинской организации о наличии (отсутствии) медицинских противопоказаний, в связи с наличием которых заявителю может быть отказано, в том числе временно, в предоставлении социальных услуг в стационарной форме, по форме, установленной Министерством здравоохранения Российской Федерации;</li>
                <li>6) копия документа, подтверждающего факт установления инвалидности заявителя, выданного федеральным государственным учреждением медико-социальной экспертизы, либо копия документа, подтверждающего факт установления инвалидности заявителя, выданного врачебно-трудовой экспертной комиссией (для заявителей, являющихся инвалидами);</li>
                <li>7) копия индивидуальной программы реабилитации инвалида, выданной федеральным государственным учреждением медико-социальной экспертизы (для заявителей, являющихся инвалидами);</li>
                <li>8) выписка из медицинской карты (акт первичного психиатрического освидетельствования), выданная психоневрологической медицинской организацией (для заявителей, имеющих психическое расстройство);</li>
                <li>9) копии документов, подтверждающих статус лица, имеющего право на внеочередное или преимущественное поступление в стационарную организацию социального обслуживания в соответствии с действующим законодательством;</li>
                <li>10) две фотографии формата 3 x 4 сантиметра;</li>
                <li>11) копия сертификата о профилактических прививках (для заявителей, являющихся несовершеннолетними, - в обязательном порядке, для совершеннолетних заявителей - при наличии);</li>
                <li>12) заключение психолого-медико-педагогической комиссии (для заявителей, являющихся несовершеннолетними);</li>
                <li>13) документ, подтверждающий полномочия представителя заявителя (в случае если от имени заявителя выступает его представитель);</li>
                <li>14) акт органа опеки и попечительства о помещении ребенка под надзор в организацию для детей-сирот (для несовершеннолетних заявителей из числа детей-сирот и детей, оставшихся без попечения родителей);</li>
                <li>15) копия документа, подтверждающего регистрацию в системе индивидуального (персонифицированного) учета;</li>
                <li>16) сведения о регистрации по месту жительства лиц, указанных заявителем (его законным представителем) в заявлении и подтвержденных Министерством внутренних дел Российской Федерации, в соответствии с запросом уполномоченного органа местного самоуправления, а также информации о родственных связях заявителя с гражданами, зарегистрированными совместно с ним, задекларированными заявителем, подтвержденными документами, удостоверяющими личность, а также свидетельствами о государственной регистрации актов гражданского состояния (далее именуются - сведения о регистрации по месту жительства лиц, указанных заявителем).</li>


                </ul>
                <div className="small-title">Место получения государственной услуги, адрес, контактный телефон</div>
                <ul className="text">
                    <li>Уполномоченные органы местного самоуправления (информация
                    о месте нахождения уполномоченных органов местного самоуправления, их почтовых адресах, справочных телефонах, адресах электронной почты размещена в полной версии Административного регламента  предоставления государственной услуги «Предоставление социальных услуг
                    поставщиками социальных услуг
                    в стационарной форме») – <a target="_blank" href="http://minsoc74.ru/stacionarnoe-obluzhivanie#overlay-context=socialnoe-obsluzhivanie">Административный регламент</a> 
                        <a target="_blank" href="http://publication.pravo.gov.ru/Document/View/7400202001200008"> портал правовой информации</a> 
</li>
                </ul>
                <div className="small-title">Сроки оказания государственной услуги</div>
                <ul className="text">
                    <li>Датой подачи заявления на получение государственной  услуги считается день подачи заявления и всех необходимых документов.
                    Решение о признании гражданина нуждающимся в социальном обслуживании принимается в течение 5 рабочих дней с даты подачи заявления.
                    В случае признания гражданина нуждающимся в социальном облуживании формируется индивидуальная программа предоставления социальных услуг по форме, установленной Министерством труда и социальной защиты Российской Федерации, в двух экземплярах. Один экземпляр индивидуальной программы, подписанный уполномоченным органом, передается гражданину или его законному представителю в срок не более чем десять рабочих дней с даты подачи гражданином заявления. Второй экземпляр индивидуальной программы остается в уполномоченном органе.
</li>
                </ul>
            </div>

        </div>

    </Container >)
}

export default Act23;