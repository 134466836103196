import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import { Link } from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/children"> Социальная поддержка семей с детьми</Link></li>
            </ol>
        </nav>
        <div className="name">Распоряжение средствами (частью средств)областного материнского (семейного) капитала</div>

        <div className='white-card'>

            <div className='card-body'>

                <h3>Важно знать!</h3>
                <p>По заявлениям, поданным с 1 января 2022 года, при определении права по уровню дохода семьи учитываются доходы каждого члена семьи за расчётный период - 12 месяцев, предшествующих 4 месяцам до месяца подачи заявления о назначении пособия (например, при обращении в январе 2022 года – расчетный период с 01.09.2020 по 31.08.2021).</p>

                <text>Распорядиться средствами могут: граждане Российской Федерации, проживающие на территории Челябинской области, в семьях имеющих трех и более детей (независимо от наличия/отсутствия статуса «многодетная семья)).</text>
                <div className="small-title">Право на распоряжение средствами имеют:</div>
                <ul className="text">
                    <li>женщины, родившие (усыновившие) третьего ребенка или последующих детей, начиная с 1 января 2012 года, если ранее они не воспользовались правом на получение областного материнского (семейного) капитала;</li>
                    <li>в случае смерти женщины, объявления ее умершей, признания ее судом недееспособной, ограниченно дееспособной, ограничения ее судом в родительских правах, лишения родительских прав в отношении ребенка, в связи с рождением которого возникло право возникает у отца (усыновителя) </li>
                    <li>в случаях, если отец (усыновитель) ребенка или мужчина, являющийся единственным усыновителем ребенка, умер, объявлен умершим, признан судом недееспособным, ограниченно дееспособным, ограничен судом в родительских правах, лишен родительских прав в отношении ребенка, в связи с рождением которого возникло право на получение областного материнского (семейного) капитала, совершил в отношении своего (своих) ребенка (детей) умышленное преступление, относящееся к преступлениям против личности, либо если в отношении указанных лиц отменено усыновление ребенка, в связи с усыновлением которого возникло право на получение областного материнского (семейного) капитала, их право на получение областного материнского (семейного) капитала прекращается и возникает у ребенка (детей в равных долях).</li>

                </ul>

                <div className="small-title">Правовые основания также зависят от нескольких условий:</div>
                <ul className="text">
                    <li>размер среднедушевого дохода семьи не должен превышать двукратную величину прожиточного минимума для трудоспособного населения, установленную в соответствии с законодательством Челябинской области во втором квартале года, предшествующего году подачи заявления  </li>
                    <li>при рождении третьего или последующих детей, начиная с 1 января 2012 года</li>
                </ul>

                <div className="small-title">Направления для распоряжения средствами областного материнского (семейного) капитала </div>
                <ul className="text">
                    <li>получение образования ребенком (детьми);</li>
                    <li>погашение основного долга и уплата процентов по кредитам или займам на приобретение (строительство) жилого помещения, предоставленным гражданам по кредитному договору или договору займа, в том числе обязательства по которому обеспечены ипотекой, заключенному с организацией, в том числе кредитной организацией; </li>
                    <li>приобретение товаров и услуг, предназначенных для социальной адаптации и интеграции в общество детей-инвалидов; </li>
                    <li>строительство, реконструкция объекта индивидуального жилищного строительства, осуществляемые гражданами без привлечения организации, осуществляющей строительство (реконструкцию) объекта индивидуального жилищного строительства, в том числе по договору строительного подряда;</li>
                    <li>подключение (технологическое присоединение) объекта индивидуального жилищного строительства к сети газораспределения, централизованным системам водоснабжения и (или) водоотведения, устройство бытовых колодцев и скважин для целей водоснабжения объекта индивидуального жилищного строительства;</li>
                    <li>приобретение садовых или огородных земельных участков, а также садовых домов;</li>
                    <li>оплата медицинских услуг, оказываемых родителю (родителям) и (или) ребенку (детям), в случае необходимости получения им (ими) медицинской помощи.</li>
                </ul>


                <div className="small-title">Нормативно правовые акты </div>
                <ul className="text">
                    <li><a target="_blank" href="http://minsoc74.ru/sites/default/files/postanovlenie_ot_26_iyunya_2013_g_n_108_p_v_red._ot_14.07.2021.docx ">Постановление Правительства Челябинской области от 26 июня 2013 г. N 108-П "Об Административном регламенте предоставления государственной услуги "Распоряжение средствами (частью средств) областного материнского (семейного) капитала" </a></li>
                    <li><a target="_blank" href="http://docs.cntd.ru/document/453120368">Постановление Губернатора Челябинской области от 02.08.2012 г. N 211 "О Порядке учета и исчисления величины среднедушевого дохода семьи, дающего право на получение пособия на ребенка и дополнительных мер социальной поддержки семей, имеющих детей". </a></li>
                    <li><a target="_blank" href="http://docs.cntd.ru/document/453114122 ">Закон Челябинской области от 15.12.2011 г. N 251-ЗО "О дополнительных мерах социальной поддержки семей, имеющих детей, в Челябинской области" </a></li>
                </ul>

                <div className="small-title">Основание для отказа</div>
                <ul className="text">
                    <li>совершение заявителем в отношении своего ребенка (детей) умышленного преступления, относящегося к преступлениям против личности;</li>
                    <li>отобрание у заявителя ребенка, в связи с рождением (усыновлением) которого возникло право на получение государственной услуги;</li>
                    <li>достижение несовершеннолетним ребенком (детьми) возраста 18 лет либо достижение совершеннолетним ребенком (детьми), обучающимся (обучающимися) по очной форме обучения в образовательном учреждении до окончания такого обучения, возраста 23 лет;</li>
                    <li>лишение заявителя родительских прав в отношении ребенка (детей)</li>
                    <li>превышение размера среднедушевого дохода семьи над величиной прожиточного минимума на душу населения в Челябинской области;</li>
                    <li>отмена усыновления заявителем в отношении ребенка, в связи с усыновлением которого возникло право на предоставление государственной услуги;</li>
                    <li>снятие получателя государственной услуги с регистрационного учета по месту жительства (месту пребывания) на территории Челябинской области;</li>
                    <li>ограничение заявителя в родительских правах в отношении ребенка, в связи с рождением (усыновлением) которого возникло право на предоставление государственной услуги;</li>
                    <li>смерть ребенка, в связи с рождением (усыновлением) которого возникло право на получение государственной услуги;</li>
                    <li>наличие противоречий в документах, представляемых заявителем, либо представление недостоверных сведений</li>
                </ul>
                <p>Подробные сведения о пособии размещены
                    <Link target={"_blank"} to={{ pathname: "https://minsoc.gov74.ru/minsoc/other/oblastnoy-materinskiy-semeynyy-kapital.htm " }}> по ссылке</Link>
                </p>
                <div className="small-title">Заявление на предоставление государственной услуги, можно подать в орган социальной защиты населения по месту жительства (регистрации) следующими способами:</div>
                <ul className="text">
                    <li>посредством личного обращения;</li>
                    <li>по почте заказным письмом (с описью вложенных документов и уведомлением о вручении);</li>
                    <li>в форме электронного документа с использованием <Link target={"_blank"} to={{ pathname: "https://gosuslugi74.ru/pgu/services/info/targets.htm?id=15522@egServiceTarget&serviceId=9438@egService" }}> регионального портала</Link> </li>
                </ul>

                <p>При этом существует перечень документов, который заявитель обязан представить лично (например, при реализации права на погашение долга по ипотечному кредиту на приобретение жилья заявителю необходимо самостоятельно предъявить оригинал справки кредитора (заимодавца) о размерах остатка по такой задолженности) в орган социальной защиты населения по месту жительства (регистрации).</p>
            </div>

        </div>

    </Container >)
}

export default Act1;