import React, { useState } from 'react';
import { Container, Form, FormGroup, Label, Input, Col, Row, Table,Button } from 'reactstrap';
import './Action.css'

const Act29 = () => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><a href="/">Главная</a></li>
                <li className="breadcrumb-item"><a href="/socialservices">Меры социальной поддержки детей-сирот и детей, оставшихся без попечения родителей</a></li>
            </ol>
        </nav>
        <div className="name">Полное государственное обеспечение детей-сирот и детей, оставшихся без попечения родителей, лиц из их числа, а также лиц, потерявших в период обучения обоих родителей или единственного родителя</div>

        <div className='white-card'>

            <div className='card-body'>
                Полное государственное обеспечение детей-сирот и детей, оставшихся без попечения родителей, лиц из их числа, а также лиц, потерявших в период обучения обоих родителей или единственного родителя (предусматривает предоставление им за время их пребывания в соответствующей организации бесплатного питания, бесплатного комплекта одежды, обуви и мягкого инвентаря, бесплатных предметов хозяйственного обихода и личной гигиены, проживания в жилом помещении без взимания платы или возмещение их полной стоимости в случаях, предусмотренных настоящим Законом, а также бесплатное оказание медицинской помощи)
            </div>

        </div>

    </Container >)
}

export default Act29;