import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                <li className="breadcrumb-item" aria-current="page"><Link to="/certificateBase" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами</Link></li>
            </ol>

        </nav>

        <div className="name"> «Присвоение звания «Ветеран труда Челябинской области» и выдача удостоверения «Ветеран труда Челябинской области»</div>
        <div className='white-card'>

            <div className='card-body'>
                <div className="small-title">круг заявителей</div>
                <p style={{textIndent: '1.5em', textAlign: 'justify', marginTop:'1em', marginBottom:'0em'}}>Граждане Российской Федерации, постоянно проживающие на территории Челябинской области, которым установлена (назначена) страховая пенсия по старости в соответствии с Федеральным законом «О страховых пенсиях» или которые достигли возраста 55 и 60 лет (соответственно женщины и мужчины):</p>
                <ol className="text" style={{ paddingTop: '0.25em',marginLeft: '3em'}}>
                    <li>имеющие страховой стаж не менее 40 лет для мужчин и 35 лет для женщин, награжденные наградами Челябинской области, наградами Законодательного Собрания Челябинской области, почетными грамотами Законодательного Собрания Челябинской области и Губернатора Челябинской области;</li>
                    <li>родившие пять и более детей и воспитавшие их до достижения ими возраста восьми лет</li>
                </ol>
                <div className="small-title">срок предоставления государственной услуги</div>
                <ul className="text">
                    <li>Срок предоставления государственной услуги со дня регистрации заявления о предоставлении государственной услуги до вынесения решения не может превышать 30 календарных дней</li>
                </ul>

                <div className="small-title">исчерпывающий перечень документов</div>
                <ul className="text">
<li>1) заявление;</li>
                    <li>2) документ, удостоверяющий личность (при обращении представителя заявителя - документы, подтверждающие его полномочия);</li>
                    <li>3) фотография размером 3 х 4 сантиметра;</li>
                    <li>4) документы, подтверждающие право на присвоение звания «Ветеран труда Челябинской области»:</li>
                    <li>4.1) для лиц, указанных в пункте 1 «Круга заявителей», которым установлена (назначена) страховая пенсия по старости в соответствии с Федеральным законом «О страховых пенсиях»:</li>
                    <li>а) справка, выданная органами, осуществляющими пенсионное обеспечение, либо сведения, полученные от указанных органов в порядке межведомственного информационного взаимодействия, об установлении (назначении) пенсии или назначении пожизненного содержания за работу (службу) и о наличии страхового стажа, исчисленного в соответствии с Федеральным законом «О страховых пенсиях», не менее 40 лет для мужчин и 35 лет для женщин; а в случаях, когда право на присвоение указанного звания возникает с учетом периодов трудовой или иной деятельности, наступивших после даты установления (назначения) пенсии в соответствии с Федеральным законом «О страховых пенсиях», - также выписка из индивидуального лицевого счета застрахованного лица;</li>
                    <li>б) удостоверения (дубликаты удостоверений) к наградам Челябинской области, наградам Законодательного Собрания Челябинской области;</li>
                    <li>в) почетные грамоты Законодательного Собрания Челябинской области и Губернатора Челябинской области.</li>
                    <li>В случае утраты наградных документов факт награждения может быть подтвержден справками, выданными органами государственной власти Челябинской области, от имени которых производилось награждение;</li>
                    <li>4.2) для лиц, указанных в пункте 1 «Круга заявителей», достигших возраста 55 и 60 лет (соответственно женщины и мужчины):</li>
                    <li>а) справка, выданная работодателем, о наличии страхового стажа, исчисленного в соответствии с Федеральным законом «О страховых пенсиях», не менее 40 лет для мужчин и 35 лет для женщин;</li>
                    <li>б) удостоверения (дубликаты удостоверений) к наградам Челябинской области, наградам Законодательного Собрания Челябинской области;</li>
                    <li>в) почетные грамоты Законодательного Собрания Челябинской области и Губернатора Челябинской области.</li>
                    <p style={{marginBottom:'0'}}>В случае утраты наградных документов факт награждения может быть подтвержден справками, выданными органами государственной власти Челябинской области, от имени которых производилось награждение;</p>
<li>4.3) для лиц, указанных в пункте 2 «Круга заявителей», - справка, выданная органами, осуществляющими пенсионное обеспечение, об установлении (назначении) пенсии или назначении пожизненного содержания за работу (службу) и о рождении пяти и более детей и воспитании их до достижения ими возраста восьми лет.</li>
                    <text>Сведения о продолжительности страхового (трудового) стажа могут быть запрошены органами социальной защиты населения в рамках межведомственного информационного взаимодействия в уполномоченных органах, осуществляющих пенсионное обеспечение</text>
                </ul>

                <div className="small-title">результат предоставления государственной услуги</div>
                <ul className="text">
                    <li>Присвоение звания «Ветеран труда Челябинской области» и выдача удостоверения «Ветеран труда Челябинской области»</li>
                </ul>
                <div className="small-title">Основания для отказа в приеме документов </div>
                <ul className="text">
                    <li>представление неполного пакета документов, установленных Административным регламентом предоставления данной услуги;</li>
                    <li>документы имеют подчистки, приписки, зачеркнутые слова и иные не оговоренные в них исправления</li>
                </ul>

                <div className="small-title">основания для отказа в предоставлении государственной услуги</div>
                <ul className="text">
                    <li>отсутствие гражданства Российской Федерации;</li>
                    <li>отсутствие необходимой продолжительности страхового стажа (для лиц, указанных в пункте 1 «Круга заявителей»);</li>
                    <li>отсутствие факта рождения пяти и более детей и воспитания их до достижения ими возраста восьми лет (для лиц, указанных в пункте 2 «Круга заявителей»);</li>
                    <li>не установлена (не назначена) страховая пенсия по старости в соответствии с Федеральным законом «О страховых пенсиях» (не применяется в отношении граждан, достигших возраста 55 и 60 лет (соответственно женщины и мужчины);</li>
                    <li>недостижение возраста 55 и 60 лет (соответственно женщины и мужчины) (не применяется в отношении граждан, которым установлена (назначена) страховая пенсия по старости в соответствии с Федеральным законом «О страховых пенсиях»);</li>
                    <li>наличие звания «Ветеран труда»;</li>
                    <li>отсутствие наград Челябинской области, наград Законодательного Собрания Челябинской области, почетных грамот Законодательного Собрания Челябинской области и Губернатора Челябинской области (для лиц, указанных в пункте 1 «Круга заявителей»)</li>
                </ul>

                <div className="small-title">Право на досудебное (внесудебное) обжалование решений и действий (бездействия) должностных лиц:</div>
                <ul className="text">
                    <li>В досудебном (внесудебном) порядке заявители могут обжаловать действия (бездействие) органов социальной защиты, Министерства социальных отношений Челябинской области, многофункционального центра, а также их должностных лиц, государственных гражданских служащих, муниципальных служащих, работников и принимаемые ими решения при предоставлении государственной услуги</li>
                </ul>

                <div className="small-title"> <a target="_blank" rel="noopener noreferrer" href="http://minsoc74.ru/sites/default/files/2_zayavlenie_vtcho.doc">Форма заявления</a></div>

            </div>

        </div>
    </Container >)
}

export default Act1;