import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';


export class SocialServices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first: true,
            second: false,
            third: false,
            list: ['24', '25', '26', '27', '28']
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleStartClick = this.handleStartClick.bind(this);
        this.renderList = this.renderList.bind(this);
    }

    renderList() {
        const { list } = this.state;

        const Baselist = ['24', '25', '26', '27', '28'];

        Baselist.forEach(item => {
            let u = document.getElementById(`action_id_${item}`);
            u.style.display = 'none';
        });

        list.forEach(item => {
            let u = document.getElementById(`action_id_${item}`);
            u.style.display = 'block';
        });

    }

    componentDidMount() {
        this.renderList();
    }

    componentDidUpdate() {
        this.renderList();
    }

    handleChange(e) {
        const VALUE = e.currentTarget.value;
        if (VALUE.indexOf('state') > -1) {
            const listVAl = VALUE.split(':'),
                id = listVAl[1];

            switch (id) {
                case 'second':
                    this.setState({ first: false, second: true, third: false, list: ['24', '25', '26', '27'] });
                    break;
                case 'third':
                    this.setState({ first: false, second: false, third: true, list: ['28'] });
                    break;
            }
        }
        else {
            const listVAl = VALUE.split(';');
            this.setState({ list: listVAl });
        }
    }


    handleStartClick(e) {
        this.setState({
            first: true,
            second: false,
            third: false,
            list: ['24', '25', '26', '27', '28']
        })
    }

    render() {
        const { first, second, third } = this.state;
        return (
            <Container>
                <Row>
                    <Col md='12' lg='12'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb pl-0">
                                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                                <li className="breadcrumb-item active"><a onClick={this.handleStartClick} style={{ cursor: 'pointer' }}>Социальное обслуживание граждан</a></li>
                            </ol>
                        </nav>
                    </Col>
                </Row>
                <Row>
                    <div className='head-list-child mt-30'>
                        <div className='title-head-list title-head-list-child'>Социальное обслуживание граждан</div>
                    </div>
                    <div className='white-box-child last pt-0'>
                        <Col md='12' lg='7' className='mb-3'>
                            {first ? (<><div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Укажите, к какой категории вы относитесь</label>
                                    </div>

                                    <div className="element_string attribute_0">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input value='state:second' type="radio" id="attribute_value0" name="attribute_0" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_0" htmlFor="attribute_value0">Инвалид</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input value='state:second' type="radio" id="attribute_value1" name="attribute_0" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_1" htmlFor="attribute_value1">Пенсионер, получающий пенсию по старости</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input value='state:third' type="radio" id="attribute_value2" name="attribute_0" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_2" htmlFor="attribute_value2">Ребенок-инвалид</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                                <div className="element_group">
                                    <div className="group_string">
                                        <div className="title-group">
                                            <label>Укажите жизненную ситуацию</label>
                                        </div>

                                        <div className="element_string attribute_1">
                                            <div className="custom-control custom-radio list_item_block">
                                                <input value='19' type="radio" id="attribute_value3" name="attribute_1" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_3" htmlFor="attribute_value3">Вы желаете получить социальное обслуживание</label>
                                            </div>

                                            <div className="custom-control custom-radio list_item_block">
                                                <input value="19" type="radio" id="attribute_value4" name="attribute_1" className="custom-control-input" />
                                                <label className="custom-control-label label-group" id="attribute_value_label_4" htmlFor="attribute_value4">Ничего не указано</label>
                                            </div>
                                        </div>
                                    </div>
                                </div></>
                            )
                                : null
                            }

                            {second ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Выберите вид социального обслуживания </label>
                                    </div>

                                    <div className="element_string">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input value="27" type="radio" id="attribute_value5" name="attribute_2" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_5" htmlFor="attribute_value5">Социальное обслуживание на дому</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input value="24;25;26" type="radio" id="attribute_value6" name="attribute_2" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_6" htmlFor="attribute_value6">Социальное обслуживание в учреждениях социальной защиты</label>
                                        </div>

                                    </div>
                                </div>
                            </div>)
                                : null}

                            {third ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Выберите вид социального обслуживания </label>
                                    </div>

                                    <div className="element_string">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input value="28" type="radio" id="attribute_value7" name="attribute_4" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_7" htmlFor="attribute_value7">Социальное обслуживание в учреждениях социальной защиты</label>
                                        </div>

                                    </div>
                                </div>
                            </div>)
                                : null}
                        </Col>

                        <Col md='12' lg='5' className='mb-3'>
                            <div className="element_group element-group-usl">
                                <div className="title-group title-group-usl">
                                    <label>Список услуг</label>
                                </div>
                                <div className="element_string attribute_0">
                                    <ul className="list-group list-group-flush" id="actions_list">
                                        <li id="action_id_24" className="">
                                            <Link to="/action/24">Предоставление социальных услуг поставщиками социальных услуг в стационарной форме</Link>
                                        </li>
                                        <li id="action_id_25" className="">
                                            <Link to="/action/25">Предоставление социальных услуг поставщиками социальных услуг в полустационарной форме в условиях временного приюта</Link>
                                        </li>
                                        <li id="action_id_26" className="">
                                            <Link to="/action/26">Предоставление социальных услуг поставщиками социальных услуг в полустационарной форме в условиях дневного пребывания.</Link>
                                        </li>
                                        <li id="action_id_27" className="">
                                            <Link to="/action/27">Предоставление социальных услуг поставщиками социальных услуг в форме социального обслуживания на дому</Link>
                                        </li>
                                        <li id="action_id_28" className="">
                                            <Link to="/action/28">Предоставление социальных услуг поставщиками социальных услуг в стационарной форме.</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </div>
                </Row>
            </Container>
        );
    }
}