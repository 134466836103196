import React, { useState } from 'react';
import { Container, Form, FormGroup, Label, Input, Col, Row, Table, Button } from 'reactstrap';
import './Action.css'

const Act23 = () => {
    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><a href="/">Главная</a></li>
                <li className="breadcrumb-item"><a href="/socialservices">Меры социальной поддержки детей-сирот и детей, оставшихся без попечения родителей</a></li>
            </ol>
        </nav>
        <div className="name">Бесплатный проезд на городском, пригородном, в сельской местности - на внутрирайонном транспорте (кроме такси), а также проезд один раз в год к месту жительства и обратно к месту учебы</div>

        <div className='white-card'>

            <div className='card-body'>
                Бесплатный проезд на городском, пригородном, в сельской местности - на внутрирайонном транспорте (кроме такси), а также проезд один раз в год к месту жительства и обратно к месту учебы  (для реализации права бесплатного проезда предоставляется ежемесячная денежная выплата в размере 262,65 рубля в месяц. Данный размер денежных средств подлежит индексации в соответствии с коэффициентом, устанавливаемым законом Челябинской области об областном бюджете на очередной финансовый год и плановый период).
            </div>

        </div>

    </Container>)
}

export default Act23;