import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                <li className="breadcrumb-item active" aria-current="page"><Link to="/certificateBase" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами</Link></li>
            </ol>
        </nav>

        <div className='white-card'>

            <div className='card-body'>
                <p>Членам экипажей судов транспортного флота, интернированным в начале Великой Отечественной войны в портах других государств, которым ранее удостоверение ветерана Великой Отечественной войны не выдавалось, удостоверение выдается соответствующими федеральными органами исполнительной власти, осуществляющими функции упраздненных министерств и ведомств.</p>
                <a>Ссылка на подпункт «д» пункта 2 Инструкции о порядке заполнения, выдачи и учета удостоверений ветерана Великой Отечественной войны, утвержденной постановлением Правительства Российской Федерации от 05.10.1999 г. № 1122</a>
                <p className="mt-3"><Link to="/action/3" style={{ cursor: 'pointer' }}>Общая информация о выдаче удостоверения ветерана Великой Отечественной войны</Link></p>
            </div>

        </div>

    </Container >)
}

export default Act1;