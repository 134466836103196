import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import { Link } from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/children"> Социальная поддержка семей с детьми</Link></li>
            </ol>
        </nav>
        <div className="name">Назначение и осуществление ежемесячной выплаты в связи с рождением (усыновлением) первого ребенка</div>

        <div className='white-card'>

            <div className='card-body'>
                <div className="small-title">Кому предоставляется выплата</div>
                <ul className="text">
                    <li>Гражданам Российской Федерации, постоянно проживающим на территории страны:</li>
                    <li>женщинам, родившим (усыновившим) первого ребёнка</li>
                    <li>отцу (усыновителю) либо опекуну ребёнка в случае смерти женщины,</li>

                </ul>
                <div className="small-title">Условия предоставления выплаты</div>
                <ul className="text">
                    <li>Первый ребёнок рожден с 1 января 2018 года и является гражданином Российской Федерации</li>
                    <li>Размер среднедушевого дохода семьи не превышает 2-кратную величину прожиточного минимума трудоспособного населения, установленную в субъекте Российской Федерации </li>
                </ul>
                <div className="small-title">Срок подачи заявления</div>
                <ul className="text">
                    <li>Заявление может быть подано в любое время в течение трех лет со дня рождения (усыновления) первого ребенка.
                    Если заявление о назначении выплаты подано не позднее шести месяцев со дня рождения ребенка, выплата осуществляется со дня рождения ребенка.
                    В остальных случаях выплата осуществляется со дня обращения за ее назначением.</li>
                </ul>
                <div className="small-title"> Куда обращаться за назначением выплаты</div>
                <ul className="text">
                    <li>В орган социальной защиты населения по месту жительства (либо месту фактического проживания) или через многофункциональный центр</li>
                    <li>Подать заявление также можно через  <a target="_blank" href="https://www.gosuslugi.ru/600165/1/form?_=1643868454766 "> Единый портал государственных услуг</a></li>
                </ul>
                <div className="small-title">Нормативно правовые акты</div>
                <ul className="text">
                    <li><a target="_blank" href="http://www.kremlin.ru/acts/bank/42647 ">Федеральный закон от 28.12.2017 N 418-ФЗ «О ежемесячных выплатах семьям, имеющим детей» </a>  </li>
                    <li><Link target={"_blank"} to={{ pathname: "http://publication.pravo.gov.ru/Document/View/0001201801120002"}}>Порядок, утвержденный Приказом Министерства труда и социальной защиты от 29.12.2017 г. № 889н «Об утверждении Порядка осуществления ежемесячных выплат в связи с рождением (усыновлением) первого ребёнка и (или) второго ребёнка, обращения за назначением указанных выплат, а также перечня документов (сведений), необходимых для назначения ежемесячных выплат в связи с рождением (усыновлением) первого или (или) второго ребёнка»</Link></li>
                    <li><a target="_blank" href="http://publication.pravo.gov.ru/Document/View/0001201904030045">Приказ Министерства труда и социальной защиты РФ от 4 февраля 2019 г. N 55н "Об утверждении Административного регламента по предоставлению органами государственной власти субъектов Российской Федерации государственной услуги в сфере переданных полномочий Российской Федерации по назначению ежемесячной выплаты в связи с рождением (усыновлением) первого ребенка" </a></li>
                </ul>
                <div className="small-title">Основание для отказа</div>
                <ul className="text">
                    <li>Лишение заявителя родительских прав в отношении ребенка, в связи с рождением (усыновлением) которого у заявителя возникло право на получение ежемесячной выплаты   </li>
                    <li>Нахождение ребенка, в связи с рождением (усыновлением) которого у заявителя возникло право на получение ежемесячной выплаты, на полном государственном обеспечении   </li>
                </ul>
                <p>Исчерпывающий перечень документов, необходимых для назначения указанной выплаты  установлен в<a target="_blank" href="http://pravo.gov.ru/proxy/ips/?docbody=&nd=102455798&intelsearch=418-%F4%E7 ">Приложении 1 к Порядку утвержденному приказом Минтруда РФ от 29.12.2017 г. № 889н</a></p>
                <p>Подробные сведения размещены <a target="_blank" href="https://minsoc.gov74.ru/minsoc/other/predostavlenie-ezhemesyachnoy-vyplaty-v-svyazi-s-rozhdeniem-pervogo-rebyonka.htm">здесь</a></p>
            </div>

        </div>

    </Container >)
}

export default Act1;