import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css';
import {
    Link
} from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/certificat">Получение удостоверений</Link></li>
                <li className="breadcrumb-item active" aria-current="page"><Link to="/certificateRadiation" style={{ cursor: 'pointer' }}>Получение удостоверения гражданами, пострадавшими от воздействия радиации</Link></li>
            </ol>
        </nav>
        <div className="name">Пострадали от ВР вследствие катастрофы на ЧАЭС </div>

        <div className='white-card'>

            <div className='card-body'>
                <text>принимали участие в ликвидации  катастрофы на ЧАЭС</text>
                <div className="small-title">Перечень документов:</div>
                <ul className="text">
                    <li>Паспорт РФ;</li>
                    <li>Заявление и  согласие на обработку персональных данных;</li>
                    <li>Документ, подтверждающий участие граждан в работах по ликвидации последствий катастрофы на ЧАЭС:
                    а) командировочное удостоверение с отметками о пребывании в населенных пунктах (пункте), находящихся в зоне отчуждения;
                    б) справка об участии в работах в зоне отчуждения;
                    в) табель учета рабочего времени в зоне отчуждения;- документ, подтверждающий участие в работах по объекту «Укрытие», которыми могут быть:а) выписка из приказа по воинским частям,; б) выписка из журналов выдачи дозиметров и контроля доз по проходу на объект «Укрытие»; выписка из наряда допуска на проведение работ на объекте «Укрытие»; пропуска для прохода на объект «Укрытие» для ведения соответствующих работ:
                    г) справка о времени работы на объекте «Укрытие», табели учета рабочего времени,  на объекте «Укрытие», выданные администрациями ЧАЭС, либо Комплексной экспедицией Института им. И.В. Курчатова, либо Межотраслевым научно-техническим центром «Укрытие» Национальной академии наук
                        </li>


                </ul>
                <text>проживали  на загрязенной территории</text>
                <div className="small-title">Перечень документов:</div>
                <ul className="text">
                    <li>Паспорт РФ;</li>
                    <li>Заявление согласие на обработку персональных данных;</li>
                    <li>Выписки из домовых книг, архивов ЖЭУ, жилищно-коммунальных отделов и справки паспортных столов;</li>
                </ul>
            </div>

        </div>

    </Container >)
}

export default Act1;