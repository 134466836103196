import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Action.css'
import { Link } from 'react-router-dom';

const Act1 = (props) => {

    return (<Container className='measure'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                <li className="breadcrumb-item"><Link to="/children"> Социальная поддержка семей с детьми</Link></li>
            </ol>
        </nav>
        <div className="name">Предоставление единовременной денежной выплаты при передаче детей-сирот и детей, оставшихся без попечения родителей, на воспитание в семью</div>

        <div className='white-card'>

            <div className='card-body'>
                <text>Право  имеют граждане Российской Федерации, постоянно проживающие на территории Челябинской области (далее именуются - заявители)         </text>
                <div className="small-title">При соблюдении следующих условий:</div>
                <ul className="text">
                    <li>наличие у усыновителей, опекунов (попечителей), приемных родителей регистрации по месту жительства на территории Челябинской области;</li>
                    <li>усыновление (удочерение) ребенка-инвалида, ребенка в возрасте старше десяти лет, а также детей, являющихся братьями и (или) сестрами, имеющих гражданство Российской Федерации и находящихся под надзором в организациях для детей-сирот и детей, оставшихся без попечения родителей, расположенных на территории Челябинской области (для усыновителей);</li>
                    <li>вынесение решения суда об усыновлении (удочерении) и его вступление в законную силу после 1 января 2016 года (для усыновителей);</li>
                    <li>передача под опеку (попечительство) и на воспитание в приемную семью ребенка в возрасте старше десяти лет, а также ребенка, переданного третьим или последующим под опеку (попечительство) и на воспитание в приемную семью (при условии пребывания в семье опекуна (попечителя), приемного родителя двух и более детей-сирот и детей, оставшихся без попечения родителей), имеющих гражданство Российской Федерации и находящихся под надзором в организациях для детей-сирот и детей, оставшихся без попеченияродителей, расположенных на территории Челябинской области (для опекунов (попечителей), приемных родителей);</li>
                    <li>отсутствие между усыновленным ребенком, а также ребенком, переданным под опеку (попечительство) и на воспитание в приемную семью, и усыновителем, опекуном (попечителем), приемным родителем близких родственных связей.</li>
                </ul>

                <div className="small-title">Право на получение единовременной денежной выплаты возникает у заявителей при соблюдении следующих условий:</div>
                <ul className="text">
                    <li>в отношении усыновителей - соблюдение условий, указанных в подпунктах 1-3 и 5 пункта 5 Административного регламента;</li>
                    <li>в отношении опекунов (попечителей) и приемных родителей - соблюдение условий, указанных в подпунктах 1, 4 и 5 пункта 5 Административного регламента.</li>
                </ul>

                <div className="small-title">Срок предоставления:</div>
                <ul className="text">
                    <li>Выплата назначается не позднее чем через 10 рабочих дней со дня представления заявления о назначении единовременной денежной выплаты и всех необходимых документов, если обращение за ней последовало не позднее шести месяцев со дня вступления в законную силу решения суда об усыновлении (удочерении), со дня вынесения органом опеки и попечительства решения об установлении опеки (попечительства), со дня заключения договора о приемной семье.</li>
                </ul>

                <div className="small-title">Размер выплаты устанавливается в размере 100 000 рублей и выплачивается двумя частями в следующем порядке:</div>
                <ul className="text">
                    <li>50 000 рублей (1 часть) - в течение месяца, если обращение последовало не позднее шести месяцев со дня установления права;</li>
                    <li>50 000 рублей (2 часть) - по истечении 12 месяцев со дня установления права.</li>
                </ul>

                <div className="small-title"> Куда обращаться за назначением</div>
                <ul className="text">
                    <li>В орган социальной защиты населения по месту жительства или через многофункциональный центр</li>
                </ul>

                <div className="small-title">Нормативно правовые акты</div>
                <ul className="text">
                    <li><a target="_blank" href="http://docs.cntd.ru/document/819023006">Закон Челябинской области от 25 октября 2007 г. N 212-ЗО "О мерах социальной поддержки детей-сирот и детей, оставшихся без попечения родителей, вознаграждении, причитающемся приемному родителю, и социальных гарантиях приемной семье" </a></li>
                    <li><a target="_blank" href="http://docs.cntd.ru/document/446264918">Постановление Правительства Челябинской области от 29 марта 2017 г. N 133-П "Об Административном регламенте предоставления государственной услуги "Предоставление единовременной денежной выплаты при передаче детей-сирот и детей, оставшихся без попечения родителей, на воспитание в семью" и внесении изменений в постановление Правительства Челябинской области от 20.04.2016 г. N 175-П" (с изменениями и дополнениями) </a></li>
                    <li><a target="_blank" href="http://www.kremlin.ru/acts/bank/27263 ">Федеральный закон от 24 апреля 2008 года № 48-ФЗ «Об опеке и попечительстве» </a> </li>
                </ul>
                <div className="small-title">Основание для отказа</div>
                <ul className="text">
                    <li>отмена усыновления (удочерения) в судебном порядке</li>
                    <li>неполное представление необходимых документов</li>
                    <li>достижение ребенком, на которого назначается единовременная денежная выплата, возраста 18 лет</li>
                    <li>смерть усыновителя, опекуна (попечителя), приемных родителей (единственного приемного родителя);</li>
                    <li>переезд усыновителя на постоянное место жительства за пределы Челябинской области</li>
                    <li>помещение несовершеннолетнего в учреждение, исполняющее наказание в виде лишения свободы</li>
                    <li>освобождение либо отстранение опекунов (попечителей) от исполнения ими своих обязанностей, расторжение договора о приемной семье</li>
                    <li>объявление ребенка, на которого назначается единовременная денежная выплата, в розыск или нахождение его в розыске</li>
                    <li>несоответствие документов, представленных для предоставления государственной услуги, предъявляемым к ним требованиям</li>
                    <li>пропуск срока обращения за предоставлением единовременной денежной выплаты</li>
                    <li>смерть ребенка, на которого назначается единовременная денежная выплата </li>
                </ul>

                <p>Подробные сведения размещены <a target="_blank" href="https://gosuslugi74.ru/pgu/services/info/targets.htm?id=16882@egServiceTarget&serviceId=10509@egService  ">здесь</a></p>
                <p>Подать заявление можно <a target="_blank" href="https://gosuslugi74.ru/pgu/services/info/targets.htm?id=16882@egServiceTarget&serviceId=10509@egService ">здесь</a></p>
            </div>

        </div>

    </Container >)
}

export default Act1;