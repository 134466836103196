import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import {
    Link
} from 'react-router-dom';


export class Jku extends Component {

    constructor(props) {
        super(props);
        this.state = {
            first: true,
            second: false,
            third: false,
            fourth: false,
            fifth: false,
            list: ['18', '19', '20', '21', '22', '23']
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleStartClick = this.handleStartClick.bind(this);
        this.renderList = this.renderList.bind(this);
    }

    componentDidMount() {
        this.renderList();
    }

    componentDidUpdate() {
        this.renderList();
    }

    renderList() {
        const { list } = this.state;

        const Baselist = ['18', '19', '20', '21', '22', '23'];

        Baselist.forEach(item => {
            let u = document.getElementById(`action_id_${item}`);
            u.style.display = 'none';
        });

        list.forEach(item => {
            let u = document.getElementById(`action_id_${item}`);
            u.style.display = 'block';
        });

    }

    handleChange(e) {
        const VALUE = e.currentTarget.value;
        if (VALUE.indexOf('state') > -1) {
            const listVAl = VALUE.split(':'),
                id = listVAl[1];

            switch (id) {
                case 'second':
                    this.setState({ first: false, second: true, third: false, fourth: false, fifth: false, sixth: false, list: ['19', '20', '21', '22', '23'] });
                    break;
                case 'third':
                    this.setState({ first: false, second: false, third: true, fourth: false, fifth: false, sixth: false, list: ['20', '21', '22', '23'] });
                    break;
                case 'fourth':
                    this.setState({ first: false, second: false, third: false, fourth: true, fifth: false, sixth: false, list: ['21', '22', '23'] });
                    break;
                case 'fifth':
                    this.setState({ first: false, second: false, third: false, fourth: false, fifth: true, sixth: false, list: ['22', '23'] });
                    break;
            }
        }
        else {
            const listVAl = VALUE.split(';');
            this.setState({ list: listVAl });
        }
    }

    handleStartClick(e) {
        this.setState({
            first: true,
            second: false,
            third: false,
            fourth: false,
            fifth: false,
            sixth: false,
            list: ['18', '19', '20', '21', '22', '23']
        })
    }

    render() {
        const { first, second, third, fourth, fifth, sixth } = this.state;
        return (
            <Container>
                <Row>
                    <Col md='12' lg='12'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb pl-0">
                                <li className="breadcrumb-item"><Link to="/"> Главная</Link></li>
                                <li className="breadcrumb-item"><Link to="/measure">Меры социальной поддержки отдельным категориям граждан</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"><a onClick={this.handleStartClick} style={{ cursor: 'pointer' }}>Субсидии на ЖКУ</a></li>
                            </ol>
                        </nav>
                    </Col>
                </Row>
                <Row>
                    <div className='head-list-child mt-30'>
                        <div className='title-head-list title-head-list-child'>Субсидии на ЖКУ</div>
                    </div>
                    <div className='white-box-child last pt-0'>
                        <Col md='12' lg='7' className='mb-3'>

                            {first ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Укажите Ваше гражданство и гражданство членов Вашей семьи</label>
                                    </div>

                                    <div className="element_string attribute_30">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input value='state:second' type="radio" id="attribute_value59" name="attribute_30" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_59" htmlFor="attribute_value59">гражданин РФ</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input value="18" type="radio" id="attribute_value60" name="attribute_30" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_60" htmlFor="attribute_value60">гражданин иностранного государства</label>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null
                            }

                            {second ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Наличие постоянной регистрации по месту жительства</label>
                                    </div>

                                    <div className="element_string attribute_1">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input value='state:third' type="radio" id="attribute_value1" name="attribute_1" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_1" htmlFor="attribute_value1">да</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input value="19" type="radio" id="attribute_value2" name="attribute_1" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_2" htmlFor="attribute_value2">нет</label>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null
                            }

                            {third ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Выберите основание пользования жилым помещением</label>
                                    </div>

                                    <div className="element_string">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input value="state:fourth" type="radio" id="attribute_value3" name="attribute_2" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_3" htmlFor="attribute_value3">собственник жилого помещения (части жилого помещения)</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input value="state:fourth" type="radio" id="attribute_value4" name="attribute_2" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_4" htmlFor="attribute_value4">пользователь жилого помещения государственного и муниципального жилищного фонда</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input value="state:fourth" type="radio" id="attribute_value5" name="attribute_2" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_5" htmlFor="attribute_value5">наниматель жилого помещения по договору найма в частном жилищном фонде</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input value="state:fourth" type="radio" id="attribute_value6" name="attribute_2" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_6" htmlFor="attribute_value6">член жилищного или жилищно-строительного кооператива</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input value="state:fourth" type="radio" id="attribute_value7" name="attribute_2" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_7" htmlFor="attribute_value7">член семьи нанимателя жилого помещения по договору найма в частном жилищном фонде, члены жилищного или жилищно-строительного кооператива, собственники жилого помещения проходят военную службу по призыву в Вооруженных Силах Российской Федерации, других войсках, воинских формированиях и органах, созданных в соответствии с законодательством Российской Федерации, либо осуждены к лишению свободы, либо признаны безвестно отсутствующими, либо умерли или объявлены умершими, либо находятся на принудительном лечении по решению суда, субсидии предоставляются членам их семей при условии, что данные члены семей продолжают постоянно проживать в ранее занимаемых совместно с этими гражданами жилых помещениях</label>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                                : null}

                            {fourth ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Наличие задолженности за оплату ЖКУ</label>
                                    </div>

                                    <div className="element_string">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input value="21" type="radio" id="attribute_value8" name="attribute_3" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_8" htmlFor="attribute_value8">да</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input value="state:fifth" type="radio" id="attribute_value9" name="attribute_3" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_9" htmlFor="attribute_value9">нет (задолженность отсутствует либо заключено и (или) выполняется  соглашение  по погашению задолженности за оплату жилого помещения и коммунальных услуг.</label>
                                        </div>

                                    </div>
                                </div>
                            </div>)
                                : null}

                            {fifth ? (<div className="element_group">
                                <div className="group_string">
                                    <div className="title-group">
                                        <label>Укажите какую часть дохода семья тратит на оплату услуг ЖКУ</label>
                                    </div>

                                    <div className="element_string">
                                        <div className="custom-control custom-radio list_item_block">
                                            <input value="23" type="radio" id="attribute_value10" name="attribute_4" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_10" htmlFor="attribute_value10">более 22 %</label>
                                        </div>

                                        <div className="custom-control custom-radio list_item_block">
                                            <input value="22" type="radio" id="attribute_value11" name="attribute_4" className="custom-control-input" onChange={this.handleChange} />
                                            <label className="custom-control-label label-group" id="attribute_value_label_11" htmlFor="attribute_value11">менее 22% <a target="_blank" href="http://minsoc74.ru/oblastnoe-zakonodatelstvo">подробнее</a></label>
                                        </div>

                                    </div>
                                </div>
                            </div>)
                                : null}

                        </Col>
                        <Col md='12' lg='5' className='mb-3'>
                            <div className="element_group element-group-usl">
                                <div className="title-group title-group-usl">
                                    <label>Список услуг</label>
                                </div>
                                <div className="element_string attribute_0">
                                    <ul className="list-group list-group-flush" id="actions_list">
                                        <li id="action_id_18" className="">
                                            <a target="_blank" href="/action/18">Предоставление гражданам субсидии на оплату жилого помещения и коммунальных услуг. Раздел 1</a>
                                        </li>
                                        <li id="action_id_19" className="">
                                            <a target="_blank" href="/action/19">Предоставление гражданам субсидии на оплату жилого помещения и коммунальных услуг. Раздел 2</a>
                                        </li>
                                        <li id="action_id_20" className="">
                                            <a target="_blank" href="/action/20">Предоставление гражданам субсидии на оплату жилого помещения и коммунальных услуг. Раздел 3</a>
                                        </li>
                                        <li id="action_id_21" className="">
                                            <a target="_blank" href="/action/21">Предоставление гражданам субсидии на оплату жилого помещения и коммунальных услуг. Раздел 4</a>
                                        </li>
                                        <li id="action_id_22" className="">
                                            <a target="_blank" href="/action/22">Предоставление гражданам субсидии на оплату жилого помещения и коммунальных услуг. Раздел 5</a>
                                        </li>
                                        <li id="action_id_23" className="">
                                            <a target="_blank" href="/action/23">Социальный калькулятор для расчета субсидии.</a>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </Col>
                    </div>
                </Row >
            </Container>
        );
    }
}